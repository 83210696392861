import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as load from "./ducks/load.duck";
import * as alert from "./ducks/alert.duck";

import * as school from "./ducks/school.duck";
import * as team from "./ducks/team.duck";
import * as sport from "./ducks/sport.duck";
import * as staffM from "./ducks/staff_members.duck";
import * as staffTypes from "./ducks/staff_types.duck";
import * as student from "./ducks/students.duck";
import * as annc from "./ducks/annc.duck";
import * as feel from "./ducks/feelings.duck";
import * as conf from "./ducks/conference.duck";
import * as goal_types from "./ducks/goal_types.duck";
import * as goals from "./ducks/goals.duck";
import * as calendar from "./ducks/calendar.duck";
import * as alumni from "./ducks/alumni.duck";
import * as daily_picks from "./ducks/daily_picks.duck";
import * as content_author from "./ducks/content_author.duck";
import * as contents from "./ducks/contents.duck";
import * as dashboard from "./ducks/dashboard.duck";
import * as poll from "./ducks/poll.duck";
import * as scheduled_notification from "./ducks/scheduled_notification.duck";
import * as focus_areas from "./ducks/focus_areas.duck";
import * as tags from "./ducks/tags.duck";
import * as subfactors from "./ducks/subfactor.duck";
import * as permisions from "./ducks/permissions.duck";
import * as showReducer from "./ducks/support_card.duck";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  load: load.reducer,
  alert: alert.reducer,
  school: school.reducer,
  team: team.reducer,
  sport: sport.reducer,
  staffM: staffM.reducer,
  staff_type: staffTypes.reducer,
  student: student.reducer,
  annc: annc.reducer,
  feel: feel.reducer,
  conf: conf.reducer,
  goal_t: goal_types.reducer,
  goal: goals.reducer,
  calendar: calendar.reducer,
  alumni: alumni.reducer,
  dp: daily_picks.reducer,
  ca: content_author.reducer,
  contents_: contents.reducer,
  dashboard: dashboard.reducer,
  poll: poll.reducer,
  scheduled_notification: scheduled_notification.reducer,
  focus_areas: focus_areas.reducer,
  tags: tags.reducer,
  subfactors: subfactors.reducer,
  permissions: permisions.reducer,
  showReducer: showReducer.reducer,
});
