import React from 'react'
import { Paper } from '@material-ui/core';
import Chart from "react-apexcharts";

import * as goalsDUCK from "../../../redux/ducks/goals.duck"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setLoader } from '../../../redux/ducks/load.duck';

const Goals = ({ goals, school, goalActions, settingLoader }) => {
  
  const options = {
    chart: {
      toolbar: {
        show: false // if true, a download menu will show
      }
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    dataLabels: {
      enabled: true, //show data on bars
      dropShadow: {
        enabled: true //background shadow on labels
      },
      formatter: value => {
        return `${value} %`
      }
    },
    legend: {
      position: "bottom",
      horizontalAlign: "right"
    },
    tooltip: {
      enabled: false,
      theme: 'dark',
      x: {
        show: false // show title on tooltip
      },
      y: {
        formatter: (val, opt) => val, // format value to render
        title: {
          formatter: (val, opt) => "" // format prev title to render on tooltip
        }
      }
    },
    grid: {
      padding: {
        right: 15,
      }
    },
  }

  const mindOptionsChar = {
    ...options,
    xaxis: {
      categories: goals.mind.goals,
    },
  }

  const bodyOptionsChar = {
    ...options,
    grid: {
      padding: {
        right: 15,
        left: 90
      }
    },
    colors: ["#04ab93"],
    xaxis: {
      categories: goals.body.goals,
    },
  }

  const lifeOptionsChar = {
    ...options,
    colors: ["#FFC107"],
    xaxis: {
      categories: goals.life.goals,
    },
  }

  return (
    <div className="py-5">
      <Paper>
        <div><h1 className="text-center">Mind</h1></div>
        <Chart
          height={400}
          type="bar"
          options={mindOptionsChar}
          series={[{
            data: goals.mind.data_percent
          }]}
        />
      </Paper>
      <Paper className="my-5">
        <div><h1 className="text-center">Body</h1></div>
        <Chart
          height={400}
          type="bar"
          options={bodyOptionsChar}
          series={[{
            data: goals.body.data_percent
          }]}
        />
      </Paper>
      <Paper>
        <div><h1 className="text-center">Life</h1></div>
        <Chart
          height={400}
          type="bar"
          options={lifeOptionsChar}
          series={[{
            data: goals.life.data_percent
          }]}
        />
      </Paper>
    </div>
  )
}

const mapStateToProps = store => ({
  school: store.school.selected_school,
  goals: store.goal.goal_analytics,
})

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  goalActions: bindActionCreators(goalsDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Goals)
