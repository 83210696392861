import React, { useEffect } from "react";
import { useBreakpoints } from "../../hooks/useBreakpoint";
import DashboardDesktop from "./DashboardDesktop";
import DashboardL from "./DashboardL";
import DashboardM from "./DashboardM";
import DashboardS from "./DashboardS";
import DashboardXs from "./DashboardXs";
import * as dashboardDUCK from "../../../redux/ducks/dashboard.duck";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDashboardData } from "../../crud/api/dashboard.crud";
import { useHistory } from "react-router-dom";
const Dashboard = ({ queries, dashboard_actions, school, is_admin,permissions }) => {
  const { isXs, isS, isM, isL, isDesktop } = useBreakpoints();
  const { date_from, date_to, team, month, mood } = queries;
  const slug = is_admin ? school?.slug : undefined;
  const [loaded, setLoaded] = React.useState(false);
  let history = useHistory();

  useEffect(() => {
    
    if(!is_admin){
        const permission = permissions.find(x => x.read && x.module_clave === 'dash001');
        
        if(!permission?.read)history.push('/users')
    }
    if (is_admin && !slug) return;
    getDashboardData(date_from, date_to, team, month, mood, slug)
      .then((res) => {
        dashboard_actions.setData(res);
        setLoaded(true);
      })
      .catch(console.log);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard_actions, date_from, date_to, month, team, mood, slug, loaded]);
  
  if (!loaded) {
    return <></>;
  } else if (isXs) {
    return <DashboardXs isXs={isXs} />;
  } else if (isS) {
    return <DashboardS />;
  } else if (isM) {
    return <DashboardM />;
  } else if (isL) {
    return <DashboardL />;
  } else if (isDesktop) {
    return <DashboardDesktop />;
  } else {
    return <></>;
  }
};

const mapStateToProps = (store) => ({
  queries: store.dashboard.queries,
  school: store.school.selected_school,
  is_admin: store.auth.user?.is_admin,
  permissions:  store.permissions.permissions
});




const mapDispatchToProps = (dispatch) => ({
  dashboard_actions: bindActionCreators(dashboardDUCK.actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
