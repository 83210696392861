/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { makeStyles } from "@material-ui/core";

import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

const useStyles = makeStyles((theme) => ({
  dropdown: {
    backgroundColor: "#040719",
  },
  username: { color: "#ECFCFF" },
  usermail: { color: "#C1CAD9" },
}));

export function UserProfileDropdown() {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    if (!user.first_name || !user.last_name || !user.email)
      history.push("/logout");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.first_name ? user.first_name : ""}{" "}
            {user.last_name ? user.last_name : ""}
          </span>
          <Avatar
            style={{ width: 35, height: 35 }}
            variant="rounded"
            src={user.avatar_thumb ? user.avatar_thumb : "/"}
          >
            <span>{user.first_name ? user.first_name[0] : ""}</span>
          </Avatar>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
        <div
          className={`d-flex align-items-center flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top ${classes.dropdown}`}
          // style={{
          //   backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-10.jpg")})`
          // }}
        >
          <Avatar
            style={{ width: 100, height: 100 }}
            variant="rounded"
            src={user.avatar_thumb ? user.avatar_thumb : ""}
          >
            <span>{user.first_name ? user.first_name[0] : ""}</span>
          </Avatar>
          <div className="d-flex flex-column ml-5">
            <div
              className={`font-weight-bold font-size-h5 ${classes.username}`}
            >
              {user.first_name ? user.first_name : ""}{" "}
              {user.last_name ? user.last_name : ""}
            </div>
            <div className={`${classes.usermail}`}>
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
                />
              </span>
              {user.email ? user.email : ""}
            </div>
            <Link
              to="/logout"
              className="btn btn-light-primary btn-bold font-weight-bold mt-5"
            >
              Sign Out
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
