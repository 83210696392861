import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import { defaultTag } from '../../app/crud/api/tags.crud';

export const actionType = {
  GetTags: '[TAGS] GET_TAGS',
  GetTag: '[TAGS] GET_TAG',
  UpdateTag: '[TAGS] UPDATE_TAG',
}

const initialState = {
  tags: [],
  tag: defaultTag,
  is_it_for_routines:false,
  selected_tag: null
}

export const reducer = persistReducer(
  { storage, key: 'tags', withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetTags: {
        return { 
            ...state, 
            tags: { 
              data: action.payload.items, 
              count: action.payload.total, 
              page: action.payload.page, 
              pages: action.payload.pages, 
              rowsPerPage: action.payload.per_page, 
              isFetched: true
            } 
          }
      }
      
      case actionType.GetTag: {
        
        return { ...state, tag: action.payload }
      }

      case actionType.UpdateTag: {
        let newData = state.tags.data
        const index = state.tags.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.tags.data]
        } else {
          newData = state.tags.data
          newData[index] = action.payload
        }
 
        return { ...state, tags: { ...state.tags, data: newData } }
      }

      default: return state

    }
  }
)

export const actions = {
  setTags: data => ({ type: actionType.GetTags, payload: data }),
  setTag: data => ({ type: actionType.GetTag, payload: data }),
  updateTag: data => ({ type: actionType.UpdateTag, payload: data }),
}