import { GOALS_URL } from "../helpers/routes"
import { handleResponse, getParams, getMultipartParams } from "../helpers/validate"
import notice from "../../utils/notice"

export const defaultTypeGoal = {
  name: "",
  active: false,
}

export const getGoalTypes = (setterFunction, zone, page = 1) => {
  return fetch(`${GOALS_URL}/zone/${zone}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error loading goals")
    console.error(e.errors)
  })
}

export const getGoalType = (setterFunction, id) => {
  return fetch(`${GOALS_URL}/show?id=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error loading goal")
    console.error(e.errors)
    throw e
  })
}

export const saveGoalType = (setterFunction, data) => {
  const jsonData = JSON.stringify(data)

  return fetch(GOALS_URL, getParams('POST', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Goal saved", "success")
    setterFunction(json)
  })
  .catch(e => {
    notice("Error saving goal")
    console.error(e.errors)
    throw e
  })
}

export const updateGoalType = (setterFunction, data, id) => {
  const jsonData = JSON.stringify(data)

  return fetch(`${GOALS_URL}/update?id=${id}`, getParams('PUT', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Goal updated", "success")
    setterFunction(json)
  })
  .catch(e => {
    notice("Error saving goal")
    console.error(e.errors)
    throw e
  })
}