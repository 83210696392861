import React, { useEffect, useState } from 'react'
import { Paper, MuiThemeProvider, Switch, Avatar, Button, useMediaQuery } from '@material-ui/core'
import { Add } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';

import FilterDataTable from '../../../utils/FilterDataTable';
import { tableMuiTheme } from '../../table/table_styles';
import StaffDialog from './StaffDialog';

import { setLoader } from '../../../../redux/ducks/load.duck';
import * as staffTypeDUCK from "../../../../redux/ducks/staff_types.duck"
import { getStaffTypes, defaultStaffType, getStaffType, updateStaffType } from '../../../crud/api/staff_type.crud';

const Staff = ({ staff_types, school, staffTActions, settingLoader }) => {
  const isDesktop = useMediaQuery("(min-width: 992px)")
  const isTablet = useMediaQuery("(min-width: 768px)")
  const [dialog, setDialog] = useState("close")
  const [query, setQuery] = useState("")

  useEffect(() => {
    if (school && school !== null) {
      if (query && query.length > 1) getStaffTypes(staffTActions.setStaffTypes, school.slug, 1, query)
      
      else getStaffTypes(staffTActions.setStaffTypes, school.slug, 1)
    }
  }, [query, school, staffTActions.setStaffTypes])
  
  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'icon',
      label: 'Icon',
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value) => (
          <Avatar>
            {value ? <img width="100%" src={value} alt="icon"/> : <img width="100%" src="https://itsthezone.s3.amazonaws.com/defaults/user_avatar_thumb_default_url.png" alt="default"/> }
          </Avatar>
        )
      }
    },
    {
      name: 'name',
      label: 'Staff type',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'color',
      label: 'Color',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div style={{ background: value, height: 20, width: 40, borderRadius: 20 }}/>
        )
      }
    },
    {
      name: 'active',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value, metaData, updateValue) => {
          
          const update = async check => {
            const st = {
              ...metaData.tableData[metaData.rowIndex],
              active: check
            }
            settingLoader(true)
            try {
              await updateStaffType(staffTActions.updateStaffTypes, st, st.id)
              setDialog("close")
            } catch (error) {
            }
          }

          return (
          <Switch
            color="secondary"
            name="status"
            checked={value}
            onChange={e => update(e.target.checked)}
          />
        )}
      }
    },
  ]

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: staff_types.rowsPerPage,
    page: staff_types.page,
    count: staff_types.count,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "670px" : isTablet ? "640px" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "staff_types.csv",
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
    },
    onRowClick: (data, meta, col) => {
      const { target } = col
      if (target.name !== "status") openDialog('editing', data[0])
    },
    onChangePage: (mPage) => {
      settingLoader(true)
      getStaffTypes(staffTActions.setStaffTypes, school.slug, mPage +1, query)
    }
  };

  const openDialog = (mode, id = null) => {
    switch(mode){
      case "create":
        settingLoader(true)
        staffTActions.setStaffType(defaultStaffType)
        setDialog(mode)
        break;
      case "editing":
        settingLoader(true)
        getStaffType(staffTActions.setStaffType, id)
        .then(() => setDialog(mode))
        break;
      case "close":
        setDialog(mode)
        setTimeout(() => {
          staffTActions.setStaffType(defaultStaffType)
        }, 500);
        break;
      default:
        console.error("Invalid dialog mode")
    }        
  }

  return (
    <div className="table-container">
      <div className="pb-4 d-flex justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} />
        <Button id="create_staff_type_btn" variant="contained" color="secondary" onClick={() => openDialog("create")}><Add /> New staff type</Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={tableMuiTheme()}>
          <MUIDataTable
            // title="Staff"
            columns={columns}
            data={staff_types.data.map(x => x.staff_type)}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      <StaffDialog mode={dialog} setDialog={openDialog} />
    </div>
  )
}

const mapStateToProps = store => {
  return ({
  staff_types: store.staff_type.staffTypes,
  school: store.school.selected_school,
})}

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  staffTActions: bindActionCreators(staffTypeDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Staff)
