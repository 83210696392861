import { ClickAwayListener, IconButton } from '@material-ui/core'
import { EmojiEmotions } from '@material-ui/icons'
import { Picker } from 'emoji-mart'
import React, { useState } from 'react'

const SimpleEmojiInput = ({ name, onChange }) => {
	const [emojiPicker, setEmojiPicker] = useState(false)

	return (
		<ClickAwayListener onClickAway={() => setEmojiPicker(false)}>
      <div>
        <IconButton id="feeling_icon_btn_dialog" onClick={() => setEmojiPicker(!emojiPicker)}>
          <EmojiEmotions />
        </IconButton>
        <Picker
          onSelect={emoji => onChange({target: { name, value: emoji.native}})}
          title="Pick your icon 👆"
          style={{
            position: "fixed",
            zIndex: 100,
            right: '40%',
            
            display: emojiPicker ? "inline-block" : "none"
          }}
        />
      </div>
    </ClickAwayListener>
	)
}

export default SimpleEmojiInput