import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'

const cropper = React.createRef(null);

export const DropNCrop = ({ setterFunction, file, field = "image", disabled=false }) => {
  const [image, setImage] = useState()
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      acceptedFiles.map(file => Object.assign(file, {
        src: URL.createObjectURL(file)
      }))
      setImage(acceptedFiles[0])
    }
  });

  function deleteFile () {
    setImage(null)
    setterFunction(field, "")
  }

  const cropImage = () => {
    fetch(cropper.current.getCroppedCanvas().toDataURL())
    .then(res => res.blob())
    .then(blob => {
      let newFile = new File([blob], "image", { type: "image/jpg" })	
      Object.assign(newFile, {src: cropper.current.getCroppedCanvas().toDataURL()})
      setterFunction(field, newFile)
      setTimeout(() => {
        setImage(null)
      }, 500);
    })
    .catch(e => console.log(e))
  }
  
  return(
    <>
      <div id="kt_dropzone_1" className="dropzone dropzone-default needsclick">
        <div className="dropzone-msg dz-message needsclick" {...getRootProps()} style={{ margin: 0 }}>
          <input id="square_drop_zone" {...getInputProps()}/>
          <h3 className="dropzone-msg-title" style={{ display: file !== null && file ? "none" : "block" }}><span className="flaticon-upload-1" /> Drop files to attach, or click</h3>
        </div>
        {file !== null && file ?
        <div className="dz-preview">
            <div className="dz-image">
              <img width="100%" src={file !== null ? file.src ? file.src : file : ""} alt="image_preview"/>
            </div>
            {!disabled && <div id="remove_square_preview_image" className="dz-remove" onClick={(e) => {e.preventDefault() ;deleteFile()}}>
              <CancelRounded fontSize="large" style={{ width: "2em", height: "2em" }} />
            </div>}
        </div>
        : null}
      </div>
      <Dialog open={Boolean(image !== null && image !== undefined && image.src)} onClose={() => deleteFile()} id="crop_square_zone_dialog">
        <DialogTitle>Crop your image</DialogTitle>
        <DialogContent>
          <Cropper
            ref={cropper}
            src={image && image.src}
            style={{height: 400, width: '100%'}}
            // Cropper.js options
            aspectRatio={1 / 1}
            guides={false}
          />
        </DialogContent>
        <DialogActions>
          <button
          id="square_crop_trigger"
          className="btn btn-primary btn-elevate kt-login__btn-primary"
          onClick={() => cropImage()}
          >
            Crop Image
          </button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const Dropper = ({ setterFunction, file, field }) => {
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      acceptedFiles.map(file => Object.assign(file, {
        src: URL.createObjectURL(file)
      }))
      
      setterFunction(field, acceptedFiles[0])
    }
  });

  const deleteFile = () =>{ 
    
    setterFunction(field, "")

}
  
  return(
    <>
      <div id="kt_dropzone_1" className="dropzone dropzone-default needsclick">
        <div className="dropzone-msg dz-message needsclick" {...getRootProps()} style={{ margin: 0 }}>
          <input id="general_drop_zone" {...getInputProps()}/>
          <span className="dropzone-msg-title" style={{ display: file !== null && file ? "none" : "block" }}><span className="flaticon-upload-1" /> Drop files to attach, or click</span>
        </div>
        {file !== null && file ?
        <div className="dz-preview">
          
            <div className="dz-image" style={{ position: "relative", width: "100%", height: "100%" }}>
              <img width="100%" src={file !== null ? file.src ? file.src : file : ""} alt="image_preview"/>
            </div>
            <div id="remove_preview_image" className="dz-remove" style={{ width: "100%", height: "100%" }} onClick={(e) => {e.preventDefault() ;deleteFile()}}>
              <CancelRounded fontSize="large" style={{ width: "2em", height: "2em" }} />
            </div>
        </div>
        : null}
      </div>
    </>
  )
}

export const DropNCropRec = ({ setterFunction, file, field = "image", disabled }) => {
  
  const [image, setImage] = useState()
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImage(reader.result));
      reader.readAsDataURL(acceptedFiles[0])
    }
  });

  function deleteFile () {
    setImage(null)
    setterFunction(field, "")
  }

  const cropImage = () => {
    fetch(cropper.current.getCroppedCanvas().toDataURL())
    .then(res => res.blob())
    .then(blob => {
      let newFile = new File([blob], "image", { type: "image/jpg" })
      Object.assign(newFile, {src: cropper.current.getCroppedCanvas().toDataURL()})
      setterFunction(field, newFile)
      setTimeout(() => {
        setImage(null)
      }, 500);
    })
  }
  
  return(
    <>
      <div id="kt_dropzone_1" className="dropzone dropzone-default needsclick">
        <div className="dropzone-msg dz-message needsclick" {...getRootProps()} style={{ margin: 0 }}>
          <input id="rec_drop_zone" {...getInputProps()}/>
          <h3 className="dropzone-msg-title" style={{ display: file !== null && file ? "none" : "block" }}>Drag 'n' Drop an image here or click to upload</h3>
        </div>
        {file !== null && file ?
        <div className="dz-preview">
            <div className="dz-image">
              <img width="100%" src={file !== null ? file.src ? file.src : file : ""} alt="image_preview"/>
            </div>
            <div id="remove_rec_preview_image" className="dz-remove" onClick={(e) => {e.preventDefault() ;deleteFile()}}>
              <CancelRounded fontSize="large" style={{ width: "2em", height: "2em" }} />
            </div>
        </div>
        : null}
      </div>

      <Dialog open={image !== null && image !== undefined} onClose={() => deleteFile()} id="crop_rec_zone_dialog">
        <DialogTitle>Crop your image</DialogTitle>
        <DialogContent>
          <Cropper
            ref={cropper}
            src={image}
            style={{height: 400, width: '100%'}}
            // Cropper.js options
            aspectRatio={16 / 9}
            guides={false}
          />
        </DialogContent>
        <DialogActions>
          <button
          id="rec_crop_trigger"
          className="btn btn-primary btn-elevate kt-login__btn-primary"
          onClick={() => cropImage()}
          >
            Crop Image
          </button>
        </DialogActions>
      </Dialog>
    </>
  )
}
