/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Paper,
  MuiThemeProvider,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { actionTableMuiTheme } from "../../table/table_styles";
import { searchSchools } from "../../../../app/crud/api/schools.crud";
import { Add } from "@material-ui/icons";
import { addPermisssion } from "../../../crud/api/Permission.crud";
import { BASE_URL_v2 } from "../../../crud/helpers/routes";
import { getParams, handleResponse } from "../../../crud/helpers/validate";
import notice from "../../../utils/notice";

const Permission = ({ schoolActions, settingLoader }) => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const [schools, setSchools] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [showButtonSave, setShowButtonSave] = useState(false);
  const [checkAllSchool, setCheckAllSchool] = useState(null);

  useEffect(() => {
    searchSchools("", setSchools);
  }, []);

  const getNewPermission = (school_id, read, module_id) => {
    const newPermision = {
      school_id: school_id,
      read: read !== null ? read : false,
      write: false,
      update_p: false,
      delete_p: false,
      module_id: module_id !== null ? module_id : 1,
    };
    const indexPermission = permissions.findIndex(
      (x) =>
        x.module_id === (module_id !== null ? module_id : 1) &&
        x.school_id === school_id
    );
    if (indexPermission !== -1) {
      permissions.splice(indexPermission, 1);
    }
    return newPermision;
  };
  const selectPermission = (event, permision, meta) => {
    const school = schools[meta.rowIndex];
    const newPermision = getNewPermission(
      school.id,
      permision.read !== undefined ? !permision.read : false,
      permision.module_id ?? 1
    );
    school.permissions = [newPermision];
    schools[meta.rowIndex] = school;

    // const indexPermission = permissions.findIndex(
    //   (x) =>
    //     x.module_id === permision.module_id &&
    //     x.school_id === permision.school_id
    // );
    // if (indexPermission !== -1) {
    //   permissions.splice(indexPermission, 1);
    // }

    setSchools(schools);
    setShowButtonSave(true);

    setPermissions([newPermision, ...permissions]);
  };
  const isCheckAllSchool = () => {
    const school = schools.filter(
      (x) =>
        (x.permissions.length > 0 && x.permissions[0].read === false) ||
        x.permissions.length === 0
    );
    if (school.length === 0) return true;
    return false;
  };
  const selectAllSchool = (event) => {
    const checked = event.currentTarget.checked;
    const permissionSelected = [];
    schools.forEach((x) => {
      const newPermision = getNewPermission(x.id, checked, 1);
      x.permissions = [newPermision];
      permissionSelected.push(newPermision);
    });
    setPermissions(permissionSelected);
    setShowButtonSave(true);
    setSchools(schools);
  };
  const saveChanges = () => {
    addPermisssion(permissions);
  };
  const columns = [
    {
      name: "name",
      label: "Organizations",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "permissions",
      label: "View dashboard",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => (
          <label>
            <input
              type="checkbox"
              name=""
              checked={value.length > 0 ? value[0].read : false}
              onChange={(event) => {
                selectPermission(event, value[0], meta);
              }}
            />
            <span></span>
          </label>
        ),
      },
    },
    {
      name: "permissions",
      label: "Mindflow",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => (
          <label>
            <input
              type="checkbox"
              name=""
              checked={isMindflowChekActive(value[0], meta)}
              onChange={(event) => {
                handleMindflowStatusChange(event, value[0], meta);
              }}
            />
            <span></span>
          </label>
        ),
      },
    },
  ];

  const isMindflowChekActive = (permision, meta) => {
    const school = schools[meta.rowIndex];
    return school.active_mindflow;
  };

  const handleMindflowStatusChange = (event, permision, meta) => {
    const school = schools[meta.rowIndex];
    const { id, active_mindflow } = school;
    if (id !== undefined) {
      fetch(
        `${BASE_URL_v2}/schools/check_uncheck_mindflow?login=email`,
        getParams(
          "POST",
          JSON.stringify({ id_school: id, active: !active_mindflow })
        )
      ).then((e) => {
        if (e.status === 200) {
          notice("Permissions updated", "success");
          searchSchools("", setSchools);
        } else {
          notice("Eror updating permissions");
        }
      });
    } else {
      notice("Eror updating permissions");
    }
  };

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: schools.rowsPerPage,
    page: 1,
    count: schools.count,
    rowHover: false,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: "none",
    tableBodyMaxHeight: isDesktop ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data",
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "school.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayRowsOnly: true,
      },
    },
  };

  return (
    <>
      <div className="table-container">
        {schools.length > 0 ? (
          <div className="pb-4 d-flex justify-content-end align-items-end">
            <label style={{ marginRight: "20px" }}>
              <input
                type="checkbox"
                name=""
                checked={
                  checkAllSchool == null ? isCheckAllSchool() : checkAllSchool
                }
                onChange={(event) => {
                  selectAllSchool(event);
                }}
              />
              <span> Check All</span>
            </label>
            {showButtonSave === true ? (
              <Button
                id="save_parmissions"
                variant="contained"
                color="secondary"
                onClick={() => saveChanges()}
              >
                <Add /> Save Changes
              </Button>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}

        <Paper>
          <MuiThemeProvider theme={actionTableMuiTheme()}>
            <MUIDataTable
              // title="Admin"
              columns={columns}
              data={schools}
              options={options}
            />
          </MuiThemeProvider>
        </Paper>
      </div>
    </>
  );
};

export default Permission;
