import {
  SCHOOL_URL,
  STUDENTS_URL,
  BACKGROUND_OPERATIONS_V2,
  SCHOOL_URL_V2,
} from "../helpers/routes";
import {
  handleResponse,
  getParams,
  getMultipartParams,
  handleFormData,
} from "../helpers/validate";
import notice from "../../utils/notice";

export const defaultStudent = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  code: "",
  team_id: "",
  classification: "",

  height: "",
  weight: "",
  gender: "",

  status: true,
  nick_name: "",
};

export const getStudents = (
  setterFunction,
  slug,
  page = 1,
  query = "",
  group = ""
) => {
  if (slug) {
    return fetch(
      `${SCHOOL_URL}/${slug}/students?page=${page}&search_query=${query}${
        group && group != null ? group : ""
      }`,
      getMultipartParams("GET")
    )
      .then((res) => handleResponse(res))
      .then((json) => setterFunction(json))
      .catch((e) => {
        notice("Error on loading Athletes");
        console.error(e.errors);
      });
  }
};

export const getStudent = (setterFunction, id) => {
  return fetch(`${STUDENTS_URL}/show?id=${id}`, getMultipartParams("GET"))
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => {
      notice("Error on fetching Athlete");
      console.error(e.errors);
      throw e;
    });
};

export const saveStudent = (setterFunction, data, type_creation) => {
  const formData = new FormData();

  for (const name in data) {
    formData.append(name, data[name]);
  }

  return fetch(
    `${STUDENTS_URL}?type_creation=` + type_creation,
    getMultipartParams("POST", formData)
  )
    .then((res) => handleResponse(res))
    .then((json) => {
      notice("Athlete created", "success");
      return json.id && setterFunction(json);
    })
    .catch((e) => {
      throw e;
    });
};

export const updateStudent = (setterFunction, code, data) => {
  const formData = new FormData();

  for (const name in data) {
    formData.append(name, data[name]);
  }

  return fetch(`${STUDENTS_URL}/${code}`, getMultipartParams("PUT", formData))
    .then((res) => handleResponse(res))
    .then((json) => {
      if (json && json.id) {
        notice("Athlete updated", "success");
        return setterFunction(json);
      } else notice("Error saving the Athlete");
    })
    .catch((e) => {
      notice("Error saving the Athlete");
      console.log(e.errors);
      throw e;
    });
};

export const deleteStudent = (setterFunction, id) => {
  return fetch(`${STUDENTS_URL}/delete?id=${id}`, getMultipartParams("DELETE"))
    .then((res) => handleResponse(res))
    .then((json) => {
      notice("Athlete deleted", "success");
      setterFunction(json);
    })
    .catch((e) => {
      notice("Error deleting the Athlete");
      console.log(e.errors);
      throw e;
    });
};

export const searchStudents = (
  setterFunction,
  slug,
  query,
  onlyStaff = false
) => {
  if (slug) {
    return fetch(
      `${SCHOOL_URL}/${slug}/students/suggestions?q=${query}&onlyStaff=${onlyStaff}`,
      getMultipartParams("GET")
    )
      .then((res) => handleResponse(res))
      .then((json) => setterFunction(json))
      .catch((e) => {
        notice("Error on loading staff members");
        console.error(e.errors);
      });
  }
};

export const genStudentCode = (setterFunction, slug, data) => {
  const jsonData = JSON.stringify(data);

  return fetch(
    `${SCHOOL_URL}/${slug}/students/request_access_code`,
    getParams("POST", jsonData)
  )
    .then((res) => handleResponse(res))
    .then((json) => {
      setterFunction("code", json.code);
    })
    .catch((e) => {
      notice("Error fetching code");
      console.log(e.errors);
    });
};

export const validateStudentCode = (
  slug,
  value,
  setterError,
  setterSuccess,
  setterFunction
) => {
  const jsonData = JSON.stringify(value);

  return fetch(
    `${SCHOOL_URL}/${slug}/students/validate_student_code`,
    getParams("POST", jsonData)
  )
    .then((res) => handleResponse(res))
    .then((json) => {
      if (json.valid_code) {
        setterSuccess("Valid Code");
      } else {
        setterSuccess("");
        setterError(`Code ${value.code} is not available`);
        setTimeout(() => {
          setterFunction("code", "");
          setterError("");
        }, 2000);
      }
    })
    .catch((e) => {
      console.error(e.errors);
      setterError(`Code ${value.code} is not available`);
      setterSuccess("");
      setTimeout(() => {
        setterFunction("code", "");
        setterError("");
      }, 2000);
    });
};

export const notifyStudent = (email) => {
  return fetch(`${STUDENTS_URL}/notify?email=${email}`, getParams("GET"))
    .then((res) => handleResponse(res))
    .then(() => notice("Athlete code sent", "success"))
    .catch((e) => {
      notice("Error resending Athlete code");
      console.log(e.errors);
      throw e;
    });
};

export const studentBulk = (slug, UrlFile, team_id) => {
  return fetch(
    `${SCHOOL_URL_V2}/${slug}/bulk_student_creation`,
    getMultipartParams("POST", handleFormData({ key: UrlFile, team_id }))
  )
    .then(handleResponse)
    .then((res) => res)
    .catch((e) => {
      console.log(e.errors);
      throw e;
    });
};

export const validateStudentBulkFetch = (slug, url_file) => {
  const jsonData = JSON.stringify({ key: url_file });
  return fetch(
    `${SCHOOL_URL_V2}/${slug}/validate_bulk_csv_file`,
    getParams("POST", jsonData)
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

export const checkStateBulk = (id_background) => {
  return fetch(`${BACKGROUND_OPERATIONS_V2}/${id_background}`, getParams("GET"))
    .then(handleResponse)
    .then((res) => res)
    .catch((e) => {
      throw e;
    });
};

export const generatePresignedUrl = (key) => {
  const jsonData = JSON.stringify({ key: key });
  return fetch(`${SCHOOL_URL_V2}/presigned_url`, getParams("POST", jsonData))
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};
