
import { SCHEDULED_NOTIFICATION } from "../helpers/routes";
import {
  handleResponse,
  getMultipartParams,
  getParams,
} from "../helpers/validate";
import notice from "../../utils/notice";
import { hour12tohour24 } from "../../utils/convertHours";


export const getScheduledNotifications = (setterFunction, page = 1, query = "") => {
    return fetch(
      `${SCHEDULED_NOTIFICATION}/list?page=${page}&search_query=${query}`,
      getMultipartParams("GET")
    )
      .then((res) => handleResponse(res))
      .then((json) => setterFunction(json))
      .catch((e) => {
        notice("Error on loading the polls");
        console.error(e.errors);
        throw e;
      });
  };

  export const getScheduledNotification = (setterFunction, id) => {
    return fetch(`${SCHEDULED_NOTIFICATION}/show/${id}`, getMultipartParams("GET"))
     .then((res) => handleResponse(res))
     .then((json) => setterFunction(json))
     .catch((e) => {
       notice("Error on loading the poll");
       console.error(e.errors);
       throw e;
     });
 };


 export const createScheduledNotification = (data) => {
  
  let datPost = {...data}
  datPost["send_at"] =  datPost["send_at"].toString()
  datPost["time_at"] = hour12tohour24( datPost["hour"],datPost["AMPM"])+":"+data["minute"]
  return fetch(`${SCHEDULED_NOTIFICATION}/create`,  getParams("POST", JSON.stringify(datPost)))
   .then((res) => handleResponse(res))
   .then((json) => {
    notice("Scheduled notification created", "success");
    return json;
  })
   .catch((e) => {
     notice("Error on loading the scheduled notification");
     console.error(e.errors);
     throw e;
   });
};

export const updateScheduledNotification = (data, id) => {
  
  let datPost = {...data}
  datPost["send_at"] =  datPost["send_at"].toString()
  datPost["time_at"] =   hour12tohour24( datPost["hour"],datPost["AMPM"])+":"+data["minute"]
  return fetch(`${SCHEDULED_NOTIFICATION}/update`, getParams("PUT", JSON.stringify(datPost)))
   .then((res) => handleResponse(res))
   .then((json) => {
    notice("Scheduled notification created", "success");
    return json;
  })
   .catch((e) => {
     notice("Error on loading the pscheduled notificationoll");
     console.error(e.errors);
     throw e;
   });
};

export const deleteScheduledNotification = (id) => {
  return fetch(`${SCHEDULED_NOTIFICATION}/delete/${id}`, getMultipartParams("DELETE"))
   .then((res) => handleResponse(res))
   .then((json) => {
    notice("Scheduled notification deleted", "success");
    return json;
  })
   .catch((e) => {
     notice("Error on loading the pscheduled notificationoll");
     console.error(e.errors);
     throw e;
   });
};