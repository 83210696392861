
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {CheckCircleOutline, Add, Delete, Timelapse } from "@material-ui/icons";
import FormDialog from "./FormDialog";
import { Button, Tooltip } from "@material-ui/core";
import * as scheduledNotificationDuck from "../../../redux/ducks/scheduled_notification.duck";
import { setLoader } from "../../../redux/ducks/load.duck";
import { setAlert } from "../../../redux/ducks/alert.duck";
import CustomTable from '../../utils/CustomTable'
import {
	getScheduledNotifications,
	getScheduledNotification,
	deleteScheduledNotification
} from "../../crud/api/scheduled_notifications.crud";
import { columns } from "./utils";

const Notifications = ({
	schedule_notifications,
	scheduledNotificationAction,
	settingLoader,
	staff_member_admin,
	is_admin,
	setAlertModal,
	selected_school, }) => {
	const [page, setPage] = useState(1);
	const [dialog, setDialog] = useState("close");


	useEffect(() => {
		getScheduledNotifications(scheduledNotificationAction.setScheduledNotifications, page);
	}, [page, scheduledNotificationAction.setScheduledNotifications]);

	const actionColumns = [
		{
			name: "is_sent",
			label: "Notification Status",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, { tableData, rowIndex }) => (
					<div className="d-flex">
						<Tooltip title={value ? 'Sent' : 'Scheduled'} placement="top">
							{value == true ? (
								<span className="text-success">	<CheckCircleOutline /></span>
							) : (
								<span className="">	<Timelapse /></span>

							)}
						</Tooltip>
					</div>
				)
			},
		},
		{
			name: "user_created",
			label: "Created by",
			options: {
			  filter: false,
			  sort: false,
			},
		  },
		{
			name: "id",
			label: "Actions",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, { tableData, rowIndex }) => (
					!tableData[rowIndex].is_sent ? (
					<div className="d-flex">
						
						<Tooltip title="Edit" placement="top">
							<a
								id={`edit_poll_btn_${value}`}
								className="btn btn-icon btn-sm btn-light btn-hover-primary "
								onClick={() => openDialog("editing", value)}
							>

								<i className="fas fa-edit text-primary" />

							</a>
						</Tooltip>

						<Tooltip title="Delete" placement="top">
							<a
								id={`delete_poll_btn_${value}`}
								className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
								onClick={() => handleDeleteScheduled(tableData[rowIndex])}
							>
								<Delete />
							</a>
						</Tooltip>
					</div>):(<div></div>)
				),
			},
		},
	];

	const handleDeleteScheduled = (data) => {
		deleteScheduledNotification(data.id);
		scheduledNotificationAction.deleteScheduledNotification(data)

	}
	const openDialog = (mode, id = null) => {
		switch (mode) {
			case "create":
				settingLoader(true);
				scheduledNotificationAction.setScheduledNotification({});
				setDialog(mode);
				break;
			case "editing":
				//settingLoader(true);
				getScheduledNotification(scheduledNotificationAction.setScheduledNotification, id).then(() => setDialog(mode));
				break;
			case "close":
				//settingLoader(true);
				setDialog(mode);
				setTimeout(() => {
					scheduledNotificationAction.setScheduledNotification({});
				}, 500);
				break;
			default:
				console.error("Invalid dialog mode");
		}
	};


	return (
		<div className="table-container">
			<div className="pb-4 d-flex justify-content-between align-items-end">
				{/* <FilterDataTable query={query} setQuery={setQuery} /> */}

				<Button
					className="ml-auto mr-0"
					id="create_poll_btn"
					variant="contained"
					color="secondary"
					onClick={() => openDialog("create")}
				>
					<Add /> New scheduled notification
				</Button>

			</div>
			<CustomTable
				title="Notifications"
				columns={[...columns, ...actionColumns]}
				data={schedule_notifications.data}
				page={schedule_notifications.page}
				count={schedule_notifications.count}
				setPage={setPage}
			/>
			<FormDialog mode={dialog} setDialog={openDialog} />
		</div>
	)
}

const mapStateToProps = (store) => ({
	schedule_notifications: store.scheduled_notification.schedule_notifications,
	is_admin: store.auth.user?.is_admin,
	staff_member_admin: store.auth.user?.staff_member_admin,
	selected_school: store.school.selected_school,
});

const mapDispatchToProps = (dispatch) => ({
	scheduledNotificationAction: bindActionCreators(scheduledNotificationDuck.actions, dispatch),
	settingLoader: bindActionCreators(setLoader, dispatch),
	setAlertModal: bindActionCreators(setAlert, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(Notifications);