import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";

export const actionType = {
  GetPermissions: '[PERMISSIONS] GET_PERMISSIONS',
  SetPermissions: '[PERMISSIONS] SET_PERMISSIONs',
}

const initialState = {
 permissions:[]
}

export const reducer = persistReducer(
  { storage, key: 'permissions', withlist: [] },
  (state = initialState, action) => {
    console.log(action.type)
    switch (action.type) {
      case actionType.GetPermissions: {
        return { 
            ...state,
            permissions:action.payload
          }
      }
      
     
      case actionType.SetPermissions: {
        let newData = state.tags.data
        const index = state.tags.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.tags.data]
        } else {
          newData = state.tags.data
          newData[index] = action.payload
        }
 
        return { ...state, tags: { ...state.tags, data: newData } }
      }

      default: return state

    }
  }
)

export const actions = {
  setPermissions: data => (  { type: actionType.GetPermissions, payload: data }),
  GetPermissions: data => ({ type: actionType.GetPermissions, payload: data }),
}