export default function setupAxios(axios, store) {
  axios.interceptors.request.use((config) => {
    config.params = { ...config.params, login: "email" };
    return config;
  });
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
}
const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  let [resource, config] = args;

  const loginMethod = window.localStorage.getItem("loginMethod");
  if (
    !resource.includes("itsthezonezone-uploads") &&
    (resource.includes("the-zone") ||
      resource.includes("localhost") ||
      resource.includes("itsthezone"))
  ) {
    if (resource.includes("?")) resource = resource + `&login=${loginMethod}`;
    else resource = resource + `?login=${loginMethod}`;
  }

  const response = await originalFetch(resource, config);

  return response;
};
