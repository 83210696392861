export const appPendo = (user) => window.pendo.initialize({
  visitor: {
		id: user?.id,   									// Required if user is logged in, default creates anonymous ID
		email: user?.email,       				// Recommended if using Pendo Feedback, or NPS Email
		firstName: user?.first_name,      // Recommended if using Pendo Feedback, or NPS Email
		lastName: user?.last_name,       	// Recommended if using Pendo Feedback, or NPS Email
		// full_name:    									// Recommended if using Pendo Feedback
		// role:         									// Optional
		// You can add any additional visitor level key-values here,
		// as long as it's not one of the above reserved names.
  },
  account: {
		id: 'ACCOUNT-UNIQUE-ID', 					// Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
		name: 'Itsthezone',		        		// Optional
		is_paying: false,  								// Recommended if using Pendo Feedback
		// monthly_value:// Recommended if using Pendo Feedback
		// planLevel:    // Optional
		// planPrice:    // Optional
		// creationDate: // Optional
		// You can add any additional account level key-values here,
		// as long as it's not one of the above reserved names.
  }
})
