/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Checkbox, Tooltip } from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";

export const columns = (
  handleGetSingleTelehelp,
  handleDeleteTelehelp,
  isResourceProAthlete
) => [
  {
    name: "name",
    label: "Name",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "is_pro_athlete",
    label: "Pro atlhete",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, meta) => (
        <Checkbox checked={isResourceProAthlete(meta)} disabled />
      ),
    },
  },
  {
    name: "id",
    label: "Actions",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, { tableData, rowIndex }) => (
        <div>
          <Tooltip title="Edit Staff Support" placement="top">
            <a
              id={`edit_submood_btn_${value}`}
              className="btn btn-icon btn-sm btn-light btn-hover-primary "
              onClick={() => handleGetSingleTelehelp(tableData[rowIndex])}
            >
              <Visibility />
            </a>
          </Tooltip>
          <Tooltip title="Delete Staff Support" placement="top">
            <a
              id={`delete_team_btn_${value}`}
              className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
              onClick={() => handleDeleteTelehelp(value)}
            >
              <Delete />
            </a>
          </Tooltip>
        </div>
      ),
    },
  },
];

export const defaultTelehelp = {
  name: "",
  is_pro_athlete: false,
};
