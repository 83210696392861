import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import CustomTable from "../../../utils/CustomTable";
import { columns, defaultSubmood } from "./utils";
import FormModal from "./FormModal";
import useSubmoodsHook from "./useSubmoodsHook";

const Submoods = () => {
  const [openModal, setOpenModal] = useState(false);
  const [submood, setSubmood] = useState(defaultSubmood);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const {
    handleGetSingleSubmood,
    handleDeleteSubmood,
    isSubmoodProAthlete,
  } = useSubmoodsHook({
    list,
    setList,
    page,
    setCount,
    setOpenModal,
    submood,
    setSubmood,
  });

  return (
    <div className="table-container">
      <div className="d-flex justify-content-end mb-4">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Add />}
          onClick={() => {
            setSubmood(defaultSubmood);
            setOpenModal(true);
          }}
        >
          New
        </Button>
      </div>
      <CustomTable
        title="Notifications"
        data={list}
        columns={columns(
          handleGetSingleSubmood,
          handleDeleteSubmood,
          isSubmoodProAthlete
        )}
        page={page}
        count={count}
        setPage={setPage}
      />
      <FormModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        submood={submood}
        setSubmood={setSubmood}
        setList={setList}
        page={page}
        setCount={setCount}
      />
    </div>
  );
};

export default Submoods;
