import { POLLS_URL } from "../helpers/routes";
import {
  handleResponse,
  getMultipartParams,
  getParams,
} from "../helpers/validate";
import notice from "../../utils/notice";
import { formatDate } from "@fullcalendar/core";

// export const formatYYYYMMDDDate = dateValue => {
//   const offset = dateValue.getTimezoneOffset()
//   dateValue = new Date(dateValue.getTime() - (offset*60*1000))
//   return dateValue.toISOString().split('T')[0]
// }

export const formatYYYYMMDDDate = (dateValue) => {
  const date = new Date(dateValue);
  return date.toISOString().split("T")[0];
};

export const defaultPoll = {
  name: "",
  close_date: "",
  question_text: "",
  options: [{ value: "", disabled: false }],
  teams: [],
  all_teams: false,
  schools: [],
  all_schools: false,
};

export const getPolls = (setterFunction, page = 1, query = "") => {
  return fetch(
    `${POLLS_URL}?page=${page}&search_query=${query}`,
    getMultipartParams("GET")
  )
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => {
      notice("Error on loading the polls");
      console.error(e.errors);
      throw e;
    });
};

export const getPoll = (setterFunction, id) => {
   return fetch(`${POLLS_URL}/show?id=${id}`, getMultipartParams("GET"))
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => {
      notice("Error on loading the poll");
      console.error(e.errors);
      throw e;
    });
};

export const savePoll = (data) => {
  return fetch(POLLS_URL, getParams("POST", JSON.stringify(data)))
    .then(handleResponse)
    .then((json) => {
      notice("Poll created", "success");
      return json;
    })
    .catch((e) => {
      notice("Error saving the poll");
      console.error(e.errors);
      throw e;
    });
};

export const updatePoll = (data, id) => {
  return fetch(
    `${POLLS_URL}/update?id=${id}`,
    getParams("PUT", JSON.stringify(data))
  )
    .then(handleResponse)
    .then((json) => {
      if (json.id) {
        notice("Poll updated", "success");
        return json;
      } else {
        notice("Eror updating the poll");
        console.error(json);
      }
    })
    .catch((e) => {
      notice("Error updating the poll");
      console.error(e.errors);
    });
};

export const deletePoll = (data, selected_school) => {
  const newPoll = {
    id: data.id,
    poll: {
      name: data.name,
      close_date: formatDate("yyyy-MM-dd"),
      question_text: "",
      deleted: true,
    },
    all_schools: [],
    options: [],
    schools: [],
    slug: selected_school.slug,
   
  };
  return fetch(
    `${POLLS_URL}/update?id=${data.id}`,
    getParams("PUT", JSON.stringify(newPoll))
  )
    .then(handleResponse)
    .then((json) => {
      if (json.id) {
        notice("Poll updated", "success");
        return json;
      } else {
        notice("Eror updating the poll");
        console.error(json);
      }
    })
    .catch((e) => {
      notice("Error updating the poll");
      console.error(e.errors);
    });
};
