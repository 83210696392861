import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, Grid, TextField, DialogActions, Button, IconButton, ClickAwayListener, DialogTitle, CircularProgress, MenuItem } from '@material-ui/core'
import { EmojiEmotions } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import { Formik } from 'formik'

import { saveFeeling, updateFeeling } from "../../../crud/api/feelings.crud"
import { setLoader } from '../../../../redux/ducks/load.duck';
import * as feelingsDUCK from "../../../../redux/ducks/feelings.duck"
import { getContentsList } from '../../../crud/api/content.crud';

const FeelingsDialog = ({ mode, setDialog, feeling, school, feelActions, settingLoader }) => {
  const open = mode === 'editing' || mode === 'create'
  const title = mode === 'editing' ? "Edit Mood" : "New Mood"
  const [loading, setLoading] = useState(false);
  const [emojiPicker, setEmojiPicker] = useState(false)
  const [contents, setContents] = useState([])

  useEffect(() => {
    getContentsList(setContents)
  }, [])

  const create = async (values) => {
    try {
      await saveFeeling(feelActions.updateFeelings, school.slug, values)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  
  const update = async (values) => {
    try {
      await updateFeeling(feelActions.updateFeelings, school.slug, values, feeling.id)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  
  return (
    <Dialog open={open} onClose={() => setDialog("close")} id="feelings_dialog">
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          ...feeling,
          call_to_action_mood_id: feeling.content?.id ? feeling.content.id : ""
        }}
        validate={values => {
          const errors = {}
          if (!values.name) errors.name = 'Required field'

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true)
          setTimeout(() => {
            feeling.id ? update(values) : create(values)
            setSubmitting(false)
          }, 1000)
        }}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
        <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid container item xs={4} spacing={1}>
                <Grid item xs={8}>
                  <TextField
                    disabled
                    fullWidth
                    id="feeling_icon_input_dialog"
                    variant="outlined"
                    label="Icon"
                    name="icon"
                    value={values.icon}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ClickAwayListener onClickAway={() => setEmojiPicker(false)}>
                    <div>
                      <IconButton id="feeling_icon_btn_dialog" onClick={() => setEmojiPicker(!emojiPicker)}>
                        <EmojiEmotions />
                      </IconButton>
                      <Picker
                        onSelect={emoji => setFieldValue("icon", emoji.native)}
                        title="Pick your icon 👆"
                        style={{
                          position: "fixed",
                          zIndex: 100,
                          display: emojiPicker ? "inline-block" : "none"
                        }}
                      />
                    </div>
                  </ClickAwayListener>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  fullWidth
                  id="feeling_name_input_dialog"
                  variant="outlined"
                  label="Mood"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="feeling_definition_input_dialog"
                  variant="outlined"
                  label="Definition"
                  name="definition"
                  value={values.definition}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  id="feeling_call_to_action_content_input_dialog"
                  variant="outlined"
                  label="Call to action content"
                  name="call_to_action_mood_id"
                  value={values.call_to_action_mood_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {contents.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="cancel_feeling_btn_dialog" onClick={() => setDialog("close")} color="secondary">
              Cancel
            </Button>
            <button
              type="submit"
              id="submit_feeling_btn_dialog"
              disabled={isSubmitting || !values.name || !values.icon}
              className="btn btn-primary btn-elevate kt-login__btn-primary d-flex">
              {mode === "editing" ? "Submit" : "Create"}
              {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
            </button>
          </DialogActions>
        </form>
      )}
      </Formik>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  school: store.school.selected_school,
  feeling: store.feel.feeling,
})

const mapDispatchToProps = (dispatch) => ({
  feelActions: bindActionCreators(feelingsDUCK.actions, dispatch),
  settingLoader: bindActionCreators(setLoader, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(FeelingsDialog)