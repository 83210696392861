import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import { defaultFocusArea } from '../../app/crud/api/focus_areas.crud';

export const actionType = {
  GetFocusAreas: '[FOCUS_AREAS] GET_FOCUS_AREAS',
  GetFocusArea: '[FOCUS_AREAS] GET_FOCUS_AREA',
  UpdateFocusArea: '[FOCUS_AREAS] UPDATE_FOCUS_AREA',
}

const initialState = {
  focus_areas: [],
  focus_area: defaultFocusArea,
  selected_focus_area: null
}

export const reducer = persistReducer(
  { storage, key: 'focus_areas', withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetFocusAreas: {
        return { ...state, focus_areas: action.payload }
      }
      
      case actionType.GetFocusArea: {
        
        return { ...state, focus_area: action.payload }
      }

      case actionType.UpdateFocusArea: {
        let newData = state.focus_areas
        const index = state.focus_areas.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.focus_areas]
        } else {
          newData = state.focus_areas
          newData[index] = action.payload
        }

        return { ...state, focus_areas: newData }
      }

      default: return state

    }
  }
)

export const actions = {
  setFocusAreas: data => ({ type: actionType.GetFocusAreas, payload: data }),
  setFocusArea: data => ({ type: actionType.GetFocusArea, payload: data }),
  updateFocusArea: data => ({ type: actionType.UpdateFocusArea, payload: data }),
}