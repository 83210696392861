import React, { Component } from 'react'

import Contents from './Contents'

class ContentsWrapper extends Component {

  render() {
    return (
      <div className="table-container">
       <Contents /> 
      </div>
    )
  }
}

export default ContentsWrapper