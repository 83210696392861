import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";


export const actionType = {
  SetLoader: '[LOAD] LOADER',
}

const initialState = {
  loader: false
}

export const reducer = persistReducer(
  { storage, key: 'load', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetLoader: {
        
        return { ...state, loader: action.payload }
      }

      default: return state

    }
  }
)

export const setLoader = state => ({ type: actionType.SetLoader, payload: state })