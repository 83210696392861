import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

const CustomModal = ({
  open,
  onClose,
  title,
  width = "md",
  children,
  onConfirmButton,
  onCancelButton,
  disabledConfirmButton,
  loader,
  confirmButtonLabel = "Confirm",
  cancelButtonLabel = "Cancel",
  hideConfirmButton = false,
}) => {
  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth={width}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      {(onConfirmButton || onCancelButton) && (
        <DialogActions>
          <Button onClick={onClose || onCancelButton}>
            {cancelButtonLabel}
          </Button>
          {!hideConfirmButton && (
            <Button
              color="secondary"
              variant="contained"
              onClick={onConfirmButton}
              disabled={disabledConfirmButton}
              endIcon={loader && <CircularProgress />}
            >
              {confirmButtonLabel}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CustomModal;
