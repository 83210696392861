import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultStaffType } from "../../app/crud/api/staff_type.crud";

export const actionType = {
  GetStaffTypes: "[STAFF_TYPE] GET_STAFF_TYPES",
  GetStaffType: "[STAFF_TYPE] GET_STAFF_TYPE",
  UpdateStaffType: "[STAFF_TYPE] UPDATE_STAFF_TYPE",
  LoadStaffTypeCsv: "[STAFF_TYPE] LOAD_STAFF_TYPE_CSV",
  SelectStaffType: "[STAFF_TYPE] SELECT_STAFF_TYPE",
};

const initialState = {
  staffTypes: { data: [], isFetched: false },
  staffType: defaultStaffType,
  selectedStaffType: null,
};

export const reducer = persistReducer(
  { storage, key: "staff_type", withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetStaffTypes: {
        return {
          ...state,
          staffTypes: {
            data: action.payload.items,
            count: action.payload.total,
            page: action.payload.page,
            pages: action.payload.pages,
            rowsPerPage: action.payload.per_page,
            isFetched: true,
          },
        };
      }

      case actionType.UpdateStaffType: {
        let newData = state.staffTypes.data;
        const index = state.staffTypes.data.findIndex(
          (x) => x.staff_type.id === action.payload.staff_type.id
        );

        if (index === -1) {
          newData = [action.payload, ...state.staffTypes.data];
        } else {
          newData = state.staffTypes.data;
          newData[index] = action.payload;
        }

        return { ...state, staffTypes: { ...state.staffTypes, data: newData } };
      }

      case actionType.GetStaffType: {
        return { ...state, staffType: action.payload };
      }

      case actionType.LoadStaffTypeCsv: {
        let newData = action.payload;

        return {
          ...state,
          staffTypes: {
            ...state.staffTypes,
            data: [...state.staffTypes.data, ...newData],
          },
        };
      }

      case actionType.SelectStaffType: {
        return { ...state, selectedStaffType: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setStaffTypes: (data) => ({ type: actionType.GetStaffTypes, payload: data }),
  setStaffType: (data) => ({ type: actionType.GetStaffType, payload: data }),
  updateStaffTypes: (data) => ({
    type: actionType.UpdateStaffType,
    payload: data,
  }),
  loadStaffTypeCsv: (data) => ({
    type: actionType.LoadStaffTypeCsv,
    payload: data,
  }),
  setSelectedStaffType: (state) => ({
    type: actionType.SelectStaffType,
    payload: state,
  }),
};
