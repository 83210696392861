import React from 'react';
import DashboardListItem from './DashboardListItem';
import PropTypes from 'prop-types';

const DashboardTopList = ({ allSelected, data, itemClassName, textKey='text', percentKey='percent' }) => data.map((item) => <DashboardListItem className={`mb-2 ${itemClassName}`} key={item[textKey]} text={item[textKey]} percent={item[percentKey]} isSelected={allSelected}/>);

DashboardTopList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        percent: PropTypes.number,
    })),
    allSelected: PropTypes.bool,
};

DashboardTopList.defaultProps = {
    data: [],
    allSelected: false,
};

export default DashboardTopList;