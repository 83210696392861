import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultContentAuthor } from "../../app/crud/api/content_authors.crud";

export const actionType = {
  GetContentAuthors: "[CONTENT_AUTHOR] GET_CONTENT_AUTHORS",
  GetContentAuthor: "[CONTENT_AUTHOR] GET_CONTENT_AUTHOR",
  UpdateContentAuthor: "[CONTENT_AUTHOR] UPDATE_CONTENT_AUTHOR",
  LoadContentAuthorCsv: "[CONTENT_AUTHOR] LOAD_CONTENT_AUTHOR_CSV",
  SelectContentAuthor: "[CONTENT_AUTHOR] SELECT_CONTENT_AUTHOR",
};

const initialState = {
  content_authors: { data: [], isFetched: false },
  content_author: defaultContentAuthor,
  selected_content_author: null,
};

export const reducer = persistReducer(
  { storage, key: "ca", withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetContentAuthors: {
        return {
          ...state,
          content_authors: {
            data: action.payload.items,
            count: action.payload.total,
            page: action.payload.page,
            pages: action.payload.pages,
            rowsPerPage: action.payload.per_page,
            isFetched: true,
          },
        };
      }

      case actionType.UpdateContentAuthor: {
        let newData = state.content_authors.data;
        const index = state.content_authors.data.findIndex(
          (x) => x.id === action.payload.id
        );

        if (index === -1) {
          newData = [action.payload, ...state.content_authors.data];
        } else {
          newData = state.content_authors.data;
          newData[index] = action.payload;
        }

        return {
          ...state,
          content_authors: { ...state.content_authors, data: newData },
        };
      }

      case actionType.GetContentAuthor: {
        return { ...state, content_author: action.payload };
      }

      case actionType.LoadContentAuthorCsv: {
        let newData = action.payload;

        return {
          ...state,
          content_authors: {
            ...state.content_authors,
            data: [...state.content_authors.data, ...newData],
          },
        };
      }

      case actionType.SelectContentAuthor: {
        return { ...state, selected_content_author: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setContentAuthors: (data) => ({
    type: actionType.GetContentAuthors,
    payload: data,
  }),
  setContentAuthor: (data) => ({
    type: actionType.GetContentAuthor,
    payload: data,
  }),
  updateContentAuthors: (data) => ({
    type: actionType.UpdateContentAuthor,
    payload: data,
  }),
  loadContentAuthorCsv: (data) => ({
    type: actionType.LoadContentAuthorCsv,
    payload: data,
  }),
  setSelectedContentAuthor: (state) => ({
    type: actionType.SelectContentAuthor,
    payload: state,
  }),
};
