import React from 'react';
import Dropdown from './Dropdown';
import * as dashboardDUCK from "../../../../redux/ducks/dashboard.duck";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const DropdownMonths = ({ months = [], selected_month, dashboard_actions, queries }) => {
    const handleMonth = e => {
        const { value } = e?.target;
        const newMonth = months.find(({ date_value }) => date_value === value);
        if (!newMonth) return;
        dashboard_actions.setDashboardState({ key: 'selected_month', value: newMonth });
        dashboard_actions.setDashboardQueries({ ...queries, month: newMonth.date_value });
    };
    return (
        <Dropdown
            items={months}
            value={selected_month?.date_value || ""}
            inputFontSize={20}
            onChange={handleMonth}
            valueKey="date_value"
            textKey="str_value"
        />
    )
};


const mapStateToProps = store => ({
    months: store.dashboard.data.select_data.months,
    selected_month: store.dashboard.data.select_data.selected_month,
    queries: store.dashboard.queries,
})

const mapDispatchToProps = (dispatch) => ({
    dashboard_actions: bindActionCreators(dashboardDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DropdownMonths);