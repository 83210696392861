import React, { useState } from 'react'
import { Dialog, DialogContent, DialogActions, Grid, TextField, Button, Typography, DialogTitle, CircularProgress, MenuItem, useMediaQuery, ListItemAvatar, Avatar, ListItemText, FormControl, InputLabel, Select } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { DropNCrop } from '../../../utils/DropNCrop'

import { setLoader } from '../../../../redux/ducks/load.duck'
import * as alumniDUCK from "../../../../redux/ducks/alumni.duck"
import { deleteAlumni, saveAlumni, updateAlumni } from '../../../crud/api/alumni.crud'
import { setAlert } from '../../../../redux/ducks/alert.duck'
import { Delete } from '@material-ui/icons'

const AdminDialog = ({ mode, setDialog, settingLoader, school, alumni, alumniActions, setAlert }) => {
  const title = mode === 'editing' ? "Edit Alumni" : "New Alumni"
  const open = mode === 'editing' || mode === 'create'
  const isMobile = useMediaQuery("(max-width: 599px)")
  const [loading, setLoading] = useState(false);

  const create = async values => {
    try {
      await saveAlumni(alumniActions.updateAlumnis, {...values, school_id: school.id})
      setLoading(false); setDialog("close")
    } catch (e) {
      return setLoading(false)
    }
  }
  
  const update = async values => {
    try {
      await updateAlumni(alumniActions.updateAlumnis, alumni.id, {...values, school_id: school.id, alumnus_id: alumni.id})
      setLoading(false); setDialog("close")
    } catch (e) {
      return setLoading(false)
    }
  }

  const deleteAlumnis = (id) => {
    setAlert({
      title: "Delete Alumni",
      message: "Are you sure to permanently delete this Alumni?",
      btn_msg: "Delete Alumni",
      action: () => deleteAlumni(alumniActions.deleteAlumnis, id).then(() => setDialog("close"))
    })
  }

  const options = [
    {value: 'https://itsthezone.s3.amazonaws.com/assets/facebook.png', label: "Facebook" },
    {value: 'https://itsthezone.s3.amazonaws.com/assets/instagram.png', label: "Instagram" },
    {value: 'https://itsthezone.s3.amazonaws.com/assets/linkedin.png', label: "Linkedin" },
    {value: 'https://itsthezone.s3.amazonaws.com/assets/twitter.png', label: "Twitter" },
  ]

  return (
    <Dialog
      open={open}
      onClose={() => setDialog("close")}
      fullWidth
      maxWidth="md"
      id="alumni_dialog"
      >
        <Formik
          initialValues={{
            ...alumni,
          }}
          validate={values => {
            const errors = {}
            if (!values.first_name) errors.first_name = 'Required field'
            if (!values.last_name) errors.last_name = 'Required field'
            if (!values.email) errors.email = 'Required field'

            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            settingLoader(true)
            setLoading(true)
            setTimeout(() => {
              alumni.id ? update(values) : create(values)
              setSubmitting(false)
            }, 1000)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
            <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
              <DialogTitle disableTypography className="dialog-title">
                <Typography variant="h6">
                  {title}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2} direction={isMobile ? "column-reverse" : "row"} >
                  <Grid container item xs={12} sm={8} spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="alumni_first_name_input_dialog"
                        variant="outlined"
                        label="First Name"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.first_name && errors.first_name}
                        error={Boolean(touched.first_name && errors.first_name)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="alumni_last_name_input_dialog"
                        variant="outlined"
                        label="Last Name"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.last_name && errors.last_name}
                        error={Boolean(touched.last_name && errors.last_name)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        fullWidth
                        id="alumni_email_input_dialog"
                        variant="outlined"
                        label="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        id="alumni_social_link_input_dialog"
                        variant="outlined"
                        label="Social link"
                        name="social_link"
                        value={values.social_link}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Social icon</InputLabel>
                        <Select
                          id="alumni_social_icon_input_dialog"
                          variant="outlined"
                          label="Social icon"
                          name="social_icon"
                          value={values.social_icon}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          renderValue={selected => {
                            let i = options.findIndex(x => x.value === selected)
                            return options[i].label
                          }}
                        >
                        {options.map((op, i) =>
                        <MenuItem value={op.value} key={op.label} id={`social_icon_${i}`}>
                          <ListItemAvatar>
                            <Avatar>
                              <img width="100%" src={op.value} alt={op.label}/>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={op.label} />
                        </MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="alumni_class_year_input_dialog"
                        variant="outlined"
                        label="School year"
                        name="class_year"
                        value={values.class_year}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="alumni_sport_input_dialog"
                        variant="outlined"
                        label="Sport"
                        name="sport"
                        value={values.sport}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item sx={12} sm={4}>
                    <Grid item xs={12}>
                      <h6 className="position-absolute">Alumni picture</h6>
                    </Grid>
                    <Grid item xs={12}>
                      <DropNCrop setterFunction={setFieldValue} file={values.image} />
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={alumni.id && "justify-content-between"}>
                {alumni.id && <button id="delete_alumni_btn_dialog" type="reset" className="btn btn-danger" onClick={() => deleteAlumnis(alumni.id)}>
                  <Delete /> Delete alumni
                </button>}
                <div className="d-flex">
                  <Button id="cancel_alumni_btn_dialog" onClick={() => setDialog("close")} color="secondary">
                    Cancel
                  </Button>
                  <button
                    type="submit"
                    id="submit_alumni_btn_dialog"
                    disabled={isSubmitting || !values.first_name || !values.last_name || !values.email || (!school || school === null)}
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex">
                    {mode === "editing" ? "Submit" : "Create"}
                    {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
                  </button>
                </div>
              </DialogActions>
            </form>
          )}
        </Formik>
    </Dialog>
  )
}

const mapStateToProps = store => {
  return ({
  school: store.school.selected_school,
  alumni: store.alumni.alumni,
})}

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  alumniActions: bindActionCreators(alumniDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminDialog)