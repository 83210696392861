import React, { createContext, useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkAuthorize } from "../../../app/crud/api/users.crud";
import store from "../../../redux/store"
import * as auth from "../../../app/modules/Auth/_redux/authRedux";

const MetronicSplashScreenContext = createContext();

export function MetronicSplashScreenProvider({ children }) {
  const dispatch = useDispatch();
  const { user } = store.getState().auth
  const [count, setCount] = useState(0);
  let visible = count > 0;

  useEffect(() => {
    const splashScreen = document.getElementById("splash-screen");

    // Show SplashScreen
    if (splashScreen && visible) {
      splashScreen.classList.remove("hidden");

      return () => {
        user ? checkAuthorize()
        .then((res) => {
          dispatch(auth.actions.autorize(res?.is_admin));
          splashScreen.classList.add("hidden")
        })
        .catch(() => splashScreen.classList.add("hidden"))
        : splashScreen.classList.add("hidden")
      };
    }

    // Hide SplashScreen
    let timeout;
    if (splashScreen && !visible) {
      timeout = setTimeout(() => {
        splashScreen.classList.add("hidden");
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, user]);

  return (
    <MetronicSplashScreenContext.Provider value={setCount}>
      {children}
    </MetronicSplashScreenContext.Provider>
  );
}

export function LayoutSplashScreen({ visible = true }) {
  // Everything are ready - remove splashscreen
  const setCount = useContext(MetronicSplashScreenContext);

  useEffect(() => {
    if (!visible) {
      return;
    }

    setCount(prev => {
      return prev + 1;
    });

    return () => {
      setCount(prev => {
        return prev - 1;
      });
    };
  }, [setCount, visible]);

  return null;
}
