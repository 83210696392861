import notice from "../../utils/notice"
import { DAILY_PICKS_URL } from "../helpers/routes"
import { getMultipartParams, getParams, handleResponse } from "../helpers/validate"

export const defaultDailyPick = {
  content: null,
  display_order: "",
}

export const getDailyPicks = (setterFunction, zone_id = 1, goal_id = "", page = 1) => {
  return fetch(`${DAILY_PICKS_URL}?student_zone_id=${zone_id}&goal_id=${goal_id}&page=${page}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error fetching daily picks")
    console.error(e.errors)
  })
}

export const getDailyPick = (setterFunction, id) => {
  return fetch(`${DAILY_PICKS_URL}/show?id=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error fetching daily pick")
    console.error(e.errors)
    throw e
  })
}

export const saveDailyPick = (setterFunction, data) => {
  const jsonData = JSON.stringify(data)

  return fetch(DAILY_PICKS_URL, getParams('POST', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Daily pick saved", "success")
    return setterFunction(json)
  })
  .catch(e => {
    notice("Error fetching daily pick")
    console.error(e.errors)
    throw e
  })
}

export const updateDailyPick = (setterFunction, data, id) => {
  const jsonData = JSON.stringify(data)

  return fetch(`${DAILY_PICKS_URL}/update?id=${id}`, getParams('PUT', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Daily pick updated", "success")
    return setterFunction(json)
  })
  .catch(e => {
    notice("Error fetching daily pick")
    console.error(e.errors)
    throw e
  })
}