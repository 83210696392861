import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultTeam } from "../../app/crud/api/teams.crud";

export const actionType = {
  GetTeams: "[TEAM] GET_TEAMS",
  GetTeam: "[TEAM] GET_TEAM",
  UpdateTeam: "[TEAM] UPDATE_TEAM",
  LoadTeamCsv: "[TEAM] LOAD_TEAM_CSV",
  SelectTeam: "[TEAM] SELECT_TEAM",
};

const initialState = {
  teams: { data: [], isFetched: false },
  team: defaultTeam,
  selected_team: null,
};

export const reducer = persistReducer(
  { storage, key: "team", withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetTeams: {
        return {
          ...state,
          teams: {
            data: action.payload.items,
            count: action.payload.total,
            page: action.payload.page,
            pages: action.payload.pages,
            rowsPerPage: action.payload.per_page,
            isFetched: true,
          },
        };
      }

      case actionType.UpdateTeam: {
        let newData = state.teams.data;
        const index = state.teams.data.findIndex(
          (x) => x.id === action.payload.id
        );

        if (index === -1) {
          newData = [action.payload, ...state.teams.data];
        } else {
          newData = state.teams.data;
          newData[index] = action.payload;
        }

        return { ...state, teams: { ...state.teams, data: newData } };
      }

      case actionType.GetTeam: {
        return { ...state, team: action.payload };
      }

      case actionType.LoadTeamCsv: {
        let newData = action.payload;

        return {
          ...state,
          teams: { ...state.teams, data: [...state.teams.data, ...newData] },
        };
      }

      case actionType.SelectTeam: {
        return { ...state, selected_team: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setTeams: (data) => ({ type: actionType.GetTeams, payload: data }),
  setTeam: (data) => ({ type: actionType.GetTeam, payload: data }),
  updateTeams: (data) => ({ type: actionType.UpdateTeam, payload: data }),
  loadTeamCsv: (data) => ({ type: actionType.LoadTeamCsv, payload: data }),
  setSelectedTeam: (state) => ({ type: actionType.SelectTeam, payload: state }),
};
