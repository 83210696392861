import { Grid, lighten } from '@material-ui/core';
import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { useIcons } from '../../../hooks/useIcons';
import DashboardTitle from './DashboardTitle';

const useStyles = makeStyles(theme => ({
    container: {
        padding: '16px 8px',
    },
    iconContainer: {
        backgroundColor: ({ iconColor }) => lighten(iconColor, 0.8),
        padding: 5,
        borderRadius: 5,
        margin: 'auto',
        marginTop: 0,
        right: 10,
        position: 'relative',
    },
    icon: {
        color: ({ iconColor }) => iconColor,
    },
    bottomCardContainer: {
        marginTop: 20,
        alignItems: 'end'
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: 1,
    },
    userAvatar: {
        width: 40,
        height: 40,
        borderRadius: 40,
        margin: 5,
    }
}));

const DashboardCardUniversityHub = ({ src, date, title, views, userName, userDegree, icon, iconColor="#ffffff" }) => {
    const classes = useStyles({ iconColor });
    const Icon = useIcons(icon)
    return (
        <Paper className={classes.container}>
            <Grid container item xs={12} sm={12} md={12} lg={12}>
                <Grid container item xs={11} sm={11} md={11} lg={11}>
                    <div className="d-flex flex-column">
                        <DashboardTitle text={date} variant="grey1" fontWeight={600} fontSize={12} />
                        <div className="mt-1">
                            <DashboardTitle text={title} variant="dark.primary" fontWeight={600} fontSize={17} />
                        </div>
                    </div>
                </Grid>
                <Grid alignItems="center" container item xs={1} sm={1} md={1} lg={1}>
                    {icon && <div className={classes.iconContainer}><Icon className={classes.icon}/></div>}
                </Grid>
            </Grid>
            <Grid className={classes.bottomCardContainer} container item xs={12} sm={12} md={12} lg={12}>
                <Grid className="align-self-center" container item xs={6} sm={8} md={6} lg={6}>
                    <DashboardTitle text={`${views} views`} variant="grey1" fontSize={17} fontWeight={600}/>
                </Grid>
                <Grid alignItems="center" container item xs={6} sm={4} md={6} lg={6}>
                    <img className={classes.userAvatar} src={src} alt={src}/>
                    <div className={classes.userInfo}>
                        <DashboardTitle text={userName} variant="dark.primary" fontSize={17} fontWeight={600}/>
                        <DashboardTitle text={userDegree} variant="grey1" fontSize={14} fontWeight={500}/>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    )
};

export default DashboardCardUniversityHub;