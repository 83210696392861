import { SCHOOL_URL } from "../../crud/helpers/routes";
import {
  getMultipartParams,
  getParams,
  handleResponse,
} from "../../crud/helpers/validate";
import notice from "../../utils/notice";

export const columns = [
  {
    name: "id",
    label: "id",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "name",
    label: "Name",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "question_text",
    label: "Question",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "teams_count",
    label: "Recipient Teams",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "options_count",
    label: "Options",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "answers_count",
    label: "Answers",
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const defaultPoll = {
  name: "",
  close_date: new Date(),
  end_date: new Date(new Date().setDate(new Date().getDate() + 1)),
  question_text: "",
  options: [{ value: "" }],
  all_schools: false,
  schools: [],
};

export const searchSchools = async (query = "", setterFunction) => {
  const result = await fetch(
    `${SCHOOL_URL}/suggestions?q=${query}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch((e) => {
      notice("Error on loading the schools");
      console.error(e.errors);
      throw e;
    });
  return result;
};

export const getTeams = async (slug) => {
  const result = await fetch(
    `${SCHOOL_URL}/${slug}/teams?per_page=100`,
    getMultipartParams("GET")
  )
    .then(handleResponse)
    .catch((e) => {
      console.error(e.errors);
      throw e;
    });
  return result.items;
};
