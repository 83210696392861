import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionType = {
  SetShow: "[SHOW] TOGGLE_SHOW",
};

const initialState = {
  show: false,
};

export const reducer = persistReducer(
  { storage, key: "show", whitelist: [""] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetShow: {
        return { ...state, show: action.payload };
      }
      default:
        return state;
    }
  }
);

export const setShow = (state) => ({
  type: actionType.SetShow,
  payload: state,
});
