import React from 'react'
import { Skeleton } from '@material-ui/lab'

export   const previewLinkCard = ({ loading, preview }, link) => (loading || preview) && (
  <div className="card flex-row border-light p-4">
    <div className="p-5" style={{alignSelf: "center"}}>
      {loading ? <Skeleton animation="wave" variant="rect" width={180} height={100} />
      : preview && preview.img && preview.domain && <img src={preview.img} alt={preview.domain} style={{maxWidth: 180}}/>}
    </div>
    <div className="card-body p-2" style={{minWidth: 320, cursor: "pointer"}} onClick={e => !loading && window.open(link, "_blank")}>
      {loading ? <>
        <Skeleton animation="wave" width="30%" height={20} />
        <Skeleton animation="wave" width="60%" height={30} />
        <Skeleton animation="wave" width="100%" />
        <Skeleton animation="wave" width="100%" />
        <Skeleton animation="wave" width="100%" />
      </>
        : <>
        {preview && preview.domain && <h6 className="card-subtitle">{preview.domain}</h6>}
        {preview && preview.title && <h4 className="card-title text-primary mb-2">{preview.title}</h4>}
        {preview && preview.description && <p className="card-text">{preview.description.replace(/<[^>]*>?/gm, '')}</p>}
      </>}
    </div>
  </div>
)
