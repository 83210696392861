import React from "react";
import { TextField } from "@material-ui/core";

const SimpleInput = ({
  id,
  name,
  label,
  value,
  onChange,
  disabled,
  onBlur,
  error,
  helperText,
  multiline,
  rows,
  variant = "outlined",
}) => {
  return (
    <TextField
      fullWidth
      id={id}
      name={name}
      rows={rows}
      value={value}
      label={label}
      onBlur={onBlur}
      variant={variant}
      disabled={disabled}
      onChange={onChange}
      multiline={multiline}
      helperText={helperText}
      error={Boolean(error)}
    />
  );
};

export default SimpleInput;
