import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


export const actionType = {
  GetScheduledNotifications: "[SCHEDULED_NOTIFICATION] GET_SCHEDULED_NOTIFICATIONS",
  GetScheduledNotification: "[SCHEDULED_NOTIFICATION] GET_SCHEDULED_NOTIFICATION",
  UpdateScheduledNotification: "[SCHEDULED_NOTIFICATION] UPDATE_SCHEDULED_NOTIFICATION",
  DeleteScheduledNotification: "[SCHEDULED_NOTIFICATION] DELETE_SCHEDULED_NOTIFICATION",

};

const initialState = {
  schedule_notifications: { data: [], isFetched: false },
  schedule_notification: {},
  selected_school: null,
};

export const reducer = persistReducer(
  { storage, key: "scheduled_notification", withlist: [] },
  (state = initialState, action) => {
    
    switch (action.type) {
      
      case actionType.GetScheduledNotifications: {
        
        return {
          ...state,
          schedule_notifications: {
            data: action.payload.items,
            count: action.payload.total,
            page: action.payload.page,
            pages: action.payload.pages,
            rowsPerPage: action.payload.per_page,
            isFetched: true,
          },
        };
      }
      case actionType.UpdateScheduledNotification: {
        
        let newData = state.schedule_notifications.data;
        const index = state.schedule_notifications.data.findIndex(
          (x) => x.id === action.payload.id
        );

        if (index === -1) {
          newData = [action.payload, ...state.schedule_notifications.data];
        } else {
          newData = state.schedule_notifications.data;
          newData[index] = action.payload;
        }

        return { ...state, schedule_notifications: { ...state.schedule_notifications, data: newData } };
      }
      case actionType.DeleteScheduledNotification: {
        
        let newData = state.schedule_notifications.data;
        const index = state.schedule_notifications.data.findIndex(
          (x) => x.id === action.payload.id
        );

      
        newData = state.schedule_notifications.data;
        newData.splice(index,1);
        

        return { ...state, schedule_notifications: { ...state.schedule_notifications, data: newData } };
      }


      case actionType.GetScheduledNotification: {
        
        return { ...state, schedule_notification: action.payload };
      }

      default:
        return state;
    }
  }
);



export const actions = {
  setScheduledNotifications: (data) => ({ type: actionType.GetScheduledNotifications, payload: data }),
  setScheduledNotification: (data) => ({ type: actionType.GetScheduledNotification, payload: data }),
  updateScheduledNotification: (data) => ({ type: actionType.UpdateScheduledNotification, payload: data }),
  deleteScheduledNotification: (data) => ({ type: actionType.DeleteScheduledNotification, payload: data }),
  //updateScheduledNotification: (data) => ({ type: actionType.UpdatePoll, payload: data }),
};
