import { format } from "date-fns";
import esLocale from "date-fns/locale/es";

export const formatDate = (form, date = new Date()) => {
  console.log("formatDateFunc", date);
  return format(date, form, { locale: esLocale })
    .split(" ")
    .map((x) => x[0].toUpperCase() + x.substring(1))
    .join(" ");
};
