import { formatDate } from "../../utils/dates";

export const columns = [
  {
    name: "id",
    label: "id",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "copy",
    label: "Copy",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "created_at",
    label: "Published at",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) =>
        formatDate("dd/MM/yyyy h:mm:ss a", new Date(value)),
    },
  },
  // {
  //   name: "index",
  //   label: "Index",
  //   options: {
  //     filter: false,
  //     sort: false,
  //   },
  // },
];

export const defaultZoneIn = {
  copy: "",
  picture_1: "",
  active: true,
  index: 1,
  is_pro_athlete: false,
};
