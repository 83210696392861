import { Grid, useTheme, Paper } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import SimpleDate from '../../../Components/SimpleDate';
import { bindActionCreators } from "redux";
import * as dashboardDUCK from "../../../../redux/ducks/dashboard.duck";

const useStyles = makeStyles((theme) => ({
	container: {
	},
	containerInput: {
	},
	containerPaper: {
		backgroundColor: "white",
		height: '50px',
	},
	label: {
		color: '#9ACEFF',
		fontSize: '24px',
		fontWeight: '500',
	},
	labelDescription: {
		color: '#9ACEFF',
		fontSize: '12px',
	}

}));

const DashboardDatePickerRange = ({
	queries,
	dashboard_actions,
}) => {
	const minRangeDay = 7;
	const theme = useTheme();
	const classes = useStyles(theme);
	const [to_date, setToDate] = useState(new Date());
	const [from_date, setFromDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - minRangeDay));
	const formatDate = (fecha) => {
		const year = fecha.getFullYear();
		const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
		const day = fecha.getDate().toString().padStart(2, '0');
		return `${year}-${month}-${day}`;
	}
	const change = (params) => {

		let days = {
			to: to_date,
			from: from_date
		}
		const { name, value } = params.target;
		if (name === 'from_date') {
			const newDate = new Date(value.getFullYear(), value.getMonth(), value.getDate() + minRangeDay);
			if (to_date < newDate) {
				//setToDate(newDate);
				days.to = newDate;
			}
			//setFromDate(value);
			days.from = value;
		}

		if (name === 'to_date') {
			const newDate = new Date(value.getFullYear(), value.getMonth(), value.getDate() - minRangeDay);
			if (from_date > newDate) {
				//setFromDate(newDate);
				days.from = newDate;
			}
			//setToDate(value);
			days.to = value;
		}
		setFromDate(days.from);
		setToDate(days.to);
		dashboard_actions.setDashboardQueries({
			...queries,
			date_from: formatDate(days.from),
			date_to: formatDate(days.to),
			month: null,
		});
	}


	useEffect(() => {
		change({ target: { value: new Date(), name: 'to_date' } });
	}, [])

	return (
		<Grid
			container
			item
			spacing={2}
			columns={12}
			direction="row"
			// wrap="nowrap"
			className={classes.container}
		>
			<Grid
				container
				item
				spacing={2}
				columns={12}
				direction="row"
				wrap="nowrap"
				className={classes.container}
			>
				<Grid item >
					<span className={classes.label}>From</span>
					<Paper className={classes.containerPaper}>
						<SimpleDate
							name='from_date'
							value={from_date}
							format="MM/dd/yyyy"
							onChange={change} />
					</Paper>
				</Grid>
				<Grid item >
					<span className={classes.label}>To</span>
					<Paper className={classes.containerPaper}>
						<SimpleDate
							name='to_date'
							value={to_date}
							format="MM/dd/yyyy"
							onChange={change} />
					</Paper>
				</Grid>
			</Grid>
			<Grid
				container
				item
				spacing={2}
				columns={12}
				direction="row"
				wrap="nowrap"
				className={classes.container}
			>
				<span className={classes.labelDescription}>
					The shortest time range that can be selected is one week.
				</span>
			</Grid>
		</Grid>
	)
};

const mapStateToProps = (store) => ({
	library: store.dashboard.data.dashboard_data.library,
	queries: store.dashboard.queries,
});

const mapDispatchToProps = (dispatch) => ({
	dashboard_actions: bindActionCreators(dashboardDUCK.actions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DashboardDatePickerRange);
