import React from "react";
import { DatePicker } from "@material-ui/pickers";

const SimpleDate = ({
  name,
  id,
  label,
  value,
  onChange,
  disabled,
  format = "MM/dd/yyyy",
  onBlur,
  error,
  helperText,
  style,
  minDate,
}) => {
  return (
    <DatePicker
      autoOk
      fullWidth
      disableToolbar
      id={id}
      name={name}
      label={label}
      value={value}
      format={format}
      variant="inline"
      disabled={disabled}
      inputVariant="outlined"
      onChange={(newValue) => onChange({ target: { name, value: newValue } })}
      onBlur={onBlur}
      helperText={helperText}
      error={error}
      style={style}
      shouldDisableDate={(date) => date < minDate}
    />
  );
};

export default SimpleDate;
