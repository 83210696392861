import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Poppins"].join(","),
    },

    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#17c191",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#fff", //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#3783e7",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#f018a6",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      dashboard: {
        dark: {
          primary: "#040719",
          secondary: "#F9FEFF",
          white: "#ECFCFF",
          accent: "#00B9E1",
          grey0: "#16192B",
          grey1: "#23263F",
          grey3: "#C1CAD9",
        },
        primary: "#E8EDF6",
        "blue-green": "#BDDCEB",
        grey0: "#23263F",
        grey1: "#536880",
        grey2: "#DFEAEE",
        grey3: "#CAD5E8",
        orange: "#FE6B00",
        green: "#00C394",
        white: "#FCFFFF",
        violet: "#8509D1",
        "hyperlink-blue": "#152CFF",
        "baby-blue": "#9ACEFF",
        "not-great-blue": "#1F2095",
      },
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1,
      },
    },
    overrides: {
      MuiSpeedDialAction: {
        fab: {
          color: "#fff",
          backgroundColor: "#3783e7",
          "&:hover": {
            backgroundColor: "#2b61a8",
          },
        },
        staticTooltipLabel: {
          color: "#fff",
          backgroundColor: "#9a9a9a",
          whiteSpace: "nowrap",
        },
      },
      MuiInputLabel: {
        root: {
          backgroundColor: "#fff",
          padding: "0 4px",
        },
      },
      MuiPickersDay: {
        daySelected: {
          color: "#fff",
          backgroundColor: "#3783e7",
        },
        current: {
          color: "#3783e7",
        },
      },
      MuiCardActionArea: {
        root: {
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        },
      },
    },
    // breakpoints: {
    //   values: {
    //     xs: 375,
    //     sm: 803,
    //     md: 1321,
    //     lg: 1400,

    // xs: 803,
    // sm: 1321,
    // md: 1400,
    // lg: 1600,
    // },
    // },
  }
);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
