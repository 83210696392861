import React, { Fragment, useEffect, useState } from "react";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const SimpleAutocomplete = ({
  id,
  label,
  multiple = false,
  value = multiple ? [] : null,
  name,
  onChange,
  options = [],
  searchFunction,
  placeholder,
  disabled,
  variant = "standard",
  margin = "none",
  error,
  helperText = "",
  onBlur = console.log,
}) => {
  const [query, setQuery] = useState("");
  const [touched, setTouched] = useState(false);
  const [reqOptions, setReqOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (touched && searchFunction) {
      setLoading(true);
      searchFunction(query)
        .then(setReqOptions)
        .catch(console.log)
        .finally(() => setLoading(false));
    }
  }, [query, searchFunction, touched]);

  const handleInputChange = (e, newInputValue = "") => setQuery(newInputValue);

  return (
    <Autocomplete
      id={id}
      fullWidth
      disabled={disabled}
      loading={loading}
      multiple={multiple}
      inputValue={query}
      onInputChange={handleInputChange}
      getOptionLabel={(option) => option?.name || option?.title || ""} // LABEL TO SHOW IN OPTIONS
      options={options?.length > 0 ? options : reqOptions}
      getOptionSelected={(option, value) => option.id === value.id} // METHOD THAT COMPARES THE SELECTED OPTION WITH THE LIST OF OPTIONS
      value={value}
      onChange={(e, newValue) =>
        onChange({
          target: { name, value: newValue || (multiple ? [] : null) },
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          error={error}
          helperText={helperText}
          label={label}
          margin={margin}
          variant={variant}
          placeholder={placeholder || label}
          onBlur={(e) => {
            setTouched(false);
            // onBlur(e);
          }}
          onFocus={(e) => setTouched(true)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color={"inherit"} size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SimpleAutocomplete;
