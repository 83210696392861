import { makeStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import DashboardTopList from './DashboardTopList';

const useStyles = makeStyles(theme => ({
    normalList: {
        backgroundColor: '#0c1123'
    },
}))

const DashboardFocusAreasList = ({ focus_areas=[] }) => {
    const classes = useStyles();
    return (
    
        <DashboardTopList
            itemClassName={classes.normalList}
            data={focus_areas.slice(focus_areas.length > 3 ? -3 : -focus_areas.length)}
            textKey="name"
            percentKey="value"
        />
    );
};

const mapStateToProps = store => ({
    focus_areas: store.dashboard.data.dashboard_data.focus_areas,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFocusAreasList);