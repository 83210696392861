/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Paper,
  MuiThemeProvider,
  Button,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import { bindActionCreators } from "redux";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";

import { actionTableMuiTheme } from "../../table/table_styles";
import AlumniDialog from "./AlumniDialog";
import notice from "../../../utils/notice";
import FilterDataTable from "../../../utils/FilterDataTable";

import { setLoader } from "../../../../redux/ducks/load.duck";
import * as alumniDUCK from "../../../../redux/ducks/alumni.duck";
import {
  defaultAlumni,
  deleteAlumni,
  getAlumni,
  getAlumnis,
} from "../../../crud/api/alumni.crud";
import { setAlert } from "../../../../redux/ducks/alert.duck";
import CustomTableFooter from "../../../utils/CustomTableFooter";

const Alumni = ({
  school,
  alumnis,
  settingLoader,
  alumniActions,
  setAlert,
}) => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const [dialog, setDialog] = useState("close");
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (school && school !== null && school.slug && school.slug !== null) {
      if (query && query.length > 1)
        getAlumnis(alumniActions.setAlumnis, school.slug, 1, query);
      else getAlumnis(alumniActions.setAlumnis, school.slug, 1);
    }
  }, [query, school, alumniActions.setAlumnis]);

  const deleteAlumnis = (id) => {
    setAlert({
      title: "Delete Alumni",
      message: "Are you sure to permanently delete this Alumni?",
      btn_msg: "Delete Alumni",
      action: () => deleteAlumni(alumniActions.deleteAlumnis, id),
    });
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "user",
      label: "Image",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value &&
          value !== null &&
          value.avatar_thumb &&
          value.avatar_thumb !== null && (
            <div
              style={{
                background: `url(${value.avatar_thumb}) center center / cover no-repeat`,
                height: 40,
                width: 40,
                borderRadius: "50%",
                margin: "-5px 0",
              }}
            />
          ),
      },
    },
    {
      name: "user",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value &&
          value !== null &&
          value.first_name &&
          value.first_name !== null &&
          value.last_name &&
          value.last_name !== null && (
            <>
              {value.first_name} {value.last_name}
            </>
          ),
      },
    },
    {
      name: "user",
      label: "Email",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value &&
          value !== null &&
          value.email &&
          value.email !== null && <>{value.email}</>,
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) =>
          value && (
            <div className="d-flex">
              <Tooltip title="Edit Alumni" placement="top">
                <a
                  id={`edit_alumni_btn_${value}`}
                  className="btn btn-icon btn-sm btn-light btn-hover-primary "
                  onClick={() => openDialog("editing", value)}
                >
                  <i className="fas fa-edit text-primary" />
                </a>
              </Tooltip>
              <Tooltip title="Delete Alumni" placement="top">
                <a
                  id={`delete_alumni_btn_${value}`}
                  className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                  onClick={() => deleteAlumnis(value)}
                >
                  <Delete />
                </a>
              </Tooltip>
            </div>
          ),
      },
    },
  ];

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: alumnis.rowsPerPage,
    page: alumnis.page,
    count: alumnis.count,
    rowHover: false,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: "none",
    tableBodyMaxHeight: isDesktop ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data",
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "alumni.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayRowsOnly: true,
      },
    },
    customFooter: (count, page, rowsPerPage) => {
      settingLoader(true);
      return (
        <CustomTableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={(mPage) => {
            if (
              school &&
              school !== null &&
              school.slug &&
              school.slug !== null
            ) {
              getAlumnis(
                alumniActions.setAlumnis,
                school.slug,
                mPage + 1,
                query
              );
            } else notice("Please select a school", "warning");
          }}
        />
      );
    },
  };

  const openDialog = (mode, id = null) => {
    switch (mode) {
      case "create":
        settingLoader(true);
        alumniActions.setAlumni(defaultAlumni);
        setDialog(mode);
        break;
      case "editing":
        settingLoader(true);
        getAlumni(alumniActions.setAlumni, id).then(() => setDialog(mode));
        break;
      case "close":
        setDialog(mode);
        setTimeout(() => alumniActions.setAlumni(defaultAlumni), 500);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  };

  return (
    <div className="table-container">
      <div className="pb-4 d-flex justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} />
        <Button
          id="create-alumni_btn"
          variant="contained"
          color="secondary"
          onClick={() => openDialog("create")}
        >
          <Add /> New alumni
        </Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={actionTableMuiTheme()}>
          <MUIDataTable
            // title="Admin"
            columns={columns}
            data={alumnis.data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      <AlumniDialog mode={dialog} setDialog={openDialog} />
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    school: store.school.selected_school,
    alumnis: store.alumni.alumnis,
  };
};

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  alumniActions: bindActionCreators(alumniDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alumni);
