import { SCHOOL_URL } from "../helpers/routes";
import { handleResponse, getMultipartParams } from "../helpers/validate";
import notice from "../../utils/notice";

export const defaultSchool = {
  active: true,
  conference: null,
  name: "",
  mascot_name: "",
  address: "",
  primary_contact: "",
  contact_email: "",
  contact_number: "",
  proof_of_concept: false,
  is_pro_athlete: false,
  image: null,
  not_great_dialog_copy:
    "If you are in crisis or may have an emergency, call 911 or your doctor immediately.\n\nIf you are having thoughts of harming yourself or others, call 1-800-273-TALK (8255) to talk to a skilled, trained counselor at any time (National Suicide Prevention Lifeline).",
};

export const getSchools = (setterFunction, page = 1, query = "") => {
  return fetch(
    `${SCHOOL_URL}?page=${page}&search_query=${query}`,
    getMultipartParams("GET")
  )
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => {
      notice("Error on loading the schools");
      console.error(e.errors);
      throw e;
    });
};

export const getSchool = (setterFunction, id) => {
  return fetch(`${SCHOOL_URL}/show?id=${id}`, getMultipartParams("GET"))
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => {
      notice("Error on loading the school");
      console.error(e.errors);
      throw e;
    });
};

export const saveSchool = (setterFunction, data) => {
  const formData = new FormData();

  for (const name in data) {
    formData.append(name, data[name]);
  }

  return fetch(SCHOOL_URL, getMultipartParams("POST", formData))
    .then((res) => handleResponse(res))
    .then((json) => {
      notice("School created", "success");
      setterFunction(json);
    })
    .catch((e) => {
      notice("Error saving the school");
      console.error(e.errors);
      throw e;
    });
};

export const updateSchool = (setterFunction, data, id) => {
  const formData = new FormData();

  for (const name in data) {
    formData.append(name, data[name]);
  }

  return fetch(
    `${SCHOOL_URL}/update?id=${id}`,
    getMultipartParams("PUT", formData)
  )
    .then((res) => handleResponse(res))
    .then((json) => {
      if (json.id) {
        setterFunction(json);
        notice("School updated", "success");
      } else {
        notice("Eror updating the school");
        console.error(json);
      }
    })
    .catch((e) => {
      notice("Error updating the school");
      console.error(e.errors);
    });
};

export const searchSchools = (query = "", setterFunction) => {
  return fetch(
    `${SCHOOL_URL}/suggestions?q=${query}`,
    getMultipartParams("GET")
  )
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => console.error(e.errors));
};

export const deleteSchool = (data, id) => {
  const formData = new FormData();

  for (const name in data) {
    formData.append(name, data[name]);
  }

  return fetch(
    `${SCHOOL_URL}/update?id=${id}`,
    getMultipartParams("PUT", formData)
  )
    .then((res) => handleResponse(res))
    .then((json) => {
      if (json.id) {
        notice("School deleted", "success");
      } else {
        notice("Eror deleting the school");
        console.error(json);
      }
    })
    .catch((e) => {
      notice("Error deleting the school");
      console.error(e.errors);
    });
};
