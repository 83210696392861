import notice from "../../utils/notice";
import { TELEHELP_TOPICS_URL } from "../helpers/routes";
import { getParams, handleResponse } from "../helpers/validate";

export const getListTelehelpTopics = (page) => {
  return fetch(`${TELEHELP_TOPICS_URL}?page=${page}`, getParams("GET"))
    .then(handleResponse)
    .catch((e) => {
      console.error(e.errors);
      notice("Error on loading Staff Support topics");
      throw e;
    });
};

export const getSingleTelehelpTopic = (id) => {
  return fetch(`${TELEHELP_TOPICS_URL}/${id}`, getParams("GET"))
    .then(handleResponse)
    .catch((e) => {
      console.error(e.errors);
      notice("Error on loading Staff Support Topic");
      throw e;
    });
};

export const createTelehelpTopic = (data) => {
  return fetch(
    `${TELEHELP_TOPICS_URL}`,
    getParams("POST", JSON.stringify(data))
  )
    .then(handleResponse)
    .catch((e) => {
      console.error(e.errors);
      notice("Error on creating Staff Support Topic");
      throw e;
    });
};

export const updateTelehelpTopic = (data) => {
  return fetch(
    `${TELEHELP_TOPICS_URL}/${data.id}`,
    getParams("PUT", JSON.stringify(data))
  )
    .then(handleResponse)
    .catch((e) => {
      console.error(e.errors);
      notice("Error on updating Staff Support Topic");
      throw e;
    });
};

export const deleteTelehelpTopic = (id) => {
  return fetch(`${TELEHELP_TOPICS_URL}/${id}`, getParams("DELETE"))
    .then(handleResponse)
    .catch((e) => {
      console.error(e.errors);
      notice("Error on deleting Staff Support Topic");
      throw e;
    });
};
