import React from 'react';
import Dropdown from './Dropdown';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';

const DashboardDownloadDropdown = () => true ? null : (
    <Dropdown 
        items={[
            { value: 0, text: 'Download' },
            { value: 1, text: 'PDF' },
            { value: 2, text: 'CSV' },
        ]} 
        value={0}
        IconComponent={SystemUpdateAltIcon}
    />
);

export default DashboardDownloadDropdown;    