import {
  Dialog,
  MuiThemeProvider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React from "react";
import {
  mindflowDialogColumns,
  mindflowDialogOptions,
} from "./utils/mindflow-dialog-colums";
import { actionTableMuiTheme } from "../table/table_styles";

const InterestedInMindflowDialog = ({ isOpen, setIsOpen, data }) => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="md"
      fullWidth
      id="interested_in_mindflow_dialog"
    >
      <div style={{ padding: "20px" }}>
        <Typography variant="h5" style={{ marginBottom: "20px" }}>
          Interested in Mindflow
        </Typography>
        {data.length > 0 ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Typography variant="h6" style={{ marginLeft: "auto" }}>
              Total: {data.length}
            </Typography>
            <MuiThemeProvider theme={actionTableMuiTheme()}>
              <MUIDataTable
                columns={mindflowDialogColumns}
                data={data}
                options={{
                  ...mindflowDialogOptions,
                  tableBodyMaxHeight: isDesktop ? "50em" : "357px",
                }}
              />
            </MuiThemeProvider>
          </div>
        ) : (
          <Typography variant="h6">
            There is no people interested in Mindlflow in this school
          </Typography>
        )}
      </div>
    </Dialog>
  );
};

export default InterestedInMindflowDialog;
