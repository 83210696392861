import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultStudent } from "../../app/crud/api/students.crud";

export const actionType = {
  GetStudents: "[STUDENT] GET_STUDENTS",
  GetStudent: "[STUDENT] GET_STUDENT",
  UpdateStudent: "[STUDENT] UPDATE_STUDENT",
  DeleteStudent: "[STUDENT] DELETE_STUDENT",
  LoadStudentCsv: "[STUDENT] LOAD_STUDENT_CSV",
  SelectStudent: "[STUDENT] SELECT_STUDENT",
};

const initialState = {
  students: { data: [], isFetched: false },
  student: defaultStudent,
  selected_student: null,
};

export const reducer = persistReducer(
  { storage, key: "student", withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetStudents: {
        return {
          ...state,
          students: {
            data: action.payload.items,
            count: action.payload.total,
            page: action.payload.page,
            pages: action.payload.pages,
            rowsPerPage: action.payload.per_page,
            isFetched: true,
          },
        };
      }

      case actionType.UpdateStudent: {
        let newData = state.students.data;
        const index = state.students.data.findIndex(
          (x) => x.id === action.payload.id
        );

        if (index === -1) {
          newData = [action.payload, ...state.students.data];
        } else {
          newData = state.students.data;
          newData[index] = action.payload;
        }

        return { ...state, students: { ...state.students, data: newData } };
      }

      case actionType.DeleteStudent: {
        let newData = state.students.data;
        const index = state.students.data.findIndex(
          (x) => x.id === action.payload.id
        );

        if (index === -1) {
          newData = [action.payload, ...state.students.data];
        } else {
          newData = state.students.data;
          newData.splice(index, 1);
        }

        return { ...state, students: { ...state.students, data: newData } };
      }

      case actionType.GetStudent: {
        
        return { ...state, student: action.payload };
      }

      case actionType.LoadStudentCsv: {
        let newData = action.payload;

        return {
          ...state,
          students: {
            ...state.students,
            data: [...state.students.data, ...newData],
          },
        };
      }

      case actionType.SelectStudent: {
        return { ...state, selected_student: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setStudents: (data) => ({ type: actionType.GetStudents, payload: data }),
  setStudent: (data) => ({ type: actionType.GetStudent, payload: data }),
  updateStudents: (data) => ({ type: actionType.UpdateStudent, payload: data }),
  deleteStudents: (data) => ({ type: actionType.DeleteStudent, payload: data }),
  loadStudentCsv: (data) => ({
    type: actionType.LoadStudentCsv,
    payload: data,
  }),
  setSelectedStudent: (state) => ({
    type: actionType.SelectStudent,
    payload: state,
  }),
};
