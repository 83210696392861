import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";

import * as subfactorDUCK from "../../../../redux/ducks/subfactor.duck.js";
import {
  saveSubfactor,
  updateSubfactor,
  getParentFactor,
} from "../../../crud/api/subfactors.crud.js";
import SimpleSwitch from "../../../Components/SimpleSwitch.js";
import SimpleSelect from "../../../Components/SimpleSelect.js";

const SubFactorDialog = ({
  mode,
  setDialog,
  subfactor,
  subfactorActions,
  school,
  staff_member_admin,
  is_admin,
}) => {
  const open = mode === "editing" || mode === "create";
  const title = mode === "editing" ? "Edit SubFactor" : "New SubFactor";
  const [loading, setLoading] = useState(false);
  const [parent_factors, setParentFactors] = useState([]);
  const [
    filteredContributingFactors,
    setFilteredContributingFactors,
  ] = useState(parent_factors);

  const [isPro, setIsPro] = useState(false);

  useEffect(() => {
    if (parent_factors.length === 0) {
      getParentFactor(setParentFactors);
    }
  }, []);

  const create = async (values) => {
    const parent_factor = parent_factors.find(
      (x) => x.value === values.parent_factor
    );
    const newSubfactor = {
      name: values.name,
      description: values.description,
      active: values.active,
      is_pro_athlete: isPro,
      parent_factor: values.parent_factor,
      name_parent_factor: parent_factor.text,
    };
    try {
      await saveSubfactor(subfactorActions.updateSubfactor, newSubfactor);
      setIsPro(false);
      setDialog("close");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const update = async (values) => {
    const parent_factor = parent_factors.find(
      (x) => x.value === values.parent_factor
    );
    const newSubfactor = {
      name: values.name,
      description: values.description,
      active: values.active,
      is_pro_athlete: isPro,
      parent_factor: values.parent_factor,
      id: subfactor.id,
      name_parent_factor: parent_factor.text,
    };

    try {
      await updateSubfactor(
        subfactorActions.updateSubfactor,
        newSubfactor,
        subfactor.id
      );
      setIsPro(false);
      setDialog("close");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const filterContributingFactors = () => {
    let newFactors;
    if (!isPro) {
      newFactors = parent_factors.filter(
        (item) => item.value >= 1 && item.value <= 4
      );
    } else {
      newFactors = parent_factors.filter(
        (item) => item.value >= 4 && item.value <= 7
      );
    }
    setFilteredContributingFactors(newFactors);
  };

  useEffect(() => {
    filterContributingFactors();
  }, [isPro, parent_factors]);

  return (
    <Dialog
      open={open}
      onClose={() => setDialog("close")}
      id="subfactor_dialog"
    >
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          ...subfactor,
          parent_factors: subfactor.parent_factors
            ? subfactor.parent_factors
            : [],
        }}
        validate={(values) => {
          const errors = {};

          if (!values.name) errors.name = "Required field";
          if (!values.description) errors.description = "Required field";
          if (!values.parent_factor) errors.parent_factor = "Required field";

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          subfactor.id ? update(values) : create(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
        }) => (
          <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
            <DialogContent style={{ maxHeight: "auto" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    disabled={false}
                    required
                    fullWidth
                    variant="outlined"
                    id="subfactor_name_input_dialog"
                    label="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={false}
                    required
                    fullWidth
                    variant="outlined"
                    id="subfactor_description_input_dialog"
                    label="Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.description && errors.description}
                    error={Boolean(touched.description && errors.description)}
                  />
                </Grid>

                <Grid container item xs={7}>
                  <Grid container item xs={12}>
                    <SimpleSelect
                      name="parent_factor"
                      label="Parent Factor"
                      value={
                        values.parent_factor == ""
                          ? (values.parent_factor = 1)
                          : values.parent_factor
                      }
                      onChange={handleChange}
                      options={filteredContributingFactors}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Checkbox
                    required
                    id="is_pro_athlete"
                    name="is_pro_athlete"
                    checked={values.is_pro_athlete}
                    onChange={() => setIsPro(!isPro)}
                  />
                  <span>Pro atlhete</span>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <SimpleSwitch
                    name="active"
                    label="Active"
                    value={values.active}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div
                className={
                  staff_member_admin || is_admin
                    ? "d-flex"
                    : "d-flex justify-content-end w-100"
                }
              >
                {staff_member_admin || is_admin ? (
                  <>
                    {" "}
                    <Button
                      onClick={() => setDialog("close")}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      className="btn btn-primary btn-elevate kt-login__btn-primary d-flex"
                    >
                      {mode === "editing" ? "Save" : "Create"}
                      {loading && (
                        <CircularProgress
                          color="inherit"
                          size={18}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={() => setDialog("close")}
                    id="close_student_btn_dialog"
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex ml-3"
                  >
                    Close
                  </button>
                )}
              </div>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

const mapStateToProps = (store) => ({
  subfactor: store.subfactors.subfactor,
  school: store.school.selected_school,
  staff_member_admin: store.auth.user?.staff_member_admin,
  is_admin: store.auth.user?.is_admin,
});

const mapDispatchToProps = (dispatch) => ({
  subfactorActions: bindActionCreators(subfactorDUCK.actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubFactorDialog);
