import React, { useState } from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { setLoader } from '../../../../redux/ducks/load.duck'
import * as contentAuthorDUCK from "../../../../redux/ducks/content_author.duck"
import { Dropper } from '../../../utils/DropNCrop'
import { saveContentAuthor, updateContentAuthor } from '../../../crud/api/content_authors.crud'

const ContentAuthorsDialog = ({ mode, setDialog, content_author, caActions, settingLoader, school }) => {
  const open = mode === 'editing' || mode === 'create'
  const title = mode === 'editing' ? "Edit Content Author" : "New Content Author"
  const [loading, setLoading] = useState(false);

  const create = async (values) => {
    try {
      await saveContentAuthor(caActions.updateContentAuthors, values)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  
  const update = async (values) => {
    try {
      await updateContentAuthor(caActions.updateContentAuthors, values, content_author.id)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={() => setDialog("close")} id="content_author_dialog">
      <DialogTitle disableTypography className="dialog-title">
        <Typography variant="h6">
          {title}
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          ...content_author,
        }}
        validate={values => {
          const errors = {}
          if (!values.name) errors.name = 'Required field'

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true)
          setTimeout(() => {
            content_author.id ? update(values) : create(values)
            setSubmitting(false)
          }, 1000)
        }}
      >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
        <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="content_author_name_input_dialog"
                  variant="outlined"
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h5>Author logo</h5>
                <Dropper setterFunction={setFieldValue} file={values.logo} field="logo" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h5>Display image</h5>
                <Dropper setterFunction={setFieldValue} file={values.display_image} field="display_image" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="cancel_content_author_btn_dialog" onClick={() => setDialog("close")} color="secondary">
              Cancel
            </Button>
            <button
              type="submit"
              id="submit_content_author_btn_dialog"
              disabled={isSubmitting || !values.name || !values.logo || !values.display_image}
              className="btn btn-primary btn-elevate kt-login__btn-primary d-flex">
              {mode === "editing" ? "Submit" : "Create"}
              {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
            </button>
          </DialogActions>
        </form> 
      )}
      </Formik>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  content_author: store.ca.content_author,
  school: store.school.selected_school,
})

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  caActions: bindActionCreators(contentAuthorDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContentAuthorsDialog)
