import notice from "../../utils/notice"
import { MOODS_URL } from "../helpers/routes"
import { getMultipartParams, handleResponse } from "../helpers/validate"

export const getStudentMoods = (slug, setterFunction) => {
  return fetch(`${MOODS_URL}/get_student_mood_percentages_by_week?slug=${slug}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => {
    
    return setterFunction(json);

  })
  .catch(e => {
    console.error(e.errors)
    throw e
  })
}

export const getTopMoodDay = (slug, setterFunction) => {
  return fetch(`${MOODS_URL}/get_top_mood_of_the_day?slug=${slug}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => 
    
    {
      
      return json && json.feeling && setterFunction(json);
    })
  .catch(e => {
    console.error(e.errors)
    notice("There´s something wrong, please wait and reload the page")
    throw e
  })
}

export const getTopMoodWeek = (slug, setterFunction) => {
  return fetch(`${MOODS_URL}/get_top_mood_of_the_week?slug=${slug}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => {
    
    return setterFunction(json)
  })
  .catch(e => {
    console.error(e.errors)
    throw e
  })
}
