import React, { useEffect, useState } from "react";
import {
  Grid,
  Checkbox,
  MenuItem,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import SimpleAutocomplete from "../../Components/SimpleAutocomplete";
import { defaulScheduledNotification, searchSchools } from "./utils";
import CustomModal from "../../Components/CustomModal";
import SimpleInput from "../../Components/SimpleInput";
import SimpleDate from "../../Components/SimpleDate";
import * as scheduleNotificationActions from "../../../redux/ducks/scheduled_notification.duck";
import {
  createScheduledNotification,
  updateScheduledNotification,
} from "../../crud/api/scheduled_notifications.crud";
import {
  hour24tohour12,
  hour24tohour12GetPMAM,
} from "../../utils/convertHours";

const FormDialog = ({ mode, setDialog }) => {
  const { selected_school, user, selectedScheduledNotification } = useSelector(
    (store) => ({
      selected_school: store.school.selected_school,
      user: store.auth.user,
      selectedScheduledNotification:
        store.scheduled_notification.schedule_notification,
    })
  );
  const [scheduledNotification, setScheduleNotification] = useState(
    defaulScheduledNotification
  );
  const [errors, setErrors] = useState(defaulScheduledNotification);
  const [loading, setLoading] = useState(false);
  const open = mode === "editing" || mode === "create";
  const dispatch = useDispatch();
  const title =
    mode === "editing"
      ? "Edit Scheduled Notification"
      : "New Scheduled Notification";
  const handleClose = () => {
    setDialog("close");
    setScheduleNotification({
      ...defaulScheduledNotification,
      options: [{ value: "" }],
    });
  };

  useEffect(() => {
    if (mode === "editing") {
      selectedScheduledNotification.send_at =
        selectedScheduledNotification.send_at +
        " GMT-0600 (Central Standard Time)";

      selectedScheduledNotification.time_at = new Date(
        "1995-12-17T" + selectedScheduledNotification.send_at.substring(11, 19)
      );

      selectedScheduledNotification.hour = hour24tohour12(
        selectedScheduledNotification.time_at.getHours()
      );
      selectedScheduledNotification.minute = selectedScheduledNotification.time_at.getMinutes();
      selectedScheduledNotification.AMPM = hour24tohour12GetPMAM(
        selectedScheduledNotification.time_at.getHours()
      );

      setScheduleNotification({ ...selectedScheduledNotification });
    }
  }, [mode, selectedScheduledNotification]);

  const handleChange = ({ target }, index) => {
    if (target.name.includes("option")) {
      let updatedOptions = scheduledNotification.options;
      updatedOptions[index].value = target.value;
      setScheduleNotification({ ...scheduledNotification });
    } else
      setScheduleNotification({
        ...scheduledNotification,
        [target.name]: target.value,
      });
  };

  const handleBlur = ({ target }) =>
    setErrors({ ...errors, [target.name]: !target.value && "Required field" });

  const create = () => {
    setLoading(true);

    createScheduledNotification(scheduledNotification)
      .then((res) => {
        dispatch(
          scheduleNotificationActions.actions.updateScheduledNotification(res)
        );
        handleClose();
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };
  const handleAllTeamsChange = (e, v) => {
    setScheduleNotification({ ...scheduledNotification, all_schools: v });
  };
  const update = () => {
    setLoading(true);

    updateScheduledNotification(scheduledNotification, scheduledNotification.id)
      .then((res) => {
        dispatch(
          scheduleNotificationActions.actions.updateScheduledNotification(res)
        );
        handleClose();
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  return (
    <CustomModal
      open={open}
      width="md"
      title={title}
      loader={loading}
      onClose={handleClose}
      onConfirmButton={() => (scheduledNotification.id ? update() : create())}
      hideConfirmButton={!(user.staff_member_admin || user.is_admin)}
      disabledConfirmButton={
        loading ||
        scheduledNotification.title == "" ||
        scheduledNotification.body == "" ||
        (!scheduledNotification.all_schools &&
          scheduledNotification.schools_id.length == 0)
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SimpleInput
            id="poll_name_input_dialog"
            label="Tilte"
            name="title"
            value={scheduledNotification?.title}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.title}
            helperText={errors.title}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SimpleInput
            id="poll_name_input_dialog"
            label="Copy"
            name="body"
            value={scheduledNotification?.body}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.body}
            helperText={errors.body}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SimpleDate
            id="poll_close_date_input_dialog"
            label="Date"
            name="send_at"
            value={scheduledNotification?.send_at}
            onChange={handleChange}
            format="MMMM dd yyyy"
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            select
            required
            fullWidth
            variant="outlined"
            id="content_select_type_input_dialog"
            label="Hour"
            name="hour"
            value={scheduledNotification?.hour}
            onChange={handleChange}
          >
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="6">6</MenuItem>
            <MenuItem value="7">7</MenuItem>
            <MenuItem value="8">8</MenuItem>
            <MenuItem value="9">9</MenuItem>
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="11">11</MenuItem>
            <MenuItem value="12">12</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            select
            required
            fullWidth
            variant="outlined"
            id="content_select_type_input_dialog"
            label="Minute"
            name="minute"
            value={scheduledNotification?.minute}
            onChange={handleChange}
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="15">15</MenuItem>
            <MenuItem value="20">20</MenuItem>
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="30">30</MenuItem>
            <MenuItem value="35">35</MenuItem>
            <MenuItem value="40">40</MenuItem>
            <MenuItem value="45">45</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="55">55</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <RadioGroup
            onChange={handleChange}
            row="true"
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={scheduledNotification?.AMPM}
            name="AMPM"
          >
            <FormControlLabel value="AM" control={<Radio />} label="AM" />
            <FormControlLabel value="PM" control={<Radio />} label="PM" />
          </RadioGroup>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
              <SimpleTime
                id="poll_close_date_input_dialog"
                label="Time"
                name="time_at"
                value={scheduledNotification?.time_at}
                onChange={handleChange}
                format="hh:mm a"
              />
            </Grid> */}

        <Grid item xs={12} sm={12}>
          <Checkbox
            id="all_teams_checbox"
            value={scheduledNotification.all_schools ? 1 : 0}
            checked={scheduledNotification.all_schools}
            name="all_schools"
            onChange={(e, v) => handleAllTeamsChange(e, v)}
          />{" "}
          Notifiy all Athletes in all Organizations
        </Grid>
        <Grid item xs={12}>
          <SimpleAutocomplete
            multiple
            id="tags-schools"
            name="schools_id"
            label="Organizations"
            disabled={scheduledNotification.all_schools}
            searchFunction={searchSchools}
            value={scheduledNotification.schools_id}
            onChange={({ target }) => {
              setScheduleNotification({
                ...scheduledNotification,
                schools_id: target.value,
              });
            }}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default FormDialog;
