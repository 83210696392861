import { MenuItem } from "@material-ui/core";
import { makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import DashboardListItem from "./DashboardListItem";
import DashboardTitle from "./DashboardTitle";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#16192B",
    padding: 10,
    borderRadius: 8,
    width: "100%",
  },
  menuItem: {
    padding: "4px 0",
    width: "100%",
  },
}));

const DashboardCardList = ({ contributing_factors }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.container}>
      <div className="mb-5">
        <DashboardTitle
          variant="blue-green"
          fontWeight={500}
          fontSize={20}
          text="Contributing Factors"
        />
      </div>
      {contributing_factors?.length &&
        contributing_factors.map((factor) => (
          <MenuItem key={factor.position} className={classes.menuItem}>
            <DashboardListItem
              text={factor.name}
              percent={factor.value}
              icon={factor.icon}
              isSelected={factor.position === 1}
            />
          </MenuItem>
        ))}
    </Paper>
  );
};

const mapStateToProps = (store) => ({
  contributing_factors:
    store.dashboard.data.dashboard_data.contributing_factors,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCardList);
