import { SCHOOL_URL, TEAMS_URL } from "../helpers/routes"
import { handleResponse, getParams, getMultipartParams } from "../helpers/validate"
import notice from "../../utils/notice"

export const defaultTeam = {
  name: "",
  sport_id: "",
  school_id: "",
  staff: [
    {
      description: "",
      staff_member_id: null, // coach
    },
  ]
}

export const getTeams = (setterFunction, slug, page = 1, query= "") => {
  if (slug) {
    return fetch(`${SCHOOL_URL}/${slug}/teams?page=${page}&search_query=${query}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
      notice("Error on loading teams")
      console.error(e.errors)
      throw e
    })
  }
}

export const getTeam = (setterFunction, id) => {
  return fetch(`${TEAMS_URL}/show?id=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error on loading teams")
    console.error(e.errors)
    throw e
  })
}

export const saveTeam = (setterFunction, data) => {
  const jsonData = JSON.stringify(data)

  return fetch(TEAMS_URL, getParams('POST', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Team created", "success")
    setterFunction(json)
  })
  .catch(e => {
    notice("Error saving the team")
    console.error(e.errors)
    throw e
  })
}

export const updateTeam = (setterFunction, data, id) => {
  const jsonData = JSON.stringify(data)

  return fetch(`${TEAMS_URL}/update?id=${id}`, getParams('PUT', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Team updated", "success")
    return setterFunction(json)
  })
  .catch(e => {
    notice("Error saving the team")
    console.error(e.errors)
    throw e
  })
}

export const deleteTeam = (id) => {
  return fetch(`${TEAMS_URL}/delete`, getParams('DELETE', JSON.stringify({id})))
  .then(handleResponse)
  .then(json => {
    notice("Team deleted", "success")
  })
  .catch(e => {
    notice("Error saving the team")
    console.error(e.errors)
    throw e
  })
}