import { getParams, handleResponse,getMultipartParams } from "../helpers/validate";
import { PERMISSION } from "../helpers/routes";
import notice from "../../utils/notice";

export const getPermisssion = async (school_id) => {
  
    return await fetch(`${PERMISSION}/get-by-school/${school_id}`, getMultipartParams("GET"))
      .then((res) => handleResponse(res))
      .then((json) => {
       return json;
      })
      .catch((e) => {
        notice("Error get the Permission");
        console.error(e.errors);
        throw e;
      });
 
};

  
export const addPermisssion = (data) => {
  
  const jsonData = JSON.stringify({"data":data});
  return fetch(
   `${PERMISSION}/create`,
    getParams("POST", jsonData)
  )
    .then((res) => handleResponse(res))
    .then((json) => notice("permissions saved successfully", "success"))
    .catch((e) => {
      notice("Error saving permissions");
      console.error(e.errors);
      throw e;
    });
};

export const setAllSchool = (data) => {
  
    const jsonData = JSON.stringify(data);
    return fetch(
     `${PERMISSION}/set_all_school`,
      getParams("POST", jsonData)
    )
      .then((res) => handleResponse(res))
      .then((json) => notice("Save All ", "success"))
      .catch((e) => {
        notice("Error saving");
        console.error(e.errors);
        throw e;
      });
  };


export const updatePermisssion = (data) => {
  
    const jsonData = JSON.stringify(data);
    return fetch(
     `${PERMISSION}/update`,
      getParams("PUT", jsonData)
    )
      .then((res) => handleResponse(res))
      .then((json) => notice("Permission updated", "success"))
      .catch((e) => {
        notice("Error saving the Permission");
        console.error(e.errors);
        throw e;
      });
  };
  