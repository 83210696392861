export default {
  aside: {
    self: {},
    items: [
      {
        clave: "dash001",
        title: "Insights",
        root: true,
        icon: "Map/Position.svg",
        page: "/dashboard",
      },
      /*{
        title: "Student Goals",
        root: true,
        icon: "Shopping/Chart-bar2.svg",
        page: "/goals"
      },*/
      {
        title: "organization_hub",
        root: true,
        icon: "Design/Layers.svg",
        page: "/announcements",
      },
      {
        title: "Send Alerts",
        root: true,
        admin: false,
        icon: "Communication/Archive.svg",
        page: "/push-notifications",
      },
      {
        title: "Send Check-in",
        root: true,
        icon: "Communication/Mail-opened.svg",
        page: "/polls",
        admin: false,
      },
      // {
      //   title: "Calendar",
      //   root: true,
      //   icon: "Layout/Layout-top-panel-6.svg",
      //   page: "/calendar"
      // },
      {
        title: "Manage Users",
        root: true,
        icon: "General/User.svg",
        page: "/users",
        submenu: [
          {
            title: "Athletes",
            page: "/users/student",
          },
          {
            title: "Staff",
            page: "/users/staff",
          },
          // {
          //   title: "Admin",
          //   page: "/users/admin"
          // },
          /*{
            title: "Alumni",
            page: "/users/alumni"
          },*/
        ],
      },
      {
        title: "Manage Teams",
        root: true,
        icon: "Communication/Group.svg",
        page: "/teams",
      },
      {
        title: "Organizations",
        root: true,
        icon: "Home/Building.svg",
        admin: true,
        page: "/schools",
      },
      {
        title: "Library",
        root: true,
        icon: "Home/Book-open.svg",
        page: "/contents",
        admin: true,
      },
      {
        title: "Zone in",
        root: true,
        icon: "Map/Direction1.svg",
        page: "/zone-in",
        admin: true,
      },
      {
        title: "Notifications",
        root: true,
        icon: "Design/Substract.svg",
        page: "/notifications",
        admin: true,
      },
      // {
      //   title: "Daily Picks",
      //   root: true,
      //   icon: "Shopping/Loader.svg",
      //   page: "/daily_picks",
      //   submenu: [
      //     {
      //       title: "Mind",
      //       page: "/daily_picks/mind"
      //     },
      //     {
      //       title: "Body",
      //       page: "/daily_picks/body"
      //     },
      //     {
      //       title: "Life",
      //       page: "/daily_picks/life"
      //     },
      //   ]
      // },
      {
        title: "Configuration",
        root: true,
        icon: "Design/Component.svg",
        page: "/config",
        admin: true,
        submenu: [
          // {
          //   title: "Staff types",
          //   page: "/config/staff_types"
          // },
          // {
          //   title: "Moods",
          //   page: "/config/emotions"
          // },
          // {
          //   title: "Sports",
          //   page: "/config/sports"
          // },
          /*{
            title: "Conferences",
            page: "/config/conference"
          },*/
          // {
          //   title: "Goals",
          //   page: "/config/goal_types"
          // },
          /*{
            title: "Content Authors",
            page: "/config/content_authors"
          },*/
          {
            title: "Focus Areas",
            page: "/config/focus_areas",
            admin: true,
          },
          {
            title: "Submoods",
            root: true,
            icon: "Home/Bulb1.svg",
            page: "/config/submoods",
            admin: true,
          },
          {
            title: "Staff Support topics",
            root: true,
            icon: "Communication/Call.svg",
            page: "/config/telehelp-topics",
            admin: true,
          },
          {
            title: "Tags",
            page: "/config/tags",
            admin: true,
          },
          {
            title: "Subfactors",
            page: "/config/sub-factors",
            admin: true,
          },
          {
            title: "Permissions",
            page: "/config/permissions",
            admin: true,
          },
        ],
        staff_member_admin: true,
      },
    ],
  },
};
