import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {withRouter} from "react-router-dom";
import {ProgressBar} from "react-bootstrap";

import { setLoader } from "../../../redux/ducks/load.duck";
// TODO: Should be rewrited to pure function
class AnimateLoading extends React.Component {
  animateTimeout;
  stopAnimateTimeout;
  state = {
    width: 0,
    routeChanged: false
  };

  componentDidUpdate(nextProps) {
    const { location, load } = this.props
    if ((location.pathname !== nextProps.location.pathname) || load) {
      this.animate();
      this.scrollToTop();
    }
  }

  scrollToTop() {
    const scrollToTopBtn = document.getElementById("kt_scrolltop");
    if (scrollToTopBtn) {
      scrollToTopBtn.click();
    }
  }

  animate() {
    this.animateTimeout = setTimeout(() => {
      if (this.state.width <= 100) {
        this.setState({ width: this.state.width + 5 });
        this.animate();
      } else {
        this.stopAnimate();
      }
    }, 30);
  }
  stopAnimate() {
    const { setLoader } = this.props
    clearTimeout(this.animateInterval);
    this.stopAnimateTimeout = setTimeout(() => {
      setLoader(false)
      this.setState({ width: 0 });
    }, 300);
  }
  componentWillUnmount() {
    if (this.stopAnimateTimeout) {
      clearTimeout(this.stopAnimateTimeout);
    }
    if (this.animateTimeout) {
      clearTimeout(this.animateTimeout);
    }
  }
  render() {
    return (
      <div
        className="header-progress-bar"
        style={{ height: "3px", width: "100%" }}
      >
        {this.state.width > 0 && (
          <ProgressBar variant="success" now={this.state.width} style={{ height: "3px" }}  />
        )}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return ({
    load: store.load.loader
  })
}

const mapDispatchToProps = dispatch => ({
  setLoader: bindActionCreators(setLoader, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnimateLoading));
