import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";

import { ContentRoute } from "../../../_metronic/layout";

import ContentAuthors from "./ContentAuthors/ContentAuthors";
import Conference from "./Conference/Conference";
import Feelings from "./Feelings/Feelings";
import Sports from "./Sports/Sports";
import Staff from "./Staff/Staff";
import Goals from "./Goals/Goals";
import Tags from "./Tags/Tags";
import FocusAreas from "./FocusAreas/FocusAreas";
import Submoods from "./Submoods";
import TelehelpTopics from "./TelehelpTopics";
import Subfactor from "./SubFactors/SubFactors";
import Permission from "./Permission/Permission";

class ConfigWrapper extends Component {
  render() {
    const { user } = this.props;
    // only show conference and Goals when user is admin

    return (
      <Switch>
        <Redirect exact={true} from="/config" to="/config/staff_types" />
        {/* Surfaces */}
        <ContentRoute from="/config/staff_types" component={Staff} />
        <ContentRoute from="/config/emotions" component={Feelings} />
        <ContentRoute from="/config/sports" component={Sports} />
        <ContentRoute
          from="/config/content_authors"
          component={ContentAuthors}
        />
        {user.is_admin && (
          <ContentRoute from="/config/conference" component={Conference} />
        )}
        {user.is_admin && (
          <ContentRoute from="/config/goal_types" component={Goals} />
        )}
        {user.is_admin && (
          <ContentRoute from="/config/focus_areas" component={FocusAreas} />
        )}
        {user.is_admin && (
          <ContentRoute path="/config/submoods" component={Submoods} />
        )}
        {user.is_admin && (
          <ContentRoute
            path="/config/telehelp-topics"
            component={TelehelpTopics}
          />
        )}
        {user.is_admin && <ContentRoute from="/config/tags" component={Tags} />}

        {user.is_admin && <ContentRoute from="/config/sub-factors" component={Subfactor} />}
        {user.is_admin && <ContentRoute from="/config/permissions" component={Permission} />}
      </Switch>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.auth.user,
});

export default connect(mapStateToProps, null)(ConfigWrapper);
