import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

import { ContentRoute } from "../../../_metronic/layout";

import Admin from './Admin/Admin'
import Student from './Student/Student'
import Staff from './Staff/Staff'
import Alumni from './Alumni/Alumni'

class UserWrapper extends Component {

  render() {
    const { user } = this.props
    // not show admin and alumni options if user is not admin
    return (
      <Switch>
        <Redirect
          exact={true}
          from="/users"
          to="/users/student"
        />
        {/* Surfaces */}
        <ContentRoute from="/users/student" component={Student} />
        <ContentRoute from="/users/staff" component={Staff} />
        {user.is_admin && <ContentRoute from="/users/admin" component={Admin} />}
        <ContentRoute from="/users/alumni" component={Alumni} />
      </Switch>
    )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user,
})

export default connect(mapStateToProps, null)(UserWrapper)