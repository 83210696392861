import React, { useState, useEffect } from 'react'
import { Paper, Tabs, Tab, MuiThemeProvider, Switch, Button, useMediaQuery } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import MUIDataTable from 'mui-datatables'
import { connect } from 'react-redux'
import moment from "moment"

import { tableMuiTheme } from '../../table/table_styles'
import GoalsDialog from './GoalsDialog'

import { getGoalTypes, defaultTypeGoal, getGoalType, updateGoalType } from "../../../crud/api/goal_types.crud"
import * as goalTypesDUCK from "../../../../redux/ducks/goal_types.duck"
import { setLoader } from '../../../../redux/ducks/load.duck'
import { Add } from '@material-ui/icons'

const Goals = ({ goal_types, goalsActions, settingLoader }) => {
  const isDesktop = useMediaQuery("(min-width: 992px)")
  const isTablet = useMediaQuery("(min-width: 768px)")
  const [tabValue, setTabValue] = useState("mind")
  const [dialog, setDialog] = useState("close")

  useEffect(() => {
    if (tabValue) getGoalTypes(goalsActions.setGoalTypes, tabValue)
  }, [goalsActions.setGoalTypes, tabValue])

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'name',
      label: 'Goal',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'created_at',
      label: 'Register Date',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <>
            {moment(value).format("MMM DD YYYY")}
          </>
        ) 
      }
    },
    {
      name: 'active',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, metaData, updateValue) => {
          const update = async check => {
            const goal = {
              ...metaData.tableData[metaData.rowIndex],
              student_zone_id: metaData.tableData[metaData.rowIndex].student_zone.id,
              active: check
            }
            settingLoader(true)

            try {
              await updateGoalType(goalsActions.updateGoalTypes, goal, goal.id)
            } catch (error) {
            }
          }
          
          return (
          <Switch
            color="secondary"
            name="status"
            checked={value}
            onChange={e => update(e.target.checked)}
          />
        )}
      }
    },
  ]

  const options = {
    serverSide: false,
    rowsPerPageOptions: [],
    rowsPerPage: 15,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "670px" : isTablet ? "640px" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "goals.csv",
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
    },
    onRowClick: (data, meta, col) => {
      const { target } = col
      if (target.name !== "status") openDialog('editing', data[0])
    },
  };

  const openDialog = (mode, id = null) => {
    switch(mode){
        case "create":
          settingLoader(true)
          goalsActions.setGoalType(defaultTypeGoal)
          setDialog(mode)
          break;
        case "editing":
          settingLoader(true)
          getGoalType(goalsActions.setGoalType, id)
          .then(() => setDialog(mode))
          break;
        case "close":
          setDialog(mode)
          setTimeout(() => {
            goalsActions.setGoalType(defaultTypeGoal)
          }, 500);
          break;
        default:
          console.error("Invalid dialog mode")
    }        
  }

  return (
    <div className="table-container">
      <Paper style={{marginBottom: 10}}>
        <Tabs
          value={tabValue}
          onChange={(e, value) => {settingLoader(true); setTabValue(value)}}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          centered
        >
          <Tab id="tab_goal_mind_selector" value="mind" label="Mind"/>
          <Tab id="tab_goal_body_selector" value="body" label="Body"/>
          <Tab id="tab_goal_life_selector" value="life" label="Life"/>
        </Tabs>
      </Paper> 
      <div className="text-right pb-4 px-5">
        <Button id="create_goal_btn" variant="contained" color="secondary" onClick={() => openDialog("create")}><Add /> New goal</Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={tableMuiTheme()}>
          <MUIDataTable
            // title="Goals"
            columns={columns}
            data={goal_types.data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>

      <GoalsDialog mode={dialog} setDialog={openDialog} zone={tabValue} />
    </div>
  )
}

const mapStateToProps = store => ({
  goal_types: store.goal_t.goal_types,
})

const mapDispatchToProps = (dispatch) => ({
  goalsActions: bindActionCreators(goalTypesDUCK.actions, dispatch),
  settingLoader: bindActionCreators(setLoader, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Goals)
