import { STAFF_MEMBERS_URL, SCHOOL_URL, STAFF_MEMBERS_URL_V2, BACKGROUND_OPERATIONS_V2 } from "../helpers/routes"
import { getMultipartParams, handleResponse, handleFormData, getParams } from "../helpers/validate"
import notice from "../../utils/notice"

export const defaultStaffMember = {
  status: true,
  first_name: "",
  last_name: "",
  email: "",
  staff_type: null,
  password: "",
  student_zone_ids: [],
  phone_number: "",
  organizationAdmin: false,
}

export const getStaffMembers = (setterFunction, slug, page = 1, query = "") => {
  if (slug) {
    return fetch(`${SCHOOL_URL}/${slug}/staff_members?page=${page}&search_query=${query}`, getMultipartParams('GET'))
      .then(res => handleResponse(res))
      .then(json => setterFunction(json))
      .catch(e => {
        notice("Error on loading staff members")
        console.error(e.errors)
        throw e
      })
  }
}

export const getStaffMember = (setterFunction, id) => {
  return fetch(`${STAFF_MEMBERS_URL}/show?id=${id}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
      notice("Error on fetching staff member")
      console.error(e.errors)
      throw e
    })
}

export const saveStaffMembers = (setterFunction, data,type_creation) => {
  const formData = new FormData();

  for (const name in data) {
    formData.append(name, data[name]);
  }

  return fetch(STAFF_MEMBERS_URL+"?type_creation=" + type_creation, getMultipartParams('POST', formData))
    .then(res => handleResponse(res))
    .then(json => {
      notice("Staff member created", "success")
      if(json !=null)
      json.id && setterFunction(json)
    })
    .catch(e => {
      // notice("Error saving the staff")
      // console.error(e.errors)
      throw e
    })
}

export const updateStaffMembers = (setterFunction, data, id) => {
  const formData = new FormData();

  for (const name in data) {
    formData.append(name, data[name]);
  }

  return fetch(`${STAFF_MEMBERS_URL}/update?id=${id}`, getMultipartParams('PUT', formData))
    .then(res => handleResponse(res))
    .then(json => {
      notice("Staff member updated", "success")
      json.id && setterFunction(json)
    })
    .catch(e => {
      notice("Error saving the staff")
      console.error(e.errors)
      throw e
    })
}

export const deleteStaffMember = (setterFunction, id) => {
  return fetch(`${STAFF_MEMBERS_URL}/delete?id=${id}`, getMultipartParams('DELETE'))
    .then(res => handleResponse(res))
    .then(json => {
      notice("Staff member deleted", "success")
      setterFunction(json)
    })
    .catch(e => {
      notice("Error deleting staff member")
      console.error(e.errors)
      throw e
    })
}

export const searchStaffMembers = (setterFunction, slug, query) => {
  if (slug) {
    return fetch(`${SCHOOL_URL}/${slug}/staff_members/suggestions?q=${query}`, getMultipartParams('GET'))
      .then(res => handleResponse(res))
      .then(json => setterFunction(json))
      .catch(e => {
        notice("Error on loading staff members")
        console.error(e.errors)
      })
  }
}




export const staffBulk = (slug, UrlFile) => {
  return fetch(`${STAFF_MEMBERS_URL_V2}/${slug}/bulk_staff_creation`, getMultipartParams('POST', handleFormData({ key: UrlFile })))
    .then(handleResponse)
    .then(res => res)
    .catch(e => {

      console.log(e.errors)
      throw e
    })
}

export const validateStaffBulkFetch = (slug, url_file) => {
  const jsonData = JSON.stringify({ key: url_file })
  return fetch(`${STAFF_MEMBERS_URL_V2}/${slug}/validate_bulk_csv_file`, getParams('POST', jsonData))// fetch(`${SCHOOL_URL}/${slug}/validate_bulk_csv_file`, getParams('POST', {csv_url:url_file}))
    .then(handleResponse)
    .then(res => { return res })
    .catch(e => {


      throw e
    })
}

export const checkStateBulk = (id_background) => {

  return fetch(`${BACKGROUND_OPERATIONS_V2}/${id_background}`, getParams('GET'))// fetch(`${SCHOOL_URL}/${slug}/validate_bulk_csv_file`, getParams('POST', {csv_url:url_file}))
    .then(handleResponse)
    .then(res => res)
    .catch(e => {
      throw e
    })
}

export const generatePresignedUrl = (key) => {
  const jsonData = JSON.stringify({ key: key })
  return fetch(`${STAFF_MEMBERS_URL_V2}/presigned_url`, getParams('POST', jsonData))// fetch(`${SCHOOL_URL}/presigned_url`, getParams('GET'))
    .then(handleResponse)
    .then(res => { return res; })
    .catch(e => {

      throw e
    })
}