import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#16192B",
    transform: "rotateX(60deg)",
    borderTopLeftRadius: "2000px 8000px",
    borderTopRightRadius: "2000px 8000px",
    flex: 1,
    height: 200,
  },
}));

const RotatedBackground = () => {
  const classes = useStyles();
  return <div className={classes.container}></div>;
};

export default RotatedBackground;
