import React from "react";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core";
import { actionTableMuiTheme } from "../pages/table/table_styles";
import CustomTableFooter from "./CustomTableFooter";
import { useBreakpoints } from "../hooks/useBreakpoint";

const CustomTable = ({
  title = "",
  columns = [],
  data = [],
  options = {},
  count = 0,
  page = 1,
  rowsPerPage = 15,
  setPage,
}) => {
  const customOptions = {
    serverSide: true,
    count,
    page,
    rowsPerPage,
    rowsPerPageOptions: [],
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    rowHover: false,
    selectableRows: "none",
    tableBodyMaxHeight: useBreakpoints ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data",
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    customFooter: !setPage
      ? false
      : () => {
          return (
            <CustomTableFooter
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={setPage}
            />
          );
        },
  };
  return (
    <MuiThemeProvider theme={actionTableMuiTheme()}>
      <MUIDataTable
        title=""
        columns={columns}
        data={data}
        options={{ ...customOptions, ...options }}
      />
    </MuiThemeProvider>
  );
};

export default CustomTable;
