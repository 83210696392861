/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { AnimateLoading } from "../../../_partials/controls";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#040719",
    borderTop: "none",
  },
  school: {
    color: "#E8EDF6",
  },
}));

export function SubHeader() {
  const classes = useStyles();
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const school = useSelector((state) => state.school.selected_school);

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    subheader.setBreadcrumbs(aside.breadcrumbs || header.breadcrumbs);
    subheader.setTitle(aside.title || header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  return (
    <div
      id="kt_subheader"
      style={{ background: "#eceef7" }}
      className={`subheader pt-0 pb-2 pb-lg-4 pt-lg-0   ${layoutProps.subheaderCssClasses} `}
    >
      <AnimateLoading />
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <div className={`d-flex align-items-center mx-5 ${classes.school}`}>
            {school && school !== null && school.mascot_image_url && (
              <img
                width="40"
                height="40"
                src={school.mascot_image_url}
                alt="mascot_image"
              />
            )}
            <h5
              className="font-weight-bold my-2 mr-5 ml-5"
              style={{ color: "#3F4254" }}
            >
              {school && school !== null && school.name && `${school.name} -`}{" "}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {subheader.title.includes("Hub") || subheader.title == ""
                  ? school != null
                    ? "Share Resources"
                    : ""
                  : subheader.title}
              </span>
            </h5>
          </div>
        </div>

        {/* Toolbar */}
        {layoutProps.viewUserDisplay && <QuickUserToggler />}
      </div>
    </div>
  );
}
