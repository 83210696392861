import React, { useState } from "react";
import { connect } from "react-redux";
import { setShow } from "../../redux/ducks/support_card.duck";

const SupportCard = ({ show, setShow }) => {
  const [fullscreen, setFullscreen] = useState(false);

  return (
    <>
      {/* <button onClick={() => setShow(!show)}>
      {show ? "Hide" : "Show"} Component
    </button> */}
      {show && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            position: "absolute",
            zIndex: "99999",
            bottom: 10,
            right: 10,
            width: fullscreen
              ? window.innerWidth - 20
              : window.innerWidth / 1.25,
            height: fullscreen ? window.innerHeight - 20 : "550px",
          }}
        >
          <div
            style={{
              height: "auto",
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              marginLeft: "auto",
              padding: "10px",
            }}
          >
            {fullscreen ? (
              <i
                class="fas fa-compress-alt"
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={() => setFullscreen(!fullscreen)}
              ></i>
            ) : (
              <i
                class="fas fa-expand-alt"
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={() => setFullscreen(!fullscreen)}
              ></i>
            )}
            <i
              class="fas fa-times"
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={() => setShow(!show)}
            ></i>
          </div>
          <iframe
            src="https://v2-embednotion.com/331804db6a704506960e196e4c3c81bb"
            style={{ width: "100%", height: "95%", border: 0, padding: 0 }}
          ></iframe>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  show: state.showReducer.show,
});

const mapDispatchToProps = {
  setShow,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportCard);
