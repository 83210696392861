import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

const SimpleSelect = ({
  name,
  label,
  value,
  onChange,
  disabled,
  options,
  error,
  helperText,
}) => {
  return (
    <FormControl fullWidth variant="outlined" disabled={disabled} error={error}>
      <InputLabel>{label}</InputLabel>
      <Select
        fullWidth
        displayEmpty
        name={name}
        value={value}
        label={label}
        variant="outlined"
        disabled={disabled}
        onChange={onChange}
      >
        <MenuItem value={""}>Select an option</MenuItem>
        {options.map(({ value, text }) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SimpleSelect;
