import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultGoal } from "../../app/crud/api/goals.crud";

export const actionType = {
  GetGoals: "[GOAL] GETGOALS",
  GetGoal: "[GOAL] GETGOAL",
  UpdateGoal: "[GOAL] UPDATEGOAL",
  LoadGoalCsv: "[GOAL] LOADGOALCSV",
  SelectGoal: "[GOAL] SELECTGOAL",
  GetGoalAnalytics: "[GOAL] GETGOALANALYTICS",
};

const initialState = {
  goals: { data: [], isFetched: false },
  goal: defaultGoal,
  selected_goal: null,
  goal_analytics: {
    mind: {
      goals: [],
      data: [],
    },
    body: {
      goals: [],
      data: [],
    },
    life: {
      goals: [],
      data: [],
    },
  },
};

export const reducer = persistReducer(
  { storage, key: "goal", withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetGoals: {
        return {
          ...state,
          goals: {
            data: action.payload.items,
            count: action.payload.total,
            page: action.payload.page,
            pages: action.payload.pages,
            rowsPerPage: action.payload.per_page,
            isFetched: true,
          },
        };
      }

      case actionType.UpdateGoal: {
        let newData = state.goals.data;
        const index = state.goals.data.findIndex(
          (x) => x.id === action.payload.id
        );

        if (index === -1) {
          newData = [action.payload, ...state.goals.data];
        } else {
          newData = state.goals.data;
          newData[index] = action.payload;
        }

        return { ...state, goals: { ...state.goals, data: newData } };
      }

      case actionType.GetGoal: {
        return { ...state, goal: action.payload };
      }

      case actionType.LoadGoalCsv: {
        let newData = action.payload;

        return {
          ...state,
          goals: { ...state.goals, data: [...state.goals.data, ...newData] },
        };
      }

      case actionType.SelectGoal: {
        return { ...state, selected_goal: action.payload };
      }

      case actionType.GetGoalAnalytics: {
        return {
          ...state,
          goal_analytics: { ...action.payload, isFetched: true },
        };
      }

      default:
        return state;
    }
  }
);
export const actions = {
  setGoals: (data) => ({ type: actionType.GetGoals, payload: data }),
  setGoal: (data) => ({ type: actionType.GetGoal, payload: data }),
  updateGoals: (data) => ({ type: actionType.UpdateGoal, payload: data }),
  loadGoalCsv: (data) => ({ type: actionType.LoadGoalCsv, payload: data }),
  setSelectedGoal: (state) => ({ type: actionType.SelectGoal, payload: state }),
  setGoalAnalytics: (state) => ({
    type: actionType.GetGoalAnalytics,
    payload: state,
  }),
};
