/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {  Alert } from '@material-ui/lab'
import notice from "../../../utils/notice";
import {
  MuiThemeProvider,
  Button,
  useMediaQuery,
  Tooltip,
  CircularProgress,
  makeStyles
} from "@material-ui/core";
import { Add, Delete, Visibility, Publish, } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import { actionTableMuiTheme } from "../../table/table_styles";
import StaffDialog from "./StaffDialog";
import PasswordReset from "../PasswordReset";
import FilterDataTable from "../../../utils/FilterDataTable";

import { setLoader } from "../../../../redux/ducks/load.duck";
import * as staffMDUCK from "../../../../redux/ducks/staff_members.duck";
import { BulkModal } from "../../../utils/BulkModal";
import {
  defaultStaffMember,
  getStaffMembers,
  getStaffMember,
  deleteStaffMember,
  checkStateBulk,
  validateStaffBulkFetch,
  generatePresignedUrl,
  staffBulk
} from "../../../crud/api/staff_members.crud";
import { setAlert } from "../../../../redux/ducks/alert.duck";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CustomTableFooter from "../../../utils/CustomTableFooter";
const useStyles = makeStyles(theme => ({
  paper: {
    width: ({ isDesktop }) => !isDesktop ? '70%' : '50%',
    [theme.breakpoints.up('sm')]: {
      width: '40%'
    },
    maxWidth: 500,
  },
  alertLeft:{
    display: 'inline-flex !important',
    right: 4 
  },
  progress: {
    marginLeft: 10,
    
  }
}));
const Staff = ({
  settingLoader,
  staffMActions,
  staffMembers,
  school,
  setAlert,
  staff_member_admin,
  is_admin,
}) => {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const isTablet = useMediaQuery("(min-width: 768px)");
  const [dialog, setDialog] = useState("close");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [bulk, setBulk] = useState(null);
  const [stepsBulk, setStepsBulk] = useState(1);
  const [bulkModal, setBulkModal] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [urlFile, setUrlFile] = useState("");
  const [errorsBulk, setErrorsBulk] = useState([]);
  const classes = useStyles({ isDesktop });
  useEffect(() => {
    if (school && school !== null && school.slug && school.slug !== null) {
      if (query && query.length > 1)
        getStaffMembers(staffMActions.setStaffMembers, school.slug, 1, query);
      else getStaffMembers(staffMActions.setStaffMembers, school.slug, 1);
    }
  }, [query, school, staffMActions.setStaffMembers]);

  const deleteStaff = (id) => {
    setAlert({
      title: "Delete Staff Member",
      message: "Are you sure to permanently delete this Staff Member?",
      btn_msg: "Delete Staff Member",
      action: () => deleteStaffMember(staffMActions.deleteStaffMembers, id),
    });
  };

  const columns = [
    {
      name: "user",
      label: "Picture",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value &&
          value.avatar_thumb && (
            <div
              style={{
                background: `url(${value.avatar_thumb}) center center / cover no-repeat`,
                height: 40,
                width: 40,
                borderRadius: "50%",
                margin: "-5px 0",
              }}
            />
          ),
      },
    },
    {
      name: "user",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value && (
            <>
              {value.first_name} {value.last_name}
            </>
          ),
      },
    },
    {
      name: "user",
      label: "Email",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => value && <>{value.email}</>,
      },
    },
    // {
    //   name: 'staff_type',
    //   label: 'Staff type',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value) => (
    //       value && <>{value.name}</>
    //     )
    //   }
    // },
    {
      name: "created_at",
      label: "Date Registered",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value && <>{moment(value).format("MMM DD YYYY")}</>,
      },
    },
    {
      name: "job_title",
      label: "Role",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (job_title) => job_title && <>{job_title}</>,
      },
    },
    {
      name: "display_in_telehelp",
      label: "Staff Support",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value && <NotificationsIcon className="w-100" />,
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) =>
          value && (
            <div className="d-flex">
              <Tooltip title="Edit Staff Member" placement="top">
                <a
                  id={`edit_staff_member_btn_${value}`}
                  className="btn btn-icon btn-sm btn-light btn-hover-primary "
                  onClick={() => openDialog("editing", value)}
                >
                  {staff_member_admin || is_admin ? (
                    <i className="fas fa-edit text-primary" />
                  ) : (
                    <Visibility />
                  )}
                </a>
              </Tooltip>
              {(staff_member_admin || is_admin) && (
                <Tooltip title="Delete Staff Member" placement="top">
                  <a
                    id={`delete_staff_member_btn_${value}`}
                    className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                    onClick={() => deleteStaff(value)}
                  >
                    <Delete />
                  </a>
                </Tooltip>
              )}
              {/* <Tooltip title="Edit Password" placement="top">
              <a id={`passw_change_staff_member_btn_${value}`} className="btn btn-icon btn-sm btn-light btn-hover-warning text-warning" onClick={() => {setEmail(meta.rowData[0].email)}}>
                <VpnKey />
              </a>
            </Tooltip> */}
            </div>
          ),
      },
    },
  ];

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: staffMembers.rowsPerPage,
    page: staffMembers.page,
    count: staffMembers.count,
    rowHover: false,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: "none",
    tableBodyMaxHeight: isDesktop ? "670px" : isTablet ? "640px" : "357px",
    textLabels: {
      body: {
        noMatch: "No data",
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "staff_members.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayRowsOnly: true,
      },
    },
    customFooter: (count, page, rowsPerPage) => {
      settingLoader(true);
      return (
        <CustomTableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={(mPage) =>
            getStaffMembers(
              staffMActions.setStaffMembers,
              school.slug,
              mPage,
              query
            )
          }
        />
      );
    },
  };

  const openDialog = (mode, id = null) => {
    switch (mode) {
      case "create":
        settingLoader(true);
        staffMActions.setStaffMember(defaultStaffMember);
        setDialog(mode);
        break;
      case "editing":
        settingLoader(true);
        getStaffMember(staffMActions.setStaffMember, id).then(() =>
          setDialog(mode)
        );
        break;
      case "close":
        setDialog(mode);
        setTimeout(() => staffMActions.setStaffMember(defaultStaffMember), 500);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  };

  const createStaffBulk = () => {
    setBulkModal(false)
    setBulkLoading(true);
    if (!school?.slug) {
      setBulkLoading(false);
      notice("Please select a school");
    } else if (!bulk) {
      setBulkLoading(false);
      notice("Please add a csv file");
    } else
      
    staffBulk(school.slug, urlFile)
      .then((response) => {

        const intervalIdentifierC = setInterval(() => { _checkStateBulk(response.id, 6, 6.1) }, 5000);
        localStorage.setItem('idInterval', intervalIdentifierC);

        setBulk(null);
        setStepsBulk(4);
        // setTimeout(
        //   () =>
        //     getStudents(studentActions.setStudents, school.slug, 1, query),
        //   2000
        // );
        setTimeout(
          () =>{
            setBulkLoading(true);
            setStepsBulk(5)},
          2000
        );
      });
  };
  const onFinish = () => {
    setStepsBulk(1)
    setBulkModal(false);
    //getStudents(studentActions.setStudents, school.slug, 1, query);
  }
  const _checkStateBulk = async (id_background, nextStep, stepError) => {
    const res = await checkStateBulk(id_background);
    if (res.state === "success") {
      clearInterval(localStorage.getItem('idInterval'));
      localStorage.removeItem('idInterval');
      setStepsBulk(nextStep);
      setBulkLoading(false);

    }

    if (res.state === "error") {
      setErrorsBulk(res.result.errors);
      clearInterval(localStorage.getItem('idInterval'));
      localStorage.removeItem('idInterval');
      setStepsBulk(stepError);
      setBulkLoading(false);

    }

  }
  const validateStudentBulk = async (values) => {

    setBulkLoading(true);
    if (!school?.slug) {
      setBulkLoading(false);
      notice("Please select a school");
    } else if (!bulk) {
      setBulkLoading(false);
      notice("Please add a csv file");
    } else {
      setStepsBulk(2);
      const result = await uPloadFiletoS3();
      if (result == false) {

        return;
      }
      setUrlFile(result);
      validateStaffBulkFetch(school.slug, result)
        .then((response) => {

          const intervalIdentifierC = setInterval(() => { _checkStateBulk(response.id, 3, 2.2) }, 5000);
          localStorage.setItem('idInterval', intervalIdentifierC);


        })
        .catch(() => { setErrorsBulk(["Error. internal error please contact administrator"]);setStepsBulk(2.2); setBulkLoading(false) });

    }
  };
  const uPloadFiletoS3 = async () => {

    const responseUploadUrl = await generatePresignedUrl(bulk.name).catch( error => {
      setErrorsBulk(["Error. internal error please contact administrator"]);
      setStepsBulk(2.2);
      setBulkLoading(false)});
      
    if(responseUploadUrl == undefined)
      return false;
    var res = await fetch(responseUploadUrl.uploadUrl, {
      method: 'PUT',
      body: bulk
    }).then(res => res)
      .catch(error => {
        setBulkLoading(false);
        setStepsBulk(2.1);

        return false
      })
      .then(response => response);

    if (res == undefined)
      return false;

    return responseUploadUrl.key
  }
  return (
    <>
      <div className="table-container">
        <div className="pb-4 d-flex justify-content-between align-items-end">
          <FilterDataTable query={query} setQuery={setQuery} />

          {(staff_member_admin || is_admin) && (
            <div>
              {stepsBulk === 5 &&
                <div style={{
                  display: 'inline-flex !important',
                  padding: '8px 15px',
                  fontSize: '0.875rem',
                  fontFamily: 'Poppins',
                  padding: '2px 5px',
                  fontWeight: 400,
                  lineHeight: 1.43,
                  borderRadius: 4,

                }}
                  class="MuiPaper-root MuiAlert-standardInfo  MuiButtonBase-root  MuiPaper-elevation0" role="alert">
                  <div class="MuiAlert-icon"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z"></path></svg></div><div class="MuiAlert-message">
                    Creating staffs in bulk {bulkLoading && stepsBulk === 5 && <CircularProgress color="inherit" size={18} className={classes.progress} />}
                  </div>
                </div>
              }
              {
                stepsBulk >= 6 &&
                
                  <div onClick={onFinish} style={{
                    display: 'inline-flex !important',
                    padding: '8px 15px',
                    fontSize: '0.875rem',
                    fontFamily: 'Poppins',
                    padding: '2px 5px',
                    fontWeight: 400,
                    lineHeight: 1.43,
                    borderRadius: 4,

                  }}
                    class="MuiPaper-root MuiAlert-standardSuccess  MuiButtonBase-root  MuiPaper-elevation0" role="alert">
                    <div class="MuiAlert-icon">
                      <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"></path></svg>
                    </div>
                    <div class="MuiAlert-message">
                      Job finished. Click here to refresh
                    </div>
                  </div>

                
              }
              <Button
                id="create_bulk_staff_btn"
                className="mr-3"
                variant="contained"
                color="primary"
                onClick={() => setBulkModal(true)}
                startIcon={<Publish />}
              >
                Bulk Upload
              </Button>

              <Alert  classes={classes.alertLeft}  style={{    display: 'none'}} className=" MuiButtonBase-root " severity=""> </Alert>


              <Button
                id="create_staff_member_btn"
                variant="contained"
                color="secondary"
                onClick={() => openDialog("create")}
                startIcon={<Add />}
              >
                New Staff Member
              </Button>
            </div>
          )}

        </div>
        <MuiThemeProvider theme={actionTableMuiTheme()}>
          <MUIDataTable
            // title="Admin"
            columns={columns}
            data={staffMembers.data}
            options={options}
          />
        </MuiThemeProvider>
      </div>
      <StaffDialog mode={dialog} setDialog={openDialog} />
      <PasswordReset email={email} setEmail={setEmail} />
      {bulkModal &&
        <BulkModal
          urlDownloadTemplate={"https://cf-templates-vd9ryh0valak-us-east-1.s3.amazonaws.com/bulk_staff_members_template.csv"}
          errorsBulk={errorsBulk}
          setSteps={setStepsBulk}
          open={bulkModal}
          validateBulk={validateStudentBulk}
          setOpen={setBulkModal}
          bulk={bulk}
          steps={stepsBulk}
          setBulk={setBulk}

          school={school}
          loading={bulkLoading}
          bulkRequest={createStaffBulk}
          setLoading={setBulkLoading}
        />
      }
    </>
  );
};

const mapStateToProps = (store) => ({
  staffMembers: store.staffM.staffMembers,
  school: store.school.selected_school,
  is_admin: store.auth.user?.is_admin,
  staff_member_admin: store.auth.user?.staff_member_admin,
});

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  staffMActions: bindActionCreators(staffMDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
