import { CONTRIBUTING_FACTORS, SUBFACTORS_URL } from "../helpers/routes";
import {
  handleResponse,
  getParams,
  getMultipartParams,
} from "../helpers/validate";
import notice from "../../utils/notice";

export const defaultSubfactor = {
  name: "",
  description: "",
  active: true,
  parent_factor: ""
};

export const getParentFactor= (
  setterFunction,
  page = 1,
  query = ""
) => {
  return fetch(
    `${CONTRIBUTING_FACTORS}?page=${page}&search_query=${query}`,
    getMultipartParams("GET")
  )
    .then((res) => handleResponse(res))
    .then((json) => { 
      const data=json.map(({ id, name }) => ({ text: name, value: id }));
      setterFunction(data);
  
  } )
    .catch((e) => {
      notice("Error on loading factors");
      console.error(e.errors);
      throw e;
    });
};

export const getSubfactors = (
  setterFunction,
  page = 1,
  query = ""
) => {
  return fetch(
    `${SUBFACTORS_URL}?page=${page}&search_query=${query}`,
    getMultipartParams("GET")
  )
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => {
      notice("Error on loading subfactors");
      console.error(e.errors);
      throw e;
    });
};

export const getSubfactor = (setterFunction, id) => {
  return fetch(`${SUBFACTORS_URL}/show/${id}`, getMultipartParams("GET"))
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => {
      notice("Error on loading subfactor");
      console.error(e.errors);
      throw e;
    });
};

export const saveSubfactor = (setterFunction, data) => {
  const jsonData = JSON.stringify(data);
  return fetch(SUBFACTORS_URL, getParams("POST", jsonData))
    .then((res) => handleResponse(res))
    .then((json) => {
      notice("Subfactor created", "success");
      json.name_parent_factor= data.name_parent_factor
      setterFunction(json);
    })
    .catch((e) => {
      notice("Error saving the Subfactor");
      console.error(e.errors);
      throw e;
    });
};

export const updateSubfactor = (setterFunction, data, id) => {
  const jsonData = JSON.stringify(data);
  return fetch(`${SUBFACTORS_URL}/${id}`, getParams("PUT", jsonData))
    .then((res) => handleResponse(res))
    .then((json) => {
      
      notice("Subfactor updated", "success");
      json.name_parent_factor= data.name_parent_factor
      return setterFunction(json);
    })
    .catch((e) => {
      console.error(e);
      notice("Error saving the Subfactor");
      console.error(e.errors);
      throw e;
    });
};

