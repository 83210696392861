/* eslint-disable no-throw-literal */
import notice from "../../utils/notice"
import { CONTENT_AUTHORS_URL } from "../helpers/routes"
import { getMultipartParams, handleResponse } from "../helpers/validate"

export const defaultContentAuthor = {
  name: "",
  logo: "",
  display_image: "",
}

export const getContentAuthors = (setterFunction, page = 1, query = "") =>  {
  return fetch(`${CONTENT_AUTHORS_URL}?page=${page}&search_query=${query}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error on loading content authors")
    console.error(e.errors)
    throw e
  })
}

export const getContentAuthor = (setterFunction, id) =>  {
  return fetch(`${CONTENT_AUTHORS_URL}/show?id=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error on loading content authors")
    console.error(e.errors)
  })
}

export const saveContentAuthor = (setterFunction, data) => {
  const formData  = new FormData();

  for(const name in data) {
    formData.append(name, data[name]);
  }
  
  return fetch(CONTENT_AUTHORS_URL, getMultipartParams('POST', formData))
  .then(res => handleResponse(res))
  .then(json => {
    if (json && json.id) {
      notice("Content author created", "success")
      return setterFunction(json)
    } else {
      notice("Error saving the content author")
      throw {error: json}
    }
  })
  .catch(e => {
    notice("Error saving the content author")
    console.error(e.errors)
    throw e
  })
}

export const updateContentAuthor = (setterFunction, data, id) => {
  const formData  = new FormData();

  for(const name in data) {
    formData.append(name, data[name]);
  }
  
  return fetch(`${CONTENT_AUTHORS_URL}/update?id=${id}`, getMultipartParams('PUT', formData))
  .then(res => handleResponse(res))
  .then(json => {
    if (json && json.id) {
      notice("Content author updated", "success")
      return setterFunction(json)
    } else {
      notice("Error saving the content author")
      throw {error: json}
    }
  })
  .catch(e => {
    notice("Error saving the content author")
    console.error(e.errors)
    throw e
  })
}