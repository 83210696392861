import React from 'react'
import { TextField } from '@material-ui/core'

export const StudentTabBody = ({values, handleChange, handleBlur}) => {
  return (
    <div style={{padding: "2vh 2vw"}}>
      <h6>Sebastian - Coach June 23rd, 2020 09:12 AM</h6>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
      <h6>Sebastian - Coach June 23rd, 2020 09:12 AM</h6>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
      <h6>Sebastian - Coach June 23rd, 2020 09:12 AM</h6>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
      <h6>Sebastian - Coach June 23rd, 2020 09:12 AM</h6>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
      <TextField
        multiline
        rowsMax="4"
        placeholder="Add a note"
        variant="outlined"
        name="body"
        margin="normal"
        value={values.body}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <span className="font-weight-light">Note:  In compliance with the HIPAA Privacy and Security rule, please do not submit any Protect Health Information (PHI) on The Zone. Read more at Hyperlink </span>
      <a href="https://www.hhs.gov/hipaa/for-professionals/index.html" className="text-decoration-none">hhs.gov/hipaa/for-professionals/index.html</a>
    </div>
  )
}
