import React, { useState } from "react";
import Dropdown from "./Dropdown";
import * as dashboardDUCK from "../../../../redux/ducks/dashboard.duck";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Drawer, makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  drawer: {
    "&>.MuiDrawer-paper": {
      backgroundColor: "#16192B",
      minHeight: "60%",
      borderRadius: 7,
    },
  },
  title: {
    fontSize: 17,
    textAlign: "center",
    margin: 5,
    color: "#FCFFFF",
  },
  text: {
    fontSize: 17,
    color: "#F9FEFF",
  },
  text2: {
    fontSize: 17,
    color: "#536880",
    textAlign: "end",
    cursor: "pointer",
  },
  singleOption: {
    fontSize: 17,
    color: "#F9FEFF",
    textAlign: "center",
    cursor: "pointer",
  },
}));

const MonthFilter = ({
  open,
  onClose,
  classes,
  onClick,
  onKeyDown,
  months,
  onSelectedMonth,
}) => {
  return (
    <Drawer
      ModalProps={{
        className: classes.drawer,
      }}
      anchor="bottom"
      open={open}
      onClose={onClose}
    >
      <Box
        sx="bottom"
        role="presentation"
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <List style={{ paddingBottom: 10, paddingTop: 10 }}>
          {months.map(({ str_value }, index) => (
            <ListItem
              onClick={() => onSelectedMonth(months[index])}
              button
              key={str_value}
            >
              <ListItemText
                classes={{ primary: classes.singleOption }}
                primary={str_value}
              />
            </ListItem>
          ))}
        </List>
        <Divider />
      </Box>
    </Drawer>
  );
};

const DashboardFiltersDropdown = ({
  isXs,
  selected_month,
  months,
  dashboard_actions,
}) => {
  const classes = useStyles();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [optionOpen, setOptionOpen] = useState({
    month: false,
    year: false,
    season: false,
    class: false,
    gender: false,
  });

  const handleDrawer = () => {
    setOpenFilterDrawer(true);
  };

  const isXsProps = isXs
    ? {
        onOpen: handleDrawer,
        open: false,
      }
    : {};

  const toggleDrawer = () => {
    console.log("xD");
  };

  const handleFilterOption = (option) =>
    setOptionOpen({ ...optionOpen, [option]: true });

  const filterOptions = [
    { option: "month", label: "Month", value: selected_month?.str_value },
    { option: "year", label: "Year", value: "" },
    { option: "season", label: "Season", value: "" },
    { option: "class", label: "Class", value: "" },
    { option: "gender", label: "Gender", value: "" },
  ];

  const handleMonthFilter = (newMonth) => {
    dashboard_actions.setDashboardState({
      key: "selected_month",
      value: newMonth,
    });
  };
  return null;
  return (
    <>
      <Dropdown
        items={[
          { value: 0, text: "All Filters" },
          { value: 1, text: "Filter 1" },
          { value: 2, text: "Filter 2" },
        ]}
        value={0}
        IconComponent={FilterListIcon}
        {...isXsProps}
      />
      <Drawer
        ModalProps={{
          className: classes.drawer,
        }}
        anchor="bottom"
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer(false)}
      >
        <Box
          sx="bottom"
          role="presentation"
          onClick={toggleDrawer("bottom", false)}
          onKeyDown={toggleDrawer("bottom", false)}
        >
          <List>
            <ListItem button>
              <ListItemText
                classes={{ primary: classes.title }}
                primary="Filters"
              />
            </ListItem>
            {filterOptions.map(({ label, value, option }) => (
              <ListItem
                onClick={() => handleFilterOption(option)}
                key={label}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary={label}
                  className={classes.filterLabel}
                />
                <ListItemText
                  classes={{ primary: classes.text2 }}
                  primary={value}
                  className={classes.filterValue}
                />
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
        <MonthFilter
          open={optionOpen.month}
          onClose={() => setOptionOpen({ ...optionOpen, month: false })}
          classes={classes}
          months={months}
          onSelectedMonth={handleMonthFilter}
        />
      </Drawer>
    </>
  );
};

const mapStateToProps = (store) => ({
  months: store.dashboard.data.select_data.months,
  selected_month: store.dashboard.data.select_data.selected_month,
  queries: store.dashboard.queries,
});

const mapDispatchToProps = (dispatch) => ({
  dashboard_actions: bindActionCreators(dashboardDUCK.actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardFiltersDropdown);
