import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import { defaultTypeGoal } from '../../app/crud/api/goal_types.crud';

export const actionType = {
  GetGoalTypes: '[GOAL_TYPE] GET_GOAL_TYPES',
  GetGoaltype: '[GOAL_TYPE] GET_GOAL_TYPE',
  UpdateGoalType: '[GOAL_TYPE] UPDATE_GOAL_TYPE',
  LoadGoalTypeCsv: '[GOAL_TYPE] LOAD_GOAL_TYPE_CSV',
  SelectGoalType: '[GOAL_TYPE] SELECT_GOAL_TYPE'
}

const initialState = {
  goal_types: { data: [], isFetched: false },
  goal_type: defaultTypeGoal,
  selected_goal_type: "",
}

export const reducer = persistReducer(
  { storage, key: 'goal_t', withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetGoalTypes: {
        return { ...state, goal_types: { data: action.payload, isFetched: true } }
      }

      case actionType.UpdateGoalType: {
        let newData = state.goal_types.data
        const index = state.goal_types.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.goal_types.data]
        } else {
          newData = state.goal_types.data
          newData[index] = action.payload
        }

        return { ...state, goal_types: { ...state.goal_types, data: newData } }
      }
      
      case actionType.GetGoaltype: {
        
        return { ...state, goal_type: action.payload }
      }

      case actionType.LoadGoalTypeCsv: {
        let newData =  action.payload
        
        return { ...state, goal_types: { ...state.goal_types, data: [...state.goal_types.data, ...newData] } }
      }

      case actionType.SelectGoalType: {

        return { ...state, selected_goal_type: action.payload }
      }

      default: return state

    }
  }
)
export const actions = {
  setGoalTypes: data => ({ type: actionType.GetGoalTypes, payload: data }),
  setGoalType: data => ({ type: actionType.GetGoaltype, payload: data }),
  updateGoalTypes: data => ({ type: actionType.UpdateGoalType, payload: data }),
  loadGoalTypeCsv: data => ({ type: actionType.LoadGoalTypeCsv, payload: data }),
  setSelectedGoalType: state => ({ type: actionType.SelectGoalType, payload: state }),
}