import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultDailyPick } from "../../app/crud/api/daily_picks.crud";

export const actionType = {
  GetDailyPicks: "[DAILY_PICK] GET_DAILY_PICKS",
  GetDailyPick: "[DAILY_PICK] GET_DAILY_PICK",
  UpdateDailyPick: "[DAILY_PICK] UPDATE_DAILY_PICK",
  LoadDailyPickCsv: "[DAILY_PICK] LOAD_DAILY_PICK_CSV",
  SelectDailyPick: "[DAILY_PICK] SELECT_DAILY_PICK",
};

const initialState = {
  daily_picks: { data: [], isFetched: false },
  daily_pick: defaultDailyPick,
  selected_daily_pick: null,
};

export const reducer = persistReducer(
  { storage, key: "dp", withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetDailyPicks: {
        return {
          ...state,
          daily_picks: {
            data: action.payload.items,
            count: action.payload.total,
            page: action.payload.page,
            pages: action.payload.pages,
            rowsPerPage: action.payload.per_page,
            isFetched: true,
          },
        };
      }

      case actionType.UpdateDailyPick: {
        let newData = state.daily_picks.data;
        const index = state.daily_picks.data.findIndex(
          (x) => x.id === action.payload.id
        );

        if (index === -1) {
          newData = [action.payload, ...state.daily_picks.data];
        } else {
          newData = state.daily_picks.data;
          newData[index] = action.payload;
        }

        return {
          ...state,
          daily_picks: { ...state.daily_picks, data: newData },
        };
      }

      case actionType.GetDailyPick: {
        return { ...state, daily_pick: action.payload };
      }

      case actionType.LoadDailyPickCsv: {
        let newData = action.payload;

        return {
          ...state,
          daily_picks: {
            ...state.daily_picks,
            data: [...state.daily_picks.data, ...newData],
          },
        };
      }

      case actionType.SelectDailyPick: {
        return { ...state, selected_daily_pick: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setDailyPicks: (data) => ({ type: actionType.GetDailyPicks, payload: data }),
  setDailyPick: (data) => ({ type: actionType.GetDailyPick, payload: data }),
  updateDailyPicks: (data) => ({
    type: actionType.UpdateDailyPick,
    payload: data,
  }),
  loadDailyPickCsv: (data) => ({
    type: actionType.LoadDailyPickCsv,
    payload: data,
  }),
  setSelectedDailyPick: (state) => ({
    type: actionType.SelectDailyPick,
    payload: state,
  }),
};
