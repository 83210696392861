import { SCHOOL_URL, ALUMNI_URL } from "../helpers/routes"
import { getMultipartParams, getToken, handleResponse } from "../helpers/validate"
import notice from "../../utils/notice"
import Axios from "axios"

export const defaultAlumni = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  nickname: "",
  image: "",
  social_media: "",
  social_icon: "",
  class_year: "",
  sport: "",
}

export const getAlumnis = (setterFunction, slug, page = 1, query = "") => {
  return fetch(`${SCHOOL_URL}/${slug}/alumni?page=${page}&search_query=${query}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    console.error(e.errors)
  })
}

export const getAlumni = (setterFunction, id) => {
  return fetch(`${ALUMNI_URL}/show?id=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction({
    id: json.id,
    first_name: json.user.first_name,
    last_name: json.user.last_name,
    image: json.user.avatar_thumb,
    email: json.user.email,
    social_link: json.social_link,
    social_icon: json.social_icon,
    class_year: json.class_year,
    sport: json.sport,
  }))
  .catch(e => {
    notice("Error fetching alumni")
    console.error(e.errors)
    throw e
  })
}

export const saveAlumni = async (setterFunction, data) => {
  const formData = new FormData()

  for(const name in data) {
    formData.append(name, data[name]);
  }

  try {
    const json = await Axios.post(ALUMNI_URL, formData, {
      headers: {
        'Authorization': getToken()
      }
    })
    if (json.status === 200 && json.data && json.data.id){
      notice("Alumni created", "success")
      return setterFunction(json.data)
    } else notice("Error saving the alumni")
  } catch (e) {
    notice("Error saving the alumni")
    console.error(e)
    return e
  }
}

export const updateAlumni = async (setterFunction, id, data) => {
  const formData = new FormData()

  for(const name in data) {
    formData.append(name, data[name]);
  }

  try {
    const json = await Axios.put(`${ALUMNI_URL}/update?id=${id}`, formData, {
      headers: {
        'Authorization': getToken()
      }
    })
    if (json.status === 200 && json.data && json.data.id){
      notice("Alumni updated", "success")
      return setterFunction(json.data)
    } else notice("Error saving the alumni")
  } catch (e) {
    notice("Error saving the alumni")
    console.error(e)
    return e
  }
}

export const deleteAlumni = (setterFunction, id) => {
  return fetch(`${ALUMNI_URL}/delete?id=${id}`, getMultipartParams('DELETE'))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Alumni deleted", "success")
    setterFunction(json)
  })
  .catch(e => {
    notice("Error deleting alumni")
    console.error(e.errors)
    throw e
  })
}