import React from "react";
import { IconButton, Paper, TextField } from "@material-ui/core";
import { Clear, Search } from "@material-ui/icons";
import { useBreakpoints } from "../hooks/useBreakpoint";

const FilterDataTable = ({ query, setQuery, search }) => {
  const { isM, isL, isDesktop } = useBreakpoints();

  return (
    <Paper style={{ width: "100%", maxWidth: '400px' }}>
      <TextField
        fullWidth
        id="autocomplete_filter_input"
        size="small"
        variant="outlined"
        name="query"
        value={query}
        placeholder="Search..."
        autoFocus
        onChange={(e) => setQuery(e.target.value)}
        inputProps={{ autoComplete: "off" }}
        keyPress={(e) => e.keyCode == 13 ? search : null}
        InputProps={{
          endAdornment: (
            <>
              {query && (
                <IconButton
                  id="clear_automplete_input_btn"
                  size="small"
                  onClick={() => setQuery("")}
                >
                  <Clear />
                </IconButton>
              )}
              <Search />
            </>
          ),
        }}
      />
    </Paper>
  );
};

export default FilterDataTable;
