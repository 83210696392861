import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLocation } from "react-router";
import { checkIsActive } from '../../../../_helpers';
import { useSelector } from 'react-redux';

export const SubMenu = ({ submenu }) => {
  const { user } = useSelector(state => state.auth);
  const location = useLocation()

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
    <div className="menu-submenu">
      <ul className="menu-subnav">
        <ul className="menu-subnav">
          {submenu.map(op => 
            <li
              key={op.page}
              style={{ display: !user.is_admin && (op.title === "Admin" || op.title === "Conference" || op.title === "Goals") ? "none" : "flex" }}
              className={`menu-item ${getMenuItemActive(
                  op.page
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={op.page}>
                <i className="menu-bullet menu-bullet-dot">
                  <span/>
                </i>
                <span className="menu-text">{op.title}</span>
              </NavLink>
            </li>
          )}
        </ul>
      </ul>
    </div>
  )
}
