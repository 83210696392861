export const hour24tohour12 = (hour) => {
    
    if (hour == 0)
        return hour + 12 ;

    if (hour >= 1 && hour <= 11)
        return hour ;

    if (hour == 12)
        return hour;
    
    if (hour >= 13 && hour <= 23)
        return hour-12;

}

export const hour12tohour24 = (hour, type) => {
    
    
    if (type === "PM" && hour == 12)
        return parseInt(hour) ;

    if (type === "PM" && hour >= 1 && hour <= 11)
        return parseInt(hour) +12 ;

    if (type === "AM" && hour==12)
        return parseInt(hour) -12 ;
    
    if (type === "AM" && hour >= 1 && hour <= 11)
        return parseInt(hour)  ;



}


export const hour24tohour12GetPMAM = (hour) => {
    
    if (hour <= 11)
        return "AM" ;

    if (hour >= 12)
        return "PM"; 
}