import notice from "../../utils/notice";
import { SUBMOODS_URL } from "../helpers/routes";
import { getParams, handleResponse } from "../helpers/validate";

export const getListSubmoods = (page) => {
  return fetch(`${SUBMOODS_URL}?page=${page}`, getParams("GET"))
    .then(handleResponse)
    .catch((e) => {
      console.error(e.errors);
      notice("Error on loading submoods");
      throw e;
    });
};

export const getSingleSubmood = (id) => {
  return fetch(`${SUBMOODS_URL}/${id}`, getParams("GET"))
    .then(handleResponse)
    .catch((e) => {
      console.error(e.errors);
      notice("Error on loading submood");
      throw e;
    });
};

export const createSubmood = (data) => {
  return fetch(`${SUBMOODS_URL}`, getParams("POST", JSON.stringify(data)))
    .then(handleResponse)
    .catch((e) => {
      console.error(e.errors);
      notice("Error on creating submood");
      throw e;
    });
};

export const updateSubmood = (data) => {
  return fetch(
    `${SUBMOODS_URL}/${data.id}`,
    getParams("PUT", JSON.stringify(data))
  )
    .then(handleResponse)
    .catch((e) => {
      console.error(e.errors);
      notice("Error on updating submood");
      throw e;
    });
};

export const deleteSubmood = (id) => {
  return fetch(`${SUBMOODS_URL}/${id}`, getParams("DELETE"))
    .then(handleResponse)
    .catch((e) => {
      console.error(e.errors);
      notice("Error on deleting submood");
      throw e;
    });
};
