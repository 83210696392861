import React, { Suspense, useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import { getToken, onMessage } from "firebase/messaging";

import { messaging } from "../firebase";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { setFirebaseToken } from "./crud/api/users.crud";
import { actions } from "./modules/Auth/_redux/authRedux";
import notice from "./utils/notice";

import { BuilderPage } from "./pages/BuilderPage";
import Dashboard from "./pages/Dashboard";
import UserWrapper from "./pages/User/UserWrapper";
import SchoolWrapper from "./pages/School/SchoolWrapper";
import GoalsWrapper from "./pages/Goals/GoalsWrapper";
import ConfigWrapper from "./pages/Config/ConfigWrapper";
// import CalendarWrapper from "./pages/Calendar/CalendarWrapper";
import AnnouncementsWrapper from "./pages/Announcement/AnnouncementsWrapper";
// import DailyPicksWrapper from "./pages/DailyPicks/DailyPicksWrapper";
import ContentsWrapper from "./pages/Contens/ContentsWrapper";
import Teams from "./pages/Teams/Teams";
import PushNotifications from "./pages/PushNotifications";
import Polls from "./pages/Polls";
import Notifications from "./pages/Notifications";
import { appPendo } from "../pendo";
import ZoneIn from "./pages/ZoneIn";

const { REACT_APP_GA_MEASUREMENT, REACT_APP_FIREBASE_VAPID_KEY } = process.env;

ReactGA.initialize(REACT_APP_GA_MEASUREMENT);
ReactGA.send("pageview");

export default function BasePage() {
  const dispatch = useDispatch();
  const { user, authToken } = useSelector((state) => state.auth);
  //store.auth.user?.staff_member_admin

  ReactGA.gtag("set", "user_properties", {
    is_staff: !user.is_admin,
    //is_staff_member_admin: user?.staff_member_admin || false
  });

  const generateFirebaseToken = async () => {
    const token = await getToken(messaging, {
      vapidKey: REACT_APP_FIREBASE_VAPID_KEY,
    }).catch((e) => console.log("Error on retrive token", e));
    if (token) {
      setFirebaseToken(token).then((res) =>
        dispatch(actions.login({ authToken, user: user }))
      );
    } else console.log("There's no token");
  };

  useEffect(() => {
    if (!user?.firebase_token) generateFirebaseToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  onMessage(messaging, (msg) =>
    notice(
      [<h4>{msg?.notification?.title}</h4>, msg?.notification?.body],
      "info",
      1000000,
      {
        width: "100%",
        maxWidth: "calc(100% - 40px)",
        backgroundColor: "#e1e1e1",
        color: "#333333",
      }
    )
  );

  appPendo(user);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={Dashboard} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/users" component={UserWrapper} />
        <ContentRoute path="/teams" component={Teams} />
        {user.is_admin && (
          <ContentRoute path="/schools" component={SchoolWrapper} />
        )}
        <ContentRoute path="/goals" component={GoalsWrapper} />
        {user.is_admin && (
          <ContentRoute path="/contents" component={ContentsWrapper} />
        )}
        <ContentRoute path="/config" component={ConfigWrapper} />
        <ContentRoute path="/announcements" component={AnnouncementsWrapper} />
        <ContentRoute
          path="/push-notifications"
          component={PushNotifications}
        />
        <ContentRoute path="/notifications" component={Notifications} />
        <ContentRoute path="/polls" component={Polls} />
        <ContentRoute path="/zone-in" component={ZoneIn} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
