import React from 'react'
import { NavLink } from 'react-router-dom';
import { useLocation } from "react-router";
import { useSelector } from 'react-redux';
import SVG from "react-inlinesvg";

import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import { SubMenu } from './SubMenu'

export const MenuOption = ({ item }) => {
  const { user } = useSelector(state => state.auth);
  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };
  
  return (
    <li
      aria-haspopup="true"
      style={{
        display: (!user.is_admin
        && (item.title === "Schools" || item.title === "Daily Picks" || item.title === "Content" || item.title === "Calendar")) // only for admins
        // || (user.is_admin && item.title === "Calendar")  // only for staff
        ? "none" : "flex" }}
      className={`menu-item${item.submenu ? getMenuItemActive(item.page, true) : getMenuItemActive(item.page, false)}`}
      data-menu-toggle={item.submenu && "hover"}
    >
      <NavLink className="menu-link menu-toggle" to={item.page}>
        <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl(`/media/svg/icons/${item.icon}`)}/>
        </span>
        <span className="menu-text">{item.title}</span>
        {item.submenu && <i className="menu-arrow"/>}
      </NavLink>
      {item.submenu && <SubMenu submenu={item.submenu} />}
    </li>
  )
}
