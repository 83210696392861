import React, { Component } from 'react'

import Announcements from './Announcements'

class AnnouncementsWrapper extends Component {

  render() {
    return (
      <div className="table-container">
        <Announcements />
      </div>
    )
  }
}

export default AnnouncementsWrapper