import React from 'react'
import ClassicEditor from "ckeditor5-build-classic-image/build/ckeditor.js";
import CKEditor from '@ckeditor/ckeditor5-react';

const CustomEditor = ({ value, setFieldValue }) => {
  return (
    <CKEditor
      editor={ ClassicEditor }
      config={{
        toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'fontColor', 'fontBackgroundColor', '|', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', 'imageUpload', 'blockQuote', 'insertTable', '|', 'undo', 'redo', '|',]
      }}
      data={value}
      onChange={(e,editor) => { let data = editor.getData(); setFieldValue("content", data)}}
    />
  )
}

export default CustomEditor
