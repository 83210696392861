import React from 'react'
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles/chart.css';
import * as dashboardDUCK from "../../../../redux/ducks/dashboard.duck";

// const DashboardChart = ({ labels = ["Great", "Alright", "Not Great"], series = [63, 21, 16] }) => {
const DashboardChart = ({ mood, queries, dashboard_actions }) => {
  const { labels = [], values = [], color = [] } = mood;

  const options = {
    fill: color,
    colors: color,
    labels: labels,
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      color: 'white',
      labels: {
        colors: 'white'
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: '45%',
        }
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '10px',
        fontWeight: '800',
        color: 'white'
      }
    },
    tooltip: {
      custom: ({ value, seriesIndex, dataPointIndex, w }) => {
        return w.config.labels[seriesIndex]
      }
    },
    chart: {
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const newMood = config.w.config.labels[config.dataPointIndex];
          dashboard_actions.setDashboardQueries({ ...queries, mood: config.selectedDataPoints[0].length ? newMood : null });
        },
      }
    }
  }

  return (
    <Chart
      options={options}
      series={values}
      type="donut"
      height={270}
    />
  )
}

const mapStateToProps = store => ({
  mood: store.dashboard.data.dashboard_data.mood,
  queries: store.dashboard.queries,
})

const mapDispatchToProps = (dispatch) => ({
  dashboard_actions: bindActionCreators(dashboardDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardChart);