import React from "react";
import { MenuItem, Select, makeStyles } from "@material-ui/core";
import { bindActionCreators } from "redux";
import * as dashboardDUCK from "../../../../redux/ducks/dashboard.duck";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  select: {
    "&>.MuiSelect-icon": {
      color: "#ECFCFF",
      top: 2,
      right: -10,
      width: "1.5em",
      height: "1.5em",
    },
  },
  input: {
    color: "#00B9E1",
    fontSize: 32,
    fontWeight: 500,
  },
  menu: {
    "& .MuiMenu-list": {
      backgroundColor: "#23263F",
      color: "#ECFCFF",
      "& .MuiListItem-root": {
        fontSize: 17,
      },
    },
  },
}));

const TeamsList = ({
  teams = [],
  selected_team,
  dashboard_actions,
  queries,
  selected_month,
}) => {
  const classes = useStyles();
  const handleTeam = (e) => {
    const { value } = e?.target;
    console.log("value", value);
    dashboard_actions.setDashboardState({ key: "selected_team", value: value });
    dashboard_actions.setDashboardQueries({
      ...queries,
      team: value,
      month: selected_month?.date_value,
    });
  };
  return (
    <>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        className={classes.select}
        value={selected_team?.id || 0}
        inputProps={{
          className: classes.input,
        }}
        MenuProps={{
          className: classes.menu,
        }}
        disableUnderline
        onChange={handleTeam}
      >
        {teams.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const mapStateToProps = (store) => ({
  teams: store.dashboard.data?.select_data.teams,
  selected_team: store.dashboard.data?.select_data.selected_team,
  selected_month: store.dashboard.data?.select_data.selected_month,
  queries: store.dashboard.queries,
});

const mapDispatchToProps = (dispatch) => ({
  dashboard_actions: bindActionCreators(dashboardDUCK.actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsList);
