import React from "react";
import {
  Paper,
  Select,
  TextField,
  MenuItem,
  FormControl,
} from "@material-ui/core";

const FilterDataTableSelect = ({ options, handleChange, values }) => {
  const handle = (e) => {
    const { value } = e.target;
    handleChange(value);
  };

  return (
    <Paper style={{ width: "100%", maxWidth: "400px" }}>
      <FormControl fullWidth size="small" style={{ maxHeight: "35px" }}>
        <TextField
          select
          variant="outlined"
          size="small"
          id="autocomplete_filter_inputSelect"
          label="Filter by team"
          name="Teams"
          value={values.team_id}
          onChange={handle}
          style={{ maxHeight: "35px" }}
        >
          <MenuItem value="allgroups" id="team_allgroup">
            All Teams
          </MenuItem>
          <MenuItem value="nogroup" id="team_nogroup">
            No Team
          </MenuItem>
          {options.map((team, i) => (
            <MenuItem key={team.id} value={team.id} id={`team_${team.i}`}>
              {team.name}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Paper>
  );
};

export default FilterDataTableSelect;
