/* eslint-disable no-throw-literal */
import notice from "../../utils/notice";
import { CONTENTS_URL, CONTENTS_URL_v2 } from "../helpers/routes";
import { getMultipartParams, handleResponse } from "../helpers/validate";

export const defaultContent = {
  name: "",
  description: "",
  content: null,
  content_type: "",
  author_name: "",
  focus_areas_ids: "",
  categories_id: "",
  moods_id: "",
  contributing_factors_id: "",
  image: null,
  logo: null,
  duration: "",
  duration_minutes: "",
  content_partner: "",
};

export const getContents = (
  setterFunction,
  page = 1,
  zone_id = 1,
  query = "",
  pro_athlete = false
) => {
  return fetch(
    `${CONTENTS_URL_v2}/list-category?category_id=${zone_id}&page=${page}&search_query=${query}&is_pro_athlete=${pro_athlete}`,
    getMultipartParams("GET")
  )
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => {
      notice("Error on loading content ");
      console.error(e.errors);
    });
};

export const getContentsList = (setterFunction, page = 1, zone_id = 1) => {
  return fetch(CONTENTS_URL, getMultipartParams("GET"))
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json.items))
    .catch((e) => {
      notice("Error on loading content ");
      console.error(e.errors);
    });
};

export const getContent = (setterFunction, id) => {
  return fetch(`${CONTENTS_URL_v2}/show?id=${id}`, getMultipartParams("GET"))
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => {
      notice("Error on loading content ");
      console.error(e.errors);
    });
};

export const saveContent = (setterFunction, data) => {
  const formData = new FormData();

  for (const name in data) {
    if (Array.isArray(data.content) && name === "content") continue;
    formData.append(name, data[name]);
  }
  if (Array.isArray(data.content)) {
    for (const index in data.content) {
      formData.append("content" + index, data.content[index]);
    }
    formData.append("totalContents", data.content.length);
  }

  return fetch(
    `${CONTENTS_URL_v2}/create-content`,
    getMultipartParams("POST", formData)
  )
    .then((res) => handleResponse(res))
    .then((json) => {
      //TODO replace with api response
      /*if (json && json.id) {
      notice("Content created", "success")
      return setterFunction(json)
    } else {
      notice("Error saving the content")
      throw {error: json}
    }*/
      notice("Content created", "success");
    })
    .catch((e) => {
      notice("Error saving the content");
      console.error(e.errors);
      throw e;
    });
};

export const updateContent = (setterFunction, data, id) => {
  const formData = new FormData();

  for (const name in data) {
    if (Array.isArray(data.content) && name === "content") continue;
    formData.append(name, data[name]);
  }

  if (Array.isArray(data.content)) {
    for (const index in data.content) {
      formData.append("content" + index, data.content[index]);
    }
    formData.append("totalContents", data.content.length);
  }

  return fetch(
    `${CONTENTS_URL_v2}/update?id=${id}`,
    getMultipartParams("PUT", formData)
  )
    .then((res) => handleResponse(res))
    .then((json) => {
      if (json && json.id) {
        notice("Content  updated", "success");
        return setterFunction(json);
      } else {
        notice("Error saving the content");
        throw { error: json };
      }
    })
    .catch((e) => {
      notice("Error saving the content");
      console.error(e.errors);
      throw e;
    });
};

export const deleteContent = (id) => {
  return fetch(
    `${CONTENTS_URL_v2}/delete?id=${id}&active=false`,
    getMultipartParams("DELETE")
  )
    .then((res) => handleResponse(res))
    .then((json) => {
      notice("Content deleted", "success");
      return json;
    })
    .catch((e) => {
      notice("Error deleting the content");
      console.error(e.errors);
      return e;
    });
};
