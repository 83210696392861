/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Paper,
  MuiThemeProvider,
  Dialog,
  DialogContent,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  ButtonGroup,
  Button,
  useMediaQuery,
  createMuiTheme,
  DialogTitle,
  CardActionArea,
  Tooltip,
} from "@material-ui/core";
import {
  Reorder,
  Apps,
  Visibility,
  Delete,
  Bookmark,
  Share,
  Add,
} from "@material-ui/icons";
import { bindActionCreators } from "redux";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import moment from "moment";

import AnnouncementsDialog from "./AnnouncementsDialog";

import {
  getAnnouncementsStaff,
  defaultAnnouncement,
  getAnnouncement,
  deleteAnnouncement,
} from "../../crud/api/annc.crud";
import { setLoader } from "../../../redux/ducks/load.duck";
import * as anncDUCK from "../../../redux/ducks/annc.duck";
import { setAlert } from "../../../redux/ducks/alert.duck";
import { useSelector } from "react-redux";

import { toAbsoluteUrl } from "../../../_metronic/_helpers/AssetsHelpers";
import FilterDataTable from "../../utils/FilterDataTable";
import CustomTableFooter from "../../utils/CustomTableFooter";

const Announcements = ({
  anncs,
  user,
  anncActions,
  settingLoader,
  setAlert,
  school,
}) => {
  const [isGrid, setIsGrid] = useState(false);
  const [dialog, setDialog] = useState("close");
  const [htmlContent, setHtmlContent] = useState("");
  const [query, setQuery] = useState("");
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const { selected_school } = useSelector((state) => state.school);

  useEffect(() => {
    if (school && school.id) {
      if (query && query.length > 1) {
        getAnnouncementsStaff(
          anncActions.setAnncs,
          user.id,
          1,
          school.id,
          query
        );
      } else getAnnouncementsStaff(anncActions.setAnncs, user.id, 1, school.id);
    }
  }, [query, anncActions.setAnncs, school, user.id, selected_school]);

  const deleteAnnc = (id) => {
    setAlert({
      title: "Delete Hub Content",
      message: "Are you sure to permanently delete this Hub Content?",
      btn_msg: "Delete Hub Content",
      action: () => deleteAnnouncement(anncActions.deleteAnncs, id),
    });
  };

  const columns = [
    {
      name: "full_name_staff",
      label: "Staff",
      options: {
        filter: false,
        sort: false,
        display: user.is_admin,
      },
    },

    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "file_type",
      label: "Type",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value) => {
          let src;
          let type = value ? value.toLowerCase() : "";

          if (type === "audio")
            src = `${toAbsoluteUrl("/media/assets/audio-icon.png")}`;
          if (type === "text" || type === "html")
            src = `${toAbsoluteUrl("/media/assets/text-icon.png")}`;
          if (type === "link")
            src = `${toAbsoluteUrl("/media/assets/link-icon.png")}`;
          if (type === "video")
            src = `${toAbsoluteUrl("/media/assets/video-icon.png")}`;
          if (type === "pdf")
            src = `${toAbsoluteUrl("/media/assets/pdf-icon.png")}`;
          if (type === "document")
            src = `${toAbsoluteUrl("/media/assets/docx-icon.png")}`;
          if (type === "image") src = `${toAbsoluteUrl("/media/assets/image")}`;

          return (
            src && <img width="40px" height="40px" src={src} alt="file_type" />
          );
        },
      },
    },
    {
      name: "read",
      label: "Read",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "liked",
      label: "Liked",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "created_at_str",
      label: "Created at",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <>{moment(value).format("DD-MMM-YYYY")}</>,
      },
    },

    {
      name: "id",
      label: "Actions",
      options: {
        display: !user.is_admin,
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => (
          <div className="d-flex">
            <Tooltip title="Edit Team" placement="top">
              <a
                id={`edit_team_btn_${value}`}
                className="btn btn-icon btn-sm btn-light btn-hover-primary "
                onClick={() => openDialog("editing", value)}
              >
                <i className="fas fa-edit text-primary" />
              </a>
            </Tooltip>
            <Tooltip title="Delete Team" placement="top">
              <a
                id={`delete_team_btn_${value}`}
                className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                onClick={() => deleteAnnc(value)}
              >
                <Delete />
              </a>
            </Tooltip>
          </div>
        ),
      },
    },
  ];

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: anncs.rowsPerPage,
    page: anncs.page,
    count: anncs.count,
    rowHover: false,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: "none",
    tableBodyMaxHeight: isDesktop ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data",
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "announcements.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayRowsOnly: true,
      },
    },
    customFooter: (count, page, rowsPerPage) => {
      settingLoader(true);
      return (
        <CustomTableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={(mPage) =>
            getAnnouncementsStaff(
              anncActions.setAnncs,
              user.id,
              mPage,
              school.id,
              query
            )
          }
        />
      );
    },
  };

  const openDialog = (mode, id = null) => {
    switch (mode) {
      case "create":
        settingLoader(true);
        anncActions.setAnnc(defaultAnnouncement);
        setDialog(mode);
        break;
      case "editing":
        settingLoader(true);
        getAnnouncement(anncActions.setAnnc, id).then(() => setDialog(mode));
        break;
      case "close":
        setDialog(mode);
        setTimeout(() => {
          anncActions.setAnnc(defaultAnnouncement);
        }, 500);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  };

  const HtmlDialogContent = () => (
    <Dialog
      open={Boolean(htmlContent)}
      onClose={() => setHtmlContent("")}
      fullWidth
      maxWidth="md"
      id="announce_content_dialog"
    >
      <DialogTitle>Content</DialogTitle>
      <DialogContent>
        <span dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </DialogContent>
    </Dialog>
  );

  const cardView = () => (
    <Grid container spacing={2} style={{ padding: "25px 10px" }}>
      {anncs.data.map((annc) => {
        return (
          <Grid item key={annc.id} xs={12} sm={6} md={3}>
            <Card>
              <CardActionArea
                id="edit_announce_card_action"
                onClick={() => openDialog("editing", annc.id)}
              >
                <CardMedia
                  style={{ height: 125 }}
                  image={
                    annc.cover_image_url
                      ? annc.cover_image_url
                      : "https://itsthezone.s3.amazonaws.com/defaults/announcement_cover_image_default.png"
                  }
                  title="user"
                />
                <CardContent>
                  <Typography variant="h5">{annc.title}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Type: {annc.content_type}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Date registered:{" "}
                    {moment(annc.created_at).format("MMM DD YYYY")}
                  </Typography>
                </CardContent>
                <CardActions>
                  <span>
                    <Visibility className="text-primary" /> {annc.metrics.read}
                  </span>
                  <span>
                    <Bookmark className="text-success" />{" "}
                    {annc.metrics.bookmarked}
                  </span>
                  <span>
                    <Share className="text-warning" /> {annc.metrics.shared}
                  </span>
                </CardActions>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            padding: "4px 10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            // eslint-disable-next-line no-useless-computed-key
            ["@media(min-width: 960px)"]: {
              maxWidth: 200,
            },
          },
        },
        MUIDataTableToolbar: {
          root: {
            background:
              "linear-gradient(125deg, rgba(17, 151, 192, 1) 25%, rgba(4, 7, 25, 1) 100%)",
            minHeight: "50px",
            height: "50px",
            borderTopLeftRadius: "3px",
            borderTopRightRadius: "3px",
            color: "white",
          },
          icon: {
            color: "white",
          },
          titleRoot: {
            display: "none",
          },
          left: {
            marginLeft: 80,
          },
        },
        MUIDataTable: {
          root: {
            display: !isGrid ? "block" : "none",
          },
        },
        MuiTable: {
          root: {
            display: !isGrid ? "table" : "none",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            background: "rgb(22 147 186) !important",
          },
          fixedHeader: {
            zIndex: 90,
            fontWeight: "bold",
            color: "white",
          },
        },
      },
    });

  return (
    <>
      <div className="pb-4 d-flex justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} />
        {!user.is_admin && (
          <Button
            id="create_announce_btn"
            variant="contained"
            color="secondary"
            onClick={() => openDialog("create")}
          >
            <Add /> Upload Content
          </Button>
        )}
      </div>
      <Paper>
        <MuiThemeProvider theme={getMuiTheme()}>
          <ButtonGroup
            variant="text"
            style={{
              position: "absolute",
              zIndex: 1,
              margin: 10,
            }}
          >
            <Button
              id="announce_list_view_btn"
              style={{ color: "white", border: "none" }}
              onClick={() => setIsGrid(false)}
            >
              <Reorder />
            </Button>
            <Button
              id="announce_grid_view_btn"
              style={{ color: "white" }}
              onClick={() => setIsGrid(true)}
            >
              <Apps />
            </Button>
          </ButtonGroup>
          <MUIDataTable
            title=""
            columns={columns}
            data={anncs.data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      {isGrid && cardView()}
      <AnnouncementsDialog isOpen={true} mode={dialog} setDialog={openDialog} />
      <HtmlDialogContent />
    </>
  );
};

const mapStateToProps = (store) => ({
  school: store.school.selected_school,
  user: store.auth.user,
  anncs: store.annc.anncs,
});

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  anncActions: bindActionCreators(anncDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
