import React from "react";
import { MenuItem, Select, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  select: {
    "&>.MuiSelect-icon": {
      color: "#ECFCFF",
      top: 0,
      right: -8,
      width: "1.5em",
      height: "1.5em",
    },
  },
  input: {
    color: "#ECFCFF",
    fontSize: ({ inputFontSize }) => inputFontSize,
    fontWeight: 500,
    margin: "auto",
  },
  item: {
    "& .MuiMenu-list": {
      backgroundColor: "#23263F",
      color: "#ECFCFF",
      "& .MuiListItem-root": {
        fontSize: 17,
      },
    },
  },
  container: {
    backgroundColor: "#16192B",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 35px 0px 35px",
    borderRadius: 30,
    height: 40,
    minWidth: "100%",
  },
}));

const Dropdown = ({
  items,
  value,
  onChange,
  IconComponent,
  inputFontSize = 17,
  valueKey = "value",
  textKey = "text",
  ...restProps
}) => {
  const classes = useStyles({ inputFontSize });
  return (
    <div className={classes.container}>
      <Select
        className={classes.select}
        value={value}
        onChange={onChange}
        MenuProps={{
          className: classes.item,
        }}
        inputProps={{
          className: classes.input,
        }}
        disableUnderline
        IconComponent={IconComponent}
        {...restProps}
      >
        {items.map((item) => (
          <MenuItem key={item[valueKey]} value={item[valueKey]}>
            {item[textKey]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default Dropdown;
