import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import SVG from "react-inlinesvg";

import * as Menu from "../../../MenuConfig";
import SchoolSelector from "./SchoolSelector";
import { MenuOption } from "./MenuOption";
import { toAbsoluteUrl } from "../../../../_helpers";
import { Divider } from "@material-ui/core";
import { getPermisssion } from "../../../../../app/crud/api/Permission.crud";
import * as permissionDuck from "../../../../../redux/ducks/permissions.duck";
import { bindActionCreators } from "redux";
import { setShow } from "../../../../../redux/ducks/support_card.duck";

function AsideMenuList({ permissionsActions, layoutProps, setShow }) {
  const [itemss, setItems] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const { selected_school } = useSelector((state) => state.school);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    let itemss = [...Menu.default.aside.items];

    if (selected_school != null) {
      getPermisssion(selected_school.id).then((data) => {
        // const permission = data.find(x => !x.read && x.module_clave === 'dash001');

        //if(!permission?.read)history.push('/users')
        permissionsActions.setPermissions(data);
        setPermissions(data);

        itemss[1].title = "Share Resources";
        setItems(Menu.default.aside.items);
      });
    } else {
      itemss.splice(1, 1);
      console.log(itemss);
      setItems(itemss);
    }
  }, [selected_school]);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {(user.is_admin || (user && user && user.is_admin)) && (
          <li style={{ padding: "0 25px", marginBottom: 25 }}>
            <SchoolSelector />
          </li>
        )}
        {/*begin::1 Level*/}
        {itemss.map((item) => {
          const permission = permissions.find(
            (x) => x.module_clave === item.clave
          );
          if (
            (item.admin && !user.is_admin) ||
            (item.staff_member_admin &&
              !user.staff_member_admin &&
              !user.is_admin)
          ) {
            return <></>;
          } else if (
            item.clave === undefined ||
            permissions.length === 0 ||
            permission?.read === true
          )
            return <MenuOption item={item} key={item.title} />;
          // item.title = item.title === 'organization_hub' ?nameSelectedSchool + " Hub" : item.title
          // return ((item.admin && !user.is_admin) || (item.staff_member_admin && (!user.staff_member_admin && !user.is_admin))) ? <></> : (
          //   <MenuOption item={item} key={item.title } />
          // );
        })}
        <Divider />
        <li className="menu-item">
          <a
            className="menu-link menu-toggle"
            target="_blamk"
            href="https://docs.google.com/forms/d/e/1FAIpQLSecHYEtASE941oGMtTn2gP2TbNyJyw95IkZaVq_EDB9VXkYwg/viewform?usp=sf_link"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  `/media/svg/icons/Communication/Incoming-box.svg`
                )}
              />
            </span>
            <span className="menu-text">Share Feedback</span>
          </a>
        </li>
        <li
          className="menu-item"
          onClick={(e) => {
            e.preventDefault();
            setShow(true);
          }}
        >
          <a className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(`/media/svg/icons/Code/Question-circle.svg`)}
              />
            </span>
            <span className="menu-text">Get Support</span>
          </a>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

const mapStateToProps = (state) => ({
  show: state.showReducer.show,
});

const mapDispatchToProps = (dispatch) => ({
  permissionsActions: bindActionCreators(permissionDuck.actions, dispatch),
  setShow: bindActionCreators(setShow, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AsideMenuList);
