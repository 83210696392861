import React, { useState, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField, CircularProgress } from "@material-ui/core";

const TimeZoneSelector = ({
  time_zone,
  setFieldValue,
  helperText,
  error,
  onBlur,
}) => {
  const time_zones = [
    { label: "Alaska", time_zone: "America/Juneau" },
    { label: "Pacific Time", time_zone: "America/Los_Angeles" },
    { label: "Mountain Time", time_zone: "America/Denver" },
    { label: "Arizona", time_zone: "America/Phoenix" },
    { label: "Central Time", time_zone: "America/Chicago" },
    { label: "Eastern Time", time_zone: "America/New_York" },
    { label: "Indiana (East)", time_zone: "America/Indiana/Indianapolis" },
    { label: "Newfoundland", time_zone: "America/St_Johns" },
    { label: "Puerto Rico", time_zone: "America/Puerto_Rico" },
  ];
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(time_zones);
  const [search, setSearch] = useState("");
  const loading = open && options.length === 0;

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    if (open) setOptions(time_zones);
  }, [open, loading, search, time_zones]);

  useEffect(() => {
    if (!open) setOptions([]);
  }, [open]);

  return (
    <Autocomplete
      id="school_time_zone_input_dialog"
      fullWidth
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      value={time_zone}
      inputValue={search}
      onChange={(e, newValue) =>
        setFieldValue("time_zone", newValue?.time_zone)
      }
      onInputChange={(e, newInputValue) => setSearch(newInputValue)}
      getOptionSelected={(option, value) => option.time_zone === value}
      getOptionLabel={(option) =>
        option.label
          ? option.label
          : time_zones.find((x) => x.time_zone === time_zone).label
      }
      renderInput={(params) => (
        <TextField
          {...params}
          required
          name="time_zone"
          label="Time Zone"
          variant="outlined"
          error={error}
          helperText={helperText}
          onBlur={onBlur}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default TimeZoneSelector;
