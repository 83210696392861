import React, { useState } from 'react'
import { Dialog, DialogContent, Grid, TextField, DialogActions, Button, DialogTitle, CircularProgress } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { setLoader } from '../../../../redux/ducks/load.duck'
import * as sportDUCK from "../../../../redux/ducks/sport.duck"
import { saveSport, updateSport } from '../../../crud/api/sports.crud'

const SportsDialog = ({ mode, setDialog, sport, school, sportActions, settingLoader }) => {
  const open = mode === 'editing' || mode === 'create'
  const title = mode === 'editing' ? "Edit Sport" : "New Sport"
  const [loading, setLoading] = useState(false);

  const create = async values => {
    try {
      await saveSport(sportActions.updateSports, school.slug, values)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const update = async values => {
    try {
      await updateSport(sportActions.updateSports, school.slug, values, sport.id)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={() => setDialog("close")} id="sport_dialog">
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          ...sport,
          active: sport.active ? sport.active : true,
        }}
        validate={values => {
          const errors = {}
          if (!values.name) errors.name = 'Required field'

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true)
          setTimeout(() => {
            sport.id ? update(values) : create(values)
            setSubmitting(false)
          }, 1000)
        }}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
        <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="sport_name_input_dialog"
                  label="Sport"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="sport_color_input_dialog"
                  label="Color"
                  name="color"
                  type="color"
                  value={values.color}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rowsMax="4"
                  variant="outlined"
                  id="sport_description_input_dialog"
                  label="Description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="cancel_sport_btn_dialog" onClick={() => setDialog("close")} color="secondary">
              Cancel
            </Button>
            <button
              type="submit"
              id="submit_sport_btn_dialog"
              disabled={isSubmitting || !values.name}
              className="btn btn-primary btn-elevate kt-login__btn-primary d-flex">
              {mode === "editing" ? "Submit" : "Create"}
              {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
            </button>
          </DialogActions>
        </form>
      )}
      </Formik>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  sport: store.sport.sport,
  school: store.school.selected_school,
})

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  sportActions: bindActionCreators(sportDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SportsDialog)
