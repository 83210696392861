import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const SimpleCheckbox = ({
  id,
  name,
  label,
  labelPos = "top",
  value,
  onChange,
  disabled,
  onBlur,
  error,
  helperText,
}) => {
  
  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPos}
      control={
        <Checkbox
          id={id}
          name={name}
          disabled={disabled}
          checked={value}
          onChange={({ target }) =>
            onChange({ target: { name, value: target.checked } })
          }
        />
      }
    />
  );
};

export default SimpleCheckbox;
