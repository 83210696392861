/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Tooltip } from "@material-ui/core";
import { Add, Delete, Visibility } from "@material-ui/icons";

import FormDialog from "./FormDialog";
import CustomTable from "../../utils/CustomTable";

import * as pollDUCK from "../../../redux/ducks/poll.duck";
import { setLoader } from "../../../redux/ducks/load.duck";
import {
  getPolls,
  defaultPoll,
  getPoll,
  deletePoll,
} from "../../crud/api/polls.crud";
import { columns } from "./utils";
import { setAlert } from "../../../redux/ducks/alert.duck";
import notice from "../../utils/notice";

const Polls = ({
  polls,
  pollActions,
  settingLoader,
  staff_member_admin,
  is_admin,
  setAlertModal,
  selected_school,
}) => {
  const [dialog, setDialog] = useState("close");
  const [page, setPage] = useState(1);

  useEffect(() => {
    getPolls(pollActions.setPolls, page);
  }, [page, pollActions.setPolls]);

  const handleDeletePoll = (data) => {
    setAlertModal({
      title: "Delete Check-in",
      message: "Are you sure to permanently delete this Check-in?",
      btn_msg: "Delete Check-in",
      action: () =>
        deletePoll(data, selected_school)
          .then((res) => {
            if (res) {
              notice("Check-in deleted", "success");
              getPolls(pollActions.setPolls, page);
            } else notice("Error deleting Poll");
          })
          .catch((e) => notice("Error deleting Check-in")),
    });
  };

  const actionColumns = [
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, { tableData, rowIndex }) => (
          <div className="d-flex">
            <Tooltip title="Edit Check-in" placement="top">
              <a
                id={`edit_poll_btn_${value}`}
                className="btn btn-icon btn-sm btn-light btn-hover-primary "
                onClick={() => openDialog("editing", value)}
              >
                {staff_member_admin || is_admin ? (
                  <i className="fas fa-edit text-primary" />
                ) : (
                  <Visibility />
                )}
              </a>
            </Tooltip>
            {(staff_member_admin || is_admin) && (
              <Tooltip title="Delete Check-in" placement="top">
                <a
                  id={`delete_poll_btn_${value}`}
                  className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                  onClick={() => handleDeletePoll(tableData[rowIndex])}
                >
                  <Delete />
                </a>
              </Tooltip>
            )}
          </div>
        ),
      },
    },
  ];

  const openDialog = (mode, id = null) => {
    switch (mode) {
      case "create":
        settingLoader(true);
        pollActions.setPoll(defaultPoll);
        setDialog(mode);
        break;
      case "editing":
        settingLoader(true);
        getPoll(pollActions.setPoll, id).then(() => setDialog(mode));
        break;
      case "close":
        settingLoader(true);
        setDialog(mode);
        setTimeout(() => {
          pollActions.setPoll(defaultPoll);
        }, 500);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  };

  return (
    <div className="table-container">
      <div className="pb-4 d-flex justify-content-between align-items-end">
        {/* <FilterDataTable query={query} setQuery={setQuery} /> */}
        {
          <Button
            className="ml-auto mr-0"
            id="create_poll_btn"
            variant="contained"
            color="secondary"
            onClick={() => openDialog("create")}
          >
            <Add /> New Check-in
          </Button>
        }
      </div>
      <CustomTable
        title=""
        columns={[...columns, ...actionColumns]}
        data={polls.data}
        page={polls.page}
        count={polls.count}
        setPage={setPage}
      />
      <FormDialog
        mode={dialog}
        setDialog={openDialog}
        school={selected_school}
      />
    </div>
  );
};

const mapStateToProps = (store) => ({
  polls: store.poll.polls,
  is_admin: store.auth.user?.is_admin,
  staff_member_admin: store.auth.user?.staff_member_admin,
  selected_school: store.school.selected_school,
});

const mapDispatchToProps = (dispatch) => ({
  pollActions: bindActionCreators(pollDUCK.actions, dispatch),
  settingLoader: bindActionCreators(setLoader, dispatch),
  setAlertModal: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Polls);
