export const mindflowDialogColumns = [
  {
    name: "name",
    label: "Name",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "gender",
    label: "Gender",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "team",
    label: "Team",
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const mindflowDialogOptions = {
  serverSide: true,
  rowsPerPageOptions: [],
  /*   rowsPerPage: schools.rowsPerPage,
  page: schools.page,
  count: schools.count, */
  rowHover: false,
  search: false,
  sort: false,
  print: false,
  filter: false,
  viewColumns: false,
  download: false,
  selectableRows: "none",
  /* tableBodyMaxHeight: isDesktop ? "50em" : "357px", */
  textLabels: {
    body: {
      noMatch: "No data",
    },
    pagination: {
      next: "Next",
      previous: "Previous",
      rowsPerPage: "Rows:",
      displayRows: "of",
    },
  },
};
