import { useMediaQuery } from '@material-ui/core';

export const useBreakpoints = () => {
    const matchesXs = useMediaQuery(theme => theme.breakpoints.up('xs'));
    const matchesSm = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const matchesMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const matchesLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const isXs = !  matchesXs;
    const isS = matchesXs && !matchesSm;
    const isM = matchesSm && !matchesMd;
    const isL = matchesMd && !matchesLg;
    const isDesktop = matchesLg;
        
    return {
        isXs,
        isS,
        isM,
        isL,
        isDesktop,
    };
};