import { SCHOOL_URL, FEELINGS_URL } from "../helpers/routes"
import { handleResponse, getParams, getMultipartParams } from "../helpers/validate"
import notice from "../../utils/notice"

export const defaultFeeling = {
  name: "",
  icon: "",
  definition: "",
  call_to_action_mood_id: ""
}

export const getFeelings = (setterFunction, slug, page = 1, query = "") => {
  return fetch(`${SCHOOL_URL}/${slug}/feelings?page=${page}&search_query=${query}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error on loading feelings")
    console.error(e.errors)
    throw e
  })
}

export const getFeeling = (setterFunction, id) => {
  return fetch(`${FEELINGS_URL}/show?id=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error fetching feeling")
    console.error(e.errors)
    throw e
  })
}

export const saveFeeling = (setterFunction, slug, data) => {
  const jsonData = JSON.stringify(data)

  return fetch(`${SCHOOL_URL}/${slug}/feelings`, getParams('POST', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Feeling created", "success")
    setterFunction({...json.feeling, content: json.content})
  })
  .catch(e => {
    notice("Error saving the feeling")
    console.error(e.errors)
    throw e.errors
  })
}

export const updateFeeling = (setterFunction, slug, data, id) => {
  const jsonData = JSON.stringify(data)

  return fetch(`${FEELINGS_URL}/update?id=${id}`, getParams('PUT', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Feeling updated", "success")
    setterFunction({...json.feeling, content: json.content})
  })
  .catch(e => {
    notice("Error saving the feeling")
    console.error(e.errors)
    throw e.errors
  })
}