import { Grid } from "@material-ui/core";
import { makeStyles, MenuItem, darken } from "@material-ui/core";
import React from "react";
import { useIcons } from "../../../hooks/useIcons";
import DashboardTitle from "./DashboardTitle";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  item: {
    backgroundColor: darken("#152CFF", 0.3),
    padding: "10px 16px 10px 32px",
    borderRadius: 8,
    // Applies to all texts
    "&>*": {
      "&>*": {
        lineHeight: 1,
        marginTop: "auto",
        marginBottom: "auto",
      },
    },
  },
  percent: {
    display: "grid",
    justifyItems: "end",
    maxWidth: "50px",
    minWidth: "35px",
  },
  menuItem: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const TeleHelpNotifications = ({
  telehelp_counter = 0,
  text,
  icon = "NotificationsIcon",
}) => {
  const classes = useStyles();
  const Icon = useIcons(icon);
  return (
    <MenuItem className={classes.menuItem}>
      <Grid
        container
        item
        spacing={1}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.item}
      >
        <Grid container item xs>
          <Grid item xs={2} lg={2}>
            <DashboardTitle
              variant="dark.grey3"
              fontWeight={600}
              fontSize={28}
              text={telehelp_counter}
            />
          </Grid>
          <Grid container xs={10} lg item style={{ minWidth: '265px' }}>
            <DashboardTitle
              variant="dark.grey3"
              fontWeight={500}
              fontSize={20}
              text={text}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item xs
          className={classes.percent}
        >
          {icon && <Icon fontSize="large" style={{ color: "#152CFF" }} />}
        </Grid>
      </Grid>
    </MenuItem>
  );
};

const mapStateToProps = (store) => ({
  telehelp_counter: store.dashboard.data.dashboard_data.telehelp_counter,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeleHelpNotifications);
