import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useIcons } from "../../../hooks/useIcons";
import DashboardTitle from "./DashboardTitle";

const useStyles = makeStyles((theme) => ({
  image: {
    height: 115,
    width: "100%",
    borderRadius: 5,
    objectFit: "cover",
  },
  viewsContainer: {
    width: 73,
    height: 24,
    backgroundColor: "#040719",
    opacity: 0.5,
    borderRadius: 5,
    position: "relative",
    margin: "-30px 10px auto auto",
    alignContent: "center",
    display: "grid",
    textAlign: "center",
  },
  iconContainer: {
    position: "relative",
    margin: "-105px 10px auto auto",
    textAlign: "right",
    paddingRight: 5,
  },
  icon: {
    color: "#FCFFFF",
  },
}));

const DashboardImageLibrary = ({ src, views, icon, text }) => {
  const classes = useStyles();
  const Icon = useIcons(icon);
  return (
    <div style={{ display: "grid" }}>
      <img className={`img-fluid ${classes.image}`} src={src} alt={text} />
      <div className={classes.viewsContainer}>
        <DashboardTitle
          text={`${views} views`}
          variant="white"
          fontSize={14}
          fontWeight={600}
        />
      </div>
      {icon && (
        <div className={classes.iconContainer}>
          <Icon className={classes.icon} />
        </div>
      )}
      {text && (
        <span className="mt-5">
          <DashboardTitle
            text={text}
            fontSize={17}
            fontWeight={500}
            variant="dark.secondary"
          />
        </span>
      )}
    </div>
  );
};

export default DashboardImageLibrary;
