import { CONFERENCE_URL } from "../helpers/routes"
import { handleResponse, getParams, getMultipartParams } from "../helpers/validate"
import notice from "../../utils/notice"

export const defaultConference = {
  name: "",
  description: "",
  color: "",
  active: true
}

export const searchConference = (query, setterFunction) => {
  return fetch(`${CONFERENCE_URL}/suggestions?q=${query}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => console.error(e.errors))
}

export const getConferences = (setterFunction, page = 1) => {
  return fetch(`${CONFERENCE_URL}?page=${page}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error loading conferences")
    console.error(e.errors)
  })
}

export const getConference = (setterFunction, id) => {
  return fetch(`${CONFERENCE_URL}/show?id=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error fetching conference")
    console.error(e.errors)
    throw e
  })
}

export const saveConference = (setterFunction, data) => {
  const jsonData = JSON.stringify(data)

  return fetch(CONFERENCE_URL, getParams('POST', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Conference created", "success")
    setterFunction(json)
  })
  .catch(e => {
    notice("Error saving the conference")
    console.error(e.errors)
    throw e
  })
}

export const updateConference = (setterFunction, data, id) => {
  const jsonData = JSON.stringify(data)

  return fetch(`${CONFERENCE_URL}/update?id=${id}`, getParams('PUT', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Conference updated", "success")
    return setterFunction(json)
  })
  .catch(e => {
    notice("Error saving the conference")
    console.error(e.errors)
    throw e
  })
}