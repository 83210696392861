import { SCHOOL_URL, SPORTS_URL } from "../helpers/routes"
import { handleResponse, getParams, getMultipartParams } from "../helpers/validate"
import notice from "../../utils/notice"

export const  defaultSport = {
  active: true,
  name: "",
  description: "",
  color: "",
  icon: ""
}

export const getSports = (setterFunction, slug, page = 1, query = "") => {
  return fetch(`${SCHOOL_URL}/${slug}/sports?page=${page}&search_query=${query}`, getMultipartParams('GET'))
  .then((res) => handleResponse(res))
  .then((json) => setterFunction(json))
  .catch(e => {
    notice("Error on loading the sports")
    console.error(e.errors)
    throw e
  })
}

export const getSport = (setterFunction, id) => {
  return fetch(`${SPORTS_URL}/show?id=${id}`, getMultipartParams('GET'))
  .then((res) => handleResponse(res))
  .then((json) => setterFunction(json))
  .catch(e => {
    notice("Error fetching the sport")
    console.error(e.errors)
    throw e
  })
}

export const searchSport = (setterFunction, slug, query) => {
  if (slug) {
    return fetch(`${SCHOOL_URL}/${slug}/sports/suggestions?q=${query}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
      notice("Error on loading sports")
      console.error(e.errors)
    })
  }
}

export const saveSport = (setterFunction, slug, data) => {
  const jsonData = JSON.stringify(data)

  return fetch(`${SCHOOL_URL}/${slug}/sports`, getParams('POST', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Sport created", "success")
    setterFunction({sport: json})
  })
  .catch(e => {
    notice("Error saving the sport")
    console.error(e.errors)
    throw e
  })
}

export const updateSport = (setterFunction, slug, data, id) => {
  const jsonData = JSON.stringify(data)

  return fetch(`${SCHOOL_URL}/${slug}/sports/update?id=${id}`, getParams('PUT', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Sport updated", "success")
    console.log(json)
    return setterFunction({sport: json})
  })
  .catch(e => {
    notice("Error saving the sport")
    console.error(e.errors)
    throw e
  })
}