import React from "react";
import { lighten, makeStyles } from "@material-ui/core";
import { useIcons } from "../../../hooks/useIcons";
import { Grid } from "@material-ui/core";
import DashboardTitle from "./DashboardTitle";

const useStyles = makeStyles((theme) => ({
  item: {
    backgroundColor: "#9ACEFF",
    borderRadius: 8,
    padding: "10px  16px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  percent: {
    display: "grid",
    justifyItems: "end",
  },
  icon: {
    backgroundColor: lighten("#9ACEFF", 0.55),
    color: "#091E25",
    padding: 5,
    borderRadius: 10,
  },
  text: {
    margin: "auto",
    textAlign: "center",
    maxWidth: "100%",
  },
}));

const DashboardResourcesViewer = ({ views, text, textSize }) => {
  const classes = useStyles();
  const Icon = useIcons("Visibility");
  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      className={classes.item}
      spacing={1}
    >
      <div className="m-auto" style={{color: "black !important"}}>
        <Grid container item xs={1} sm={1} md={1} lg={1}>
          <DashboardTitle
            color="black"
            variant="dark.black"
            fontWeight={600}
            fontSize={24}
            text={views}
          />
        </Grid>
      </div>
      <div className="m-auto">
        <Grid
          className={classes.text}
          container
          item
          xs={8}
          sm={8}
          md={8}
          lg={8}
        >
          <DashboardTitle
          color="black"
            variant="dark.grey1"
            fontWeight={500}
            fontSize={textSize || 12}
            text={text}
          />
        </Grid>
      </div>
      <div className="m-auto">
        <Grid
          container
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          className={classes.percent}
        >
          <Icon fontSize="large" className={classes.icon} />
        </Grid>
      </div>
    </Grid>
  );
};

export default DashboardResourcesViewer;
