import React from 'react'
import { TextField } from '@material-ui/core'
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "react-google-places-autocomplete/dist/index.min.css";

export const GAutocomplete = ({ initialValue, setterFunction, error }) => {
  
  return (
    <GooglePlacesAutocomplete
      onLoadFailed={(e) => console.error("Error", e)}
      placeholder=""
      apiKey={process.env.REACT_APP_GOOGLE_API}
      initialValue={initialValue}
      onSelect={async (place) => setterFunction("address", place.description)}
      suggestionsStyles={{container: { maxHeight: 150, overflow: "auto" }}}
      renderInput={props => (
        <TextField
        {...props}
          fullWidth
          id="adress_input_dialog"
          variant="outlined"
          name="address"
          label="Address"
          helperText={error}
          error={Boolean(error)}
        />
      )}
    />
  )
}
