/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  MuiThemeProvider,
  Button,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import { Add, Delete, Visibility } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";

import { actionTableMuiTheme } from "../table/table_styles";
import FilterDataTable from "../../utils/FilterDataTable";
import TeamsDialog from "./TeamsDialog";

import * as teamDUCK from "../../../redux/ducks/team.duck";
import { setLoader } from "../../../redux/ducks/load.duck";
import {
  getTeams,
  defaultTeam,
  getTeam,
  deleteTeam,
} from "../../crud/api/teams.crud";
import CustomTableFooter from "../../utils/CustomTableFooter";
import { setAlert } from "../../../redux/ducks/alert.duck";

const Teams = ({
  teams,
  teamActions,
  settingLoader,
  school,
  staff_member_admin,
  is_admin,
  handleAlert,
}) => {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const [dialog, setDialog] = useState("close");
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (school && school !== null) {
      if (query && query.length > 1)
        getTeams(teamActions.setTeams, school.slug, 1, query);
      else getTeams(teamActions.setTeams, school.slug, 1);
    }
  }, [query, school, teamActions.setTeams]);

  const handleDeleteTeam = (id) => {
    handleAlert({
      title: "Delete Team",
      message: "Are you sure to permanently delete this Team?",
      btn_msg: "Delete Team",
      action: () =>
        deleteTeam(id).then(() => {
          // handleAlert()
          getTeams(teamActions.setTeams, school.slug, 1);
        }),
    });
  };

  const columns = [
    {
      name: "name",
      label: "Team name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "staff_member",
      label: "Staff",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value &&
          value.user && (
            <>
              {value.user.first_name} {value.user.last_name}
            </>
          ),
      },
    },
    // {
    //   name: 'description',
    //   label: 'Description',
    //   options: {
    //     filter: false,
    //     sort: false,
    //   }
    // },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => (
          <div className="d-flex">
            <Tooltip title="Edit Team" placement="top">
              <a
                id={`edit_team_btn_${value}`}
                className="btn btn-icon btn-sm btn-light btn-hover-primary "
                onClick={() => openDialog("editing", value)}
              >
                {staff_member_admin || is_admin ? (
                  <i className="fas fa-edit text-primary" />
                ) : (
                  <Visibility />
                )}
              </a>
            </Tooltip>
            {(staff_member_admin || is_admin) && (
              <Tooltip title="Delete Team" placement="top">
                <a
                  id={`delete_team_btn_${value}`}
                  className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                  onClick={() => handleDeleteTeam(value)}
                >
                  <Delete />
                </a>
              </Tooltip>
            )}
          </div>
        ),
      },
    },
  ];

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: teams.rowsPerPage,
    page: teams.page,
    count: teams.count,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    rowHover: false,
    selectableRows: "none",
    tableBodyMaxHeight: isDesktop ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data",
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "teams.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayRowsOnly: true,
      },
    },
    customFooter: (count, page, rowsPerPage) => {
      settingLoader(true);
      return (
        <CustomTableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={(mPage) =>
            getTeams(teamActions.setTeams, school.slug, mPage, query)
          }
        />
      );
    },
  };

  const openDialog = (mode, id = null) => {
    switch (mode) {
      case "create":
        settingLoader(true);
        teamActions.setTeam(defaultTeam);
        setDialog(mode);
        break;
      case "editing":
        settingLoader(true);
        getTeam(teamActions.setTeam, id).then(() => setDialog(mode));
        break;
      case "close":
        settingLoader(true);
        setDialog(mode);
        setTimeout(() => {
          teamActions.setTeam(defaultTeam);
        }, 500);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  };

  return (
    <div className="table-container">
      <div className="pb-4 d-flex justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} />
        {(staff_member_admin || is_admin) && (
          <Button
            id="create_team_btn"
            variant="contained"
            color="secondary"
            onClick={() => openDialog("create")}
          >
            <Add /> New Team
          </Button>
        )}
      </div>
      <MuiThemeProvider theme={actionTableMuiTheme()}>
        <MUIDataTable
          // title="Teams"
          columns={columns}
          data={teams.data}
          options={options}
        />
      </MuiThemeProvider>
      <TeamsDialog mode={dialog} setDialog={openDialog} />
    </div>
  );
};

const mapStateToProps = (store) => ({
  teams: store.team.teams,
  school: store.school.selected_school,
  is_admin: store.auth.user?.is_admin,
  staff_member_admin: store.auth.user?.staff_member_admin,
});

const mapDispatchToProps = (dispatch) => ({
  teamActions: bindActionCreators(teamDUCK.actions, dispatch),
  settingLoader: bindActionCreators(setLoader, dispatch),
  handleAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
