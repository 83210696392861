import { SCHOOL_URL } from "../helpers/routes"
import { getMultipartParams, handleResponse } from "../helpers/validate"
import notice from "../../utils/notice"

export const defaultGoal = {
  name: ""
}

export const getGoals = (setterFunction, slug, page = 1) => {
  if (slug) {
    return fetch(`${SCHOOL_URL}/${slug}/students/goals?page=${page}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => {
      let newItems = json.items.map(gl => ({...gl, student_goals: {
        life: gl.student_goals.map(goal => goal.goal.student_zone.name === "Life" ? goal.goal : undefined).filter(el => el),

        body: gl.student_goals.map(goal => goal.goal.student_zone.name === "Body" ? goal.goal : undefined).filter(el => el),

        mind: gl.student_goals.map(goal => goal.goal.student_zone.name === "Mind" ? goal.goal : undefined).filter(el => el),
      }}))
      return setterFunction({...json, items: newItems})
    })
    
    .catch(e => {
      notice("Error on loading students")
      console.error(e)
      throw e
    })
  }
}

export const getGoalAnalytics = (setterFunction, slug) => {
  return fetch(`${SCHOOL_URL}/${slug}/goal_analytics`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error on loading students")
      console.error(e)
      throw e
  })
}