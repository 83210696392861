import notice from "../../utils/notice"
import { APPOINTMENT_URL, EVENT_URL, STAFF_MEMBERS_URL } from "../helpers/routes"
import { getMultipartParams, getParams, handleResponse } from "../helpers/validate"

export const defaultAppointment = {
  name: "",
  starts_at: null,
  ends_at: null,
  staff_member: null,
  student: null,
  notes: "",
  tag: "",
  location: "",
}

export const getAppointments = (setterFunction, id, start_time, end_time) => {
  return fetch(`${STAFF_MEMBERS_URL}/${id}/appointments?filter_from=${start_time}&filter_to=${end_time}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => {
    let events = json.map(ev => ({
      id: ev.id,
      title: ev.name,
      start: ev.starts_at,
      end: ev.ends_at,
      backgroundColor: new Date() > new Date(ev.ends_at) ? `${ev.color}b3` : ev.color
    }))
    return setterFunction(events)
  })
  .catch(e => {
    notice("Error fetching the appointments")
    console.log(e.errors)
    return e
  })
}

export const getAppointment = (setterFunction, id) => {
  return fetch(`${APPOINTMENT_URL}/show?id=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error fetching the appointment")
    console.log(e.errors)
    return e
  })
}

export const saveAppointment = (setterFunction, data) => {
  const jsonData = JSON.stringify(data)

  return fetch(APPOINTMENT_URL, getParams('POST', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Appointment created", "success")
    let newAppoint = {
      id: json.id,
      title: json.name,
      start: json.starts_at,
      end: json.ends_at,
      backgroundColor: json.color ? json.color : "#C471A3",
    }
    return setterFunction(newAppoint)
  })
  .catch(e => {
    notice("Error saving appointment")
    console.log(e.errors)
    return e
  })
}

export const updateAppointment = (setterFunction, data, id) => {
  const jsonData = JSON.stringify(data)

  return fetch(`${APPOINTMENT_URL}/update?id=${id}`, getParams('PUT', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Appointment updated", "success")
    let newAppoint = {
      id: json.id,
      title: json.name,
      start: json.starts_at,
      end: json.ends_at,
      backgroundColor: json.color ? json.color : "#C471A3",
    }
    return setterFunction(newAppoint)
  })
  .catch(e => {
    notice("Error saving appointment")
    console.log(e.errors)
    return e
  })
}

export const deleteAppointment = (setterFunction, id) => {
  return fetch(`${APPOINTMENT_URL}/delete?id=${id}`, getMultipartParams('DELETE'))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Appointment deleted", "success")
    setterFunction(json)
  })
  .catch()
}

export const updateEventAction = (id, action) => {
  return fetch(`${APPOINTMENT_URL}/${id}/${action}`, getMultipartParams('POST'))
  .then(res => handleResponse(res))
  .then(json => {
    notice(`Appointment is ${action}ed`, "success")
    return json
  })
  .catch(e => {
    notice("Error updating action")
    console.log(e.errors)
    return e
  })
}

export const saveEvent = (setterFunction, data) => {
  const jsonData = JSON.stringify(data)

  return fetch(EVENT_URL, getParams('POST', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Event created", "success")
    return setterFunction(json)
  })
  .catch(e => {
    notice("Error saving event")
    console.log(e.errors)
    return e
  })
}