/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, Link } from "@material-ui/core";
import { Publish } from "@material-ui/icons";
import { useDropzone } from "react-dropzone";
import "dropzone/dist/min/dropzone.min.css";
import "react-dropzone-component/styles/filepicker.css";

const SimpleFileButton = ({
  onChange,
  name,
  value,
  accept = "*",
  message = "Upload file",
  containerClass = "",
}) => {
  const { getInputProps } = useDropzone({
    accept,
    multiple: false,
    onDrop: (acceptedFiles, files) =>
      onChange({
        target: { name, value: acceptedFiles[0] ?? files[0]?.file ?? null },
      }),
  });

  const deleteFile = () => onChange({ target: { name, value: null } });

  return (
    <section
      id="kt_dropzone_1"
      className={`d-flex flex-column align-items-center ${containerClass}`}
    >
      <Button
        id={`upload_file_${name}_btn`}
        className="mb-2 mb-sm-0"
        variant="contained"
        color="secondary"
        startIcon={<Publish />}
      >
        <input
          id="files_drop_zone"
          {...getInputProps()}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
          }}
        />
        {message}
      </Button>
      {typeof value === "string" && (
        <Link
          className="ml-0 ml-sm-2 text-muted text-truncate"
          style={{ maxWidth: 300 }}
          onClick={deleteFile}
        >
          {value}
        </Link>
      )}
      {value?.name && (
        <Link className="ml-0 ml-sm-2 text-muted" onClick={deleteFile}>
          {value?.name}
        </Link>
      )}
    </section>
  );
};

export default SimpleFileButton;
