import React, { useState } from 'react'
import { Dialog, DialogContent, DialogActions, Grid, TextField, Button, Typography, Switch, DialogTitle } from '@material-ui/core'
import { Formik } from 'formik'
import clsx from 'clsx'

import { DropNCrop } from "../../../utils/DropNCrop"

const AdminDialog = ({ mode, setDialog }) => {
  const open = mode === 'editing' || mode === 'create'
  const title = mode === 'editing' ? "Edit Admin" : "New Admin"
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  }

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  }

  return (
    <Dialog
      open={open}
      onClose={() => setDialog("close")}
      >
        <Formik
          initialValues={{
            status: false,
            first_name: "",
            last_name: "",
            nick_name: "",
            email: "",
            image: null
          }}
          validate={values => {
            const errors = {}
            if (!values.first_name) errors.first_name = 'Required field'
            if (!values.last_name) errors.last_name = 'Required field'
            if (!values.nick_name) errors.nick_name = 'Required field'
            if (!values.email) errors.email = 'Required field'

            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading()
            setTimeout(() => {
              setDialog("closed")
              setSubmitting(false)
              disableLoading()
            }, 1000)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
            <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
              <DialogTitle disableTypography className="dialog-title">
                <Typography variant="h6">
                  {title}
                </Typography>
                <Switch
                  name="status"
                  checked={values.status}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2} className="form-group">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      label="First Name"
                      margin="normal"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.first_name && errors.first_name}
                      error={Boolean(touched.first_name && errors.first_name)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      label="Last Name"
                      margin="normal"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.last_name && errors.last_name}
                      error={Boolean(touched.last_name && errors.last_name)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      label="Nick Name"
                      margin="normal"
                      name="nick_name"
                      value={values.nick_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.nick_name && errors.nick_name}
                      error={Boolean(touched.nick_name && errors.nick_name)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      label="Email"
                      margin="normal"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DropNCrop setterFunction={setFieldValue} file={values.image} />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialog("close")} color="secondary">
                  Cancel
                </Button>
                <button
                  onClick={() => setDialog("close")}
                  style={loadingButtonStyle}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading})}`}>
                  {mode === "editing" ? "Submit" : "Create"}
                </button>
              </DialogActions>
            </form>
          )}
        </Formik>
    </Dialog>
  )
}

export default AdminDialog
