import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";

export const actionType = {
  SetData: '[DASHBOARD] SET_DATA',
  SetDashboardState: '[DASHBOARD] SET_DASHBOARD_STATE',
  SetDashboardQueries: '[DASHBOARD] SET_DASHBOARD_QUERIES',
}

const initialState = {
  data: {
    select_data: {
      teams: [],
      months: []
    },
    dashboard_data: {
      mood: {
        labels: [],
        values: [],
        color: []
      },
      telehelp_counter: null,
      telehelp_sent_counter: null,
      contributing_factors: [],
      focus_areas: [],
      university_hub: null,
      library: null,
      daily_launches_count: {
        value: null,
      },
      active_students_count: {
        value: null,
      },
    }
  },
  selected_team: undefined,
  selected_month: { date_value: "", str_value: "" },
  selected_week: {
    str_value: "",
    date_from: "",
    date_to: "",
  },
  queries: {
    date_from: null,
    date_to: null,
    team: null,
    month: null,
    mood: null,
  }
};

export const reducer = persistReducer(
  { storage, key: 'dashboard', withlist: [], blacklist: ['queries'] },
  (state = initialState, action) => {
    switch (action.type) {

      case actionType.SetData:
        return { ...state, data: action.payload };

      case actionType.SetDashboardState:
        return { ...state, [action.payload.key]: action.payload.value };

      case actionType.SetDashboardQueries:
        return { ...state, queries: action.payload };

      default: return state;
    }
  }
)

export const actions = {
  setData: data => ({ type: actionType.SetData, payload: data }),
  setDashboardState: ({ key, value }) => ({ type: actionType.SetDashboardState, payload: { key, value } }),
  setDashboardQueries: (queries) => ({ type: actionType.SetDashboardQueries, payload: queries }),
}