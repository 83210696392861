import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setLoader } from '../../../../../redux/ducks/load.duck';
import * as schoolDUCK from "../../../../../redux/ducks/school.duck"
import { searchSchools } from '../../../../../app/crud/api/schools.crud';
import { NavigateNext } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: "white"
  },
  clearIndicator: {
    color: "white"
  },
  popupIndicator: {
    color: "white"
  },
  notchedOutline: {
    borderColor: "#ffffff !important",
    
  },
}))

const SchoolSelector = ({ setLoad, school, setSelectedSchool }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("")
  const loading = open && options.length === 0;

  useEffect(() => {

    if (open) searchSchools(search, setOptions)

  }, [open, search])

  useEffect(() => {
    if (!open) setOptions([])

  }, [open])

  

  return (
    <Autocomplete
      fullWidth
      classes={{ clearIndicator: classes.clearIndicator, inputRoot: classes.inputRoot, popupIndicator: classes.popupIndicator }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      loading={loading}
      value={school}
      inputValue={search}
      onChange={(e, value) => {setSelectedSchool(value);setLoad(true);}}
      onInputChange={(e, newInputValue) => setSearch(newInputValue)}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option && option.name ? option.name : ""}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            classes: {
              notchedOutline: classes.notchedOutline,
            },
            endAdornment: (
              <>
                {loading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

const mapStateToProps = store => {
  return ({
    school: store.school.selected_school,
  })
}

const mapDispatchToProps = dispatch => ({
  setLoad: bindActionCreators(setLoader, dispatch),
  setSelectedSchool: bindActionCreators(schoolDUCK.actions.setSelectedSchool, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SchoolSelector)
