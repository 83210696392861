import React from 'react';
import { connect } from 'react-redux';
import DashboardTopList from './DashboardTopList';

const DashboardFocusAreasListTop3 = ({ focus_areas=[] }) => (
    <DashboardTopList
        allSelected
        data={focus_areas.slice(0, 3 > focus_areas.length ? focus_areas.length : 3)}
        textKey="name"
        percentKey="value"
    />
);

const mapStateToProps = store => ({
    focus_areas: store.dashboard.data.dashboard_data.focus_areas,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFocusAreasListTop3);