import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { FileDropInput } from "./FileDropInput";
import { Autocomplete, Alert } from "@material-ui/lab";
import { Formik } from "formik";

import { SCHOOL_URL } from "../crud/helpers/routes";
import { getMultipartParams, handleResponse } from "../crud/helpers/validate";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: ({ isDesktop }) => (!isDesktop ? "70%" : "50%"),
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
    maxWidth: 500,
  },
  progress: {
    marginLeft: 10,
  },
}));

export const BulkModal = ({
  showSelectTeam = false,
  urlDownloadTemplate,
  errorsBulk,
  open,
  setOpen,
  bulk,
  setBulk,
  school,
  loading,
  steps,
  setSteps,
  onFinish,
  setLoading,
  validateBulk,
  bulkRequest = () => {},
}) => {
  const [teams, setTeams] = useState([]);

  const isDesktop = useMediaQuery("(min-width: 600px)");
  const classes = useStyles({ isDesktop });
  const getTeams = (setterFunction, slug) => {
    return fetch(
      `${SCHOOL_URL}/${slug}/teams?per_page=100`,
      getMultipartParams("GET")
    )
      .then((res) => handleResponse(res))
      .then((json) => setterFunction(json.items))
      .catch((e) => {
        console.error(e.errors);
        return e;
      });
  };

  useEffect(() => {
    setBulk(null);
    setSteps(1);
    if (school && school !== null && school.slug && school.slug !== null)
      getTeams(setTeams, school.slug);
  }, [school]);

  return (
    <Dialog
      PaperProps={{
        className: classes.paper,
      }}
      open={open}
      id="bulk_dialog"
      onClose={() => setOpen(false)}
    >
      <Formik
        id="fileuoload"
        initialValues={{
          team_id: null,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.team_id) errors.team_id = "Required field";
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          setOpen(false);
          bulkRequest(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <>
            <DialogTitle className="pb-1">Upload CSV File</DialogTitle>
            <DialogContent className="py-0">
              <div className="mb-4 d-flex flex-column">
                <a
                  href={urlDownloadTemplate}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-5"
                >
                  Download CSV Template{" "}
                  <span class="material-icons">cloud_download</span>
                </a>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontWeight: "1000" }}>Note</span>
                  <span style={{ color: "#6B6B6B" }}>
                    <strong>Important:</strong> Ensure the order of the columns
                    in the template is <strong>not</strong> altered.{" "}
                    <strong>
                      If you are uploading your own csv the order is first name,
                      last name, email and phone number.
                    </strong>
                  </span>
                </div>
              </div>
              <br />
              {showSelectTeam && (
                <Autocomplete
                  selectedAutocompleteValues={values.team_id}
                  id="bulk_team_id"
                  className="mb-5"
                  options={teams}
                  defaultValue={[]}
                  setBulk
                  getOptionLabel={(option) => option.name}
                  onChange={(event, val) => {
                    const teamId = val?.id;
                    setFieldValue("team_id", teamId);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Teams"
                    />
                  )}
                />
              )}
              <FileDropInput
                setterFunction={setBulk}
                value={bulk}
                type=".csv"
                message="Add CSV file"
              />
            </DialogContent>
            <DialogActions>
              <Button
                id="cancel_bulk_btn_dialog"
                type="reset"
                onClick={() => setOpen(false)}
                color="secondary"
              >
                Cancel
              </Button>

              <button
                type="submit"
                id="submit_validate_bulk_btn_dialog"
                disabled={
                  !bulk || !school?.slug || (showSelectTeam && !values.team_id)
                }
                onClick={validateBulk}
                className="btn btn-primary btn-elevate kt-login__btn-primary d-flex ml-3"
              >
                Validate File
              </button>
            </DialogActions>

            {steps >= 2 && (
              <Alert severity="info">
                We are validating your CSV File{" "}
                {loading && steps === 2 && (
                  <CircularProgress
                    color="inherit"
                    size={18}
                    className={classes.progress}
                  />
                )}
              </Alert>
            )}
            <br />
            {steps == 2.1 && (
              <Alert severity="error">
                Error. file could not be uploaded to s3, please click validate
                file again
              </Alert>
            )}

            {steps == 2.2 && (
              <Alert severity="error">
                <ul>
                  {errorsBulk.map((error) => (
                    <li style={{ listStyleType: "disc" }}>{error}</li>
                  ))}
                  <li> please validate file again</li>
                </ul>
              </Alert>
            )}

            {steps >= 3 && (
              <>
                <Alert severity="success">
                  File validated. Press submit to finish
                </Alert>
                <DialogActions>
                  <button
                    type="submit"
                    id="submit_bulk_btn_dialog"
                    onClick={showSelectTeam ? handleSubmit : bulkRequest}
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex ml-3"
                  >
                    Submit
                  </button>
                </DialogActions>
              </>
            )}

            <br />
          </>
        )}
      </Formik>
    </Dialog>
  );
};
