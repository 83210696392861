import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import { defaultSubfactor } from '../../app/crud/api/subfactors.crud';

export const actionType = {
  GetSubfactors: '[SUBFACTOR] GET_SUBFACTORs',
  GetSubfactor: '[SUBFACTOR] GET_SUBFACTOR',
  UpdateSubfactor: '[SUBFACTOR] UPDATE_SUBFACTOR',
}

const initialState = {
    subfactors: [],
    subfactor: defaultSubfactor,
    selected_subfacto: null
}

export const reducer = persistReducer(
  { storage, key: 'subfactors', withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetSubfactors: {
        return { 
            ...state, 
            subfactors: { 
              data: action.payload.items, 
              count: action.payload.total, 
              page: action.payload.page, 
              pages: action.payload.pages, 
              rowsPerPage: action.payload.per_page, 
              isFetched: true
            } 
          }
      }
      
      case actionType.GetSubfactor: {
        const data =  { ...state, subfactor: {...action.payload} }
    
        return data
      }

      case actionType.UpdateSubfactor: {
        
        let newData = state.subfactors.data
        const index = state.subfactors.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.subfactors.data]
        } else {
          newData = state.subfactors.data
          newData[index] = action.payload
        }
 
        return { ...state, subfactors: { ...state.subfactors, data: newData } }
      }

      default: return state

    }
  }
)

export const actions = {
  setSubfactors: data => ({ type: actionType.GetSubfactors, payload: data }),
  setSubfactor: data => ({ type: actionType.GetSubfactor, payload: data }),
  updateSubfactor: data => ({ type: actionType.UpdateSubfactor, payload: data }),
}