import React, { Component } from 'react'

import School from './School'

class SchoolWrapper extends Component {
  
  render() {
    return (
      <div className="table-container">
       <School /> 
      </div>
    )
  }
}

export default SchoolWrapper