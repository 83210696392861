import { getParams, handleResponse } from "../helpers/validate";
import { SCHOOL_URL_V2 } from "../helpers/routes";
import notice from "../../utils/notice";

export const saveNotification = (data, slug) => {
  
  const jsonData = JSON.stringify(data);
  return fetch(
   `${SCHOOL_URL_V2}/${slug}/push_notification`,
    getParams("POST", jsonData)
  )
    .then((res) => handleResponse(res))
    .then((json) => notice("Notification created", "success"))
    .catch((e) => {
      notice("Error saving the notification");
      console.error(e.errors);
      throw e;
    });
};
