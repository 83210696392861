/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Button, Card, CardActions, CardContent, CardHeader, Tooltip, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import FilterDataTable from '../../../utils/FilterDataTable';
import FeelingsDialog from './FeelingsDialog';

import { getFeelings, defaultFeeling, getFeeling } from '../../../crud/api/feelings.crud';
import * as feelingsDUCK from "../../../../redux/ducks/feelings.duck"
import { setLoader } from '../../../../redux/ducks/load.duck';

const Feelings = ({ feelings, school, feelActions, settingLoader }) => {
  const [dialog, setDialog] = useState("close")
  const [query, setQuery] = useState("")

  useEffect(() => {
    if (school && school !== null) {
      if (query && query.length > 1) getFeelings(feelActions.setFeelings, school.slug, 1, query)
      
      else getFeelings(feelActions.setFeelings, school.slug, 1)
    }
  }, [feelActions.setFeelings, query, school])

  const openDialog = (mode, id = null) => {
    switch(mode){
        case "create":
          settingLoader(true)
          setDialog(mode)
          feelActions.setFeeling(defaultFeeling)
          break;
        case "editing":
          settingLoader(true)
          getFeeling(feelActions.setFeeling, id)
          .then(() => setDialog(mode))
          .catch(() => {})
          break;
        case "close":
          setDialog(mode)
          setTimeout(() => {
            feelActions.setFeeling(defaultFeeling)
          }, 500);
          break;
        default:
          console.error("Invalid dialog mode")
    }        
  }

  return (
    <div className="table-container">
      <div className="pb-4 d-flex justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} />
        <Button id="create_feeling_btn" variant="contained" color="secondary" onClick={() => openDialog("create")} startIcon={<Add />}>New mood</Button>
      </div>
      <div className="grid-wrapper">
        {feelings.data.map(feel => {
          let src

          if (feel.content?.content_type) {
            if (feel.content.content_type === "audio") src = `${toAbsoluteUrl("/media/assets/audio-icon.png")}`
            if (feel.content.content_type === "html") src = `${toAbsoluteUrl("/media/assets/html5-icon.png")}`
            if (feel.content.content_type === "link") src = `${toAbsoluteUrl("/media/assets/link-icon.png")}`
            if (feel.content.content_type === "video") src = `${toAbsoluteUrl("/media/assets/video-icon.png")}`
            if (feel.content.content_type === "pdf") src = `${toAbsoluteUrl("/media/assets/pdf-icon.png")}`
            if (feel.content.content_type === "document") src = `${toAbsoluteUrl("/media/assets/docx-icon.png")}`
          }

          return (
          <Card key={feel.id} id={`emotion_card_${feel.id}`} className="d-flex flex-column justify-content-between">
            <CardHeader
              className="pb-0"
              avatar={feel.icon}
              title={<span className="font-weight-bolder">
                {feel.name}
                </span>}
            />
            <CardContent className="d-flex flex-column justify-content-between h-100">
              <Typography variant="body2">
                {feel.definition}
              </Typography>
              {feel.content?.content_type && (
                <p className="font-weight-bold">
                  {feel.content.name}
                  <img className="ml-3" src={src} alt={feel.content.content_type} width="15px" />
                </p>
              )}
            </CardContent>
            <CardActions>
              <Tooltip title="Edit Mood" placement="top">
                <a id={`edit_emotion_btn_${feel.id}_card`} className="btn btn-icon btn-sm btn-light btn-hover-primary" onClick={() => openDialog('editing', feel.id)}>
                  <i className="fas fa-edit text-primary" />
                </a>
              </Tooltip>
            </CardActions>
          </Card>
        )})}
      </div>
      <FeelingsDialog mode={dialog} setDialog={openDialog} />
    </div>
  )
}

const mapStateToProps = store => ({
  school: store.school.selected_school,
  feelings: store.feel.feelings,
})

const mapDispatchToProps = (dispatch) => ({
  feelActions: bindActionCreators(feelingsDUCK.actions, dispatch),
  settingLoader: bindActionCreators(setLoader, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Feelings)
