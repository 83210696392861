import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DashboardTitle from "./DashboardTitle";
import { makeStyles, IconButton } from "@material-ui/core";
import * as dashboardDUCK from "../../../../redux/ducks/dashboard.duck";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const useStyles = makeStyles((theme) => ({
  container: {
    "&>*": {
      marginTop: "auto",
      marginBottom: "auto",
    },
    display: "flex",
  },
  icon: {
    color: "#C1CAD9",
  },
}));

const DashboardTextSlider = ({
  weeks,
  selected_week,
  dashboard_actions,
  queries,
  selected_month,
}) => {
  const classes = useStyles();

  const handleWeekChange = (action) => {
    console.log(selected_week, "-", weeks[4]);
    console.log("action", action);
    let currentIndex;
    currentIndex = weeks.findIndex(
      ({ str_value, date_from, date_to }) =>
        str_value === selected_week.str_value
    );
    console.log("current", currentIndex);
    if (currentIndex === -1) return;
    currentIndex = action === "next" ? currentIndex + 1 : currentIndex - 1;
    if (currentIndex === weeks.length) currentIndex = 0;
    else if (currentIndex === -1) currentIndex = weeks.length - 1;
    const newSelectedWeek = weeks[currentIndex];
    dashboard_actions.setDashboardState({
      key: "selected_week",
      value: newSelectedWeek,
    });
    const newQueries = {
      ...queries,
      date_from: newSelectedWeek.date_from,
      date_to: newSelectedWeek.date_to,
      month: selected_month?.date_value,
    };
    dashboard_actions.setDashboardQueries(newQueries);
  };
  return (
    <span className={classes.container}>
      <IconButton onClick={() => handleWeekChange("previous")}>
        <ArrowBackIosIcon className={classes.icon} />
      </IconButton>
      <span className="pl-5 pr-5">
        <DashboardTitle
          text={selected_week?.str_value}
          variant="dark.white"
          fontSize={20}
          fontWeight={500}
          whiteSpace="nowrap"
        />
      </span>
      <IconButton onClick={() => handleWeekChange("next")}>
        <ArrowForwardIosIcon className={classes.icon} />
      </IconButton>
    </span>
  );
};

const mapStateToProps = (store) => ({
  weeks: store.dashboard.data.select_data.weeks,
  selected_week: store.dashboard.data.select_data.selected_week,
  selected_month: store.dashboard.data?.select_data.selected_month,
  queries: store.dashboard.queries,
});

const mapDispatchToProps = (dispatch) => ({
  dashboard_actions: bindActionCreators(dashboardDUCK.actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardTextSlider);
