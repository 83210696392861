import React from "react";
import { IconButton } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

const CustomTableFooter = ({
  page = 0,
  rowsPerPage = 0,
  count = 0,
  onChangePage,
}) => {
  return (
    <div className="d-flex justify-content-end px-8 py-4 border-top">
      <div className="d-flex justify-content-between align-items-center">
        <p className="m-0">
          {count > 0 ? rowsPerPage * page - rowsPerPage + 1 : 0} -{" "}
          {rowsPerPage * page > count ? count : rowsPerPage * page} of {count}
        </p>
        <IconButton
          disabled={page === 1}
          size="small"
          className="mx-6"
          onClick={() => onChangePage(page - 1)}
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          disabled={Math.ceil(count / rowsPerPage) === page}
          size="small"
          onClick={() => onChangePage(page + 1)}
        >
          <ChevronRight />
        </IconButton>
      </div>
    </div>
  );
};

export default CustomTableFooter;
