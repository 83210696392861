import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultAnnouncement } from "../../app/crud/api/annc.crud";

export const actionType = {
  GetAnncs: "[ANNC] GET_ANNCS",
  GetAnnc: "[ANNC] GET_ANNC",
  UpdateAnnc: "[ANNC] UPDATE_ANNC",
  DeleteAnnc: "[ANNC] DELETE_ANNC",
  LoadAnncCsv: "[ANNC] LOAD_ANNC_CSV",
  SelectAnnc: "[ANNC] SELECT_ANNC",
};

const initialState = {
  anncs: { data: [], total: 0, page: 0, rowsPerPage: 0, isFetched: false },
  annc: defaultAnnouncement,
  selected_annc: null,
};

export const reducer = persistReducer(
  { storage, key: "annc", withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetAnncs: {
        return {
          ...state,
          anncs: {
            data: action.payload.items,
            count: action.payload.total,
            page: action.payload.page,
            pages: action.payload.pages,
            rowsPerPage: action.payload.per_page,
            isFetched: true,
          },
        };
      }

      case actionType.UpdateAnnc: {
        let newData = state.anncs.data;
        const index = state.anncs.data.findIndex(
          (x) => x.id === action.payload.id
        );

        if (index === -1) {
          newData = [action.payload, ...state.anncs.data];
        } else {
          newData = state.anncs.data;
          newData[index] = action.payload;
        }

        return { ...state, anncs: { ...state.anncs, data: newData } };
      }

      case actionType.DeleteAnnc: {
        let newData = state.anncs.data;
        const index = state.anncs.data.findIndex(
          (x) => x.id === action.payload.id
        );

        if (index === -1) {
          newData = [action.payload, ...state.anncs.data];
        } else {
          newData = state.anncs.data;
          newData.splice(index, 1);
        }

        return { ...state, anncs: { ...state.anncs, data: newData } };
      }

      case actionType.GetAnnc: {
        return { ...state, annc: action.payload };
      }

      case actionType.LoadAnncCsv: {
        let newData = action.payload;

        return {
          ...state,
          anncs: { ...state.anncs, data: [...state.anncs.data, ...newData] },
        };
      }

      case actionType.SelectAnnc: {
        return { ...state, selected_annc: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setAnncs: (data) => ({ type: actionType.GetAnncs, payload: data }),
  setAnnc: (data) => ({ type: actionType.GetAnnc, payload: data }),
  updateAnncs: (data) => ({ type: actionType.UpdateAnnc, payload: data }),
  deleteAnncs: (data) => ({ type: actionType.DeleteAnnc, payload: data }),
  loadAnncCsv: (data) => ({ type: actionType.LoadAnncCsv, payload: data }),
  setSelectedAnnc: (state) => ({ type: actionType.SelectAnnc, payload: state }),
};
