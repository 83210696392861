import React, { useState } from 'react'
import { Dialog, Switch, DialogContent, Grid, TextField, DialogActions, Button, DialogTitle, Typography, CircularProgress } from '@material-ui/core'
import { Formik } from 'formik'

import * as conferenceDUCK from "../../../../redux/ducks/conference.duck"
import { bindActionCreators } from 'redux'
import { setLoader } from '../../../../redux/ducks/load.duck'
import { connect } from 'react-redux'
import { saveConference, updateConference } from '../../../crud/api/conference.crud'

const ConferenceDialog = ({ mode, setDialog, conference, conferenceActions, settingLoader }) => {
  const open = mode === 'editing' || mode === 'create'
  const title = mode === 'editing' ? "Edit Conference" : "New Conference"
  const [loading, setLoading] = useState(false);

  const create = async values => {
    try {
      await saveConference(conferenceActions.updateConferences, values)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  
  const update = async values => {
    try {
      await updateConference(conferenceActions.updateConferences, values, conference.id)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  
  return (
    <Dialog open={open} onClose={() => setDialog("close")} id="conferende_dialog">
      <Formik
        initialValues={{
          ...conference,
        }}
        validate={values => {
          const errors = {}
          if (!values.name) errors.name = 'Required field'

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true)
          settingLoader(true)
          setTimeout(() => {
            conference.id ? update(values) : create(values)
            setSubmitting(false)
          }, 1000)
        }}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
        <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
          <DialogTitle disableTypography className="dialog-title">
            <Typography variant="h6">
              {title}
            </Typography>
            <Switch
              name="active"
              id="conference_active_input_dialog"
              checked={values.active}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  required
                  fullWidth
                  id="conference_name_input_dialog"
                  variant="outlined"
                  label="Conference"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  id="conference_color_input_dialog"
                  variant="outlined"
                  label="Color"
                  name="color"
                  type="color"
                  value={values.color}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rowsMax="4"
                  id="conference_description_input_dialog"
                  variant="outlined"
                  label="Description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="cancel_conference_btn_dialog" onClick={() => setDialog("close")} color="secondary">
              Cancel
            </Button>
            <button
              type="submit"
              id="submit_conference_btn_dialog"
              disabled={isSubmitting || !values.name}
              className="btn btn-primary btn-elevate kt-login__btn-primary d-flex">
              {mode === "editing" ? "Submit" : "Create"}
              {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
            </button>
          </DialogActions>
        </form>
      )}
      </Formik>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  conference: store.conf.conference,
})

const mapDispatchToProps = (dispatch) => ({
  conferenceActions: bindActionCreators(conferenceDUCK.actions, dispatch),
  settingLoader: bindActionCreators(setLoader, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceDialog)
