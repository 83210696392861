import React, { useEffect, useState } from "react";
import SimpleAutocomplete from "../../Components/SimpleAutocomplete";
import { useSelector } from "react-redux";

const PercentageTable = ({ data, options, value, onChange, label }) => {
  const { user } = useSelector((store) => ({
    user: store.auth.user,
  }));
  const [orderedData, setOrderedData] = useState([]);

  const normalizePercentajes = (arr) => {
    const totalPercentage = arr.reduce(
      (sum, item) => sum + parseFloat(item.percentage),
      0
    );
    if (totalPercentage > 0) {
      if (totalPercentage !== 100) {
        const factor = 100 / totalPercentage;
        arr.forEach((item) => {
          item.percentage =
            (parseFloat(item.percentage) * factor).toFixed(2) + "%";
        });
      }
    }
    return arr;
  };

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    } else {
      let arr = [];
      if (!user.is_admin && value.id === 0) {
        arr = normalizePercentajes(data);
      } else {
        arr = data;
      }
      const newData = arr.sort(
        (a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)
      );
      setOrderedData(newData);
    }
  }, [data]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <h6>Results</h6>
      <div style={{ width: "25%", marginLeft: "auto" }}>
        <SimpleAutocomplete
          id="poll_results_select"
          name="poll_results_select"
          label={label}
          options={options}
          value={value}
          onChange={onChange}
        />
      </div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead style={{ backgroundColor: "#d6f0f7" }}>
          <tr>
            <th style={{ padding: "10px", textAlign: "left" }}>Option</th>
            <th style={{ padding: "10px", textAlign: "left" }}>Percentage</th>
          </tr>
        </thead>
        <tbody>
          {orderedData.map((item, index) => (
            <tr key={index} style={{ backgroundColor: "#FAFAFA" }}>
              <td style={{ padding: "10px" }}>{item.name}</td>
              <td style={{ padding: "10px" }}>{item.percentage}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PercentageTable;
