import React from "react";
import { CancelRounded } from "@material-ui/icons";
import { useDropzone } from "react-dropzone";
import "dropzone/dist/min/dropzone.min.css";
import "react-dropzone-component/styles/filepicker.css";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Grid } from "@material-ui/core";

export const DropFiles = ({
  setterFunction,
  field,
  value,
  type = "",
  message = "Drop files to attach, or click",
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: type,
    multiple: false,
    onDrop: (acceptedFiles) => {
      field
        ? setterFunction(field, acceptedFiles[0])
        : setterFunction(acceptedFiles[0]);
    },
  });

  const deleteFile = () =>
    field ? setterFunction(field, null) : setterFunction(null);

  return (
    <section
      id="kt_dropzone_1"
      className="dropzone dropzone-default needsclick"
    >
      <div className="dropzone-msg dz-message needsclick" {...getRootProps()}>
        <input id="files_drop_zone" {...getInputProps()} />
        <p
          className="dropzone-msg-title"
          style={{ display: value !== null && value ? "none" : "block" }}
        >
          <span className="flaticon-upload-1" />
          {message}
        </p>
      </div>
      {value !== null && value ? (
        <div className="dz-preview">
          <div className="dz-image">
            <img
              width="100%"
              src={toAbsoluteUrl("/media/assets/default-image-file.png")}
              alt="default_file"
            />
          </div>
          <div
            className="dz-remove"
            onClick={(e) => {
              e.preventDefault();
              deleteFile();
            }}
          >
            <CancelRounded
              id="remove_preview_file"
              fontSize="large"
              style={{ width: "2em", height: "2em" }}
            />
          </div>
        </div>
      ) : null}
    </section>
  );
};

export const DropMultipleFiles = ({
  setterFunction,
  field,
  files = [],
  value = "",
  type = "*",
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: type,
    multiple: true,
    onDrop: (acceptedFiles) => {
      
      acceptedFiles.map((file) =>
        Object.assign(file, {
          src: URL.createObjectURL(file),
        })
      );
    
      files.push(acceptedFiles[0]);
      setterFunction(field, files);
      console.log("filess: ", files);
    },
  });

  function deleteFile(i) {
    let newFiles = files;
    newFiles.splice(i, 1);
    setterFunction(field, newFiles);
  }
  function resolveSize(size) {
    if (size === undefined) return "";
    let result = size / 1024;
    if (result < 1024) return Math.ceil(result) + " KB";

    return Math.ceil(result / 1024) + " MB";
  }

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        sm={6}
        className="dropzone dropzone-multi needsclick"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div id="kt_dropzone_1">
          <div
            className="dropzone-msg dz-message needsclick"
            {...getRootProps()}
          >
             <br></br>
            <input id="multi_files_drop_zone" {...getInputProps()} />
            <h3 className="dropzone-msg-title" style={{ fontSize: "15px" }}>
              <span className="flaticon-upload-1" /> Drop files to attach, or
              click
            </h3>
            <br></br>
           
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} style={{maxHeight: "250px",
    overflowY: "scroll"}}>
        {files != null && files.length > 0 ? (
          <div
            id="kt_dropzone_1"
            className="dropzone dropzone-multi needsclick"
          >
            <span>Uploaded files</span>
            {files.map((file, i) => (
              <Grid
                container
                spacing={1}
                style={{
                  height: "auto",
                  borderBottom: "2px solid #EBEDF3",
                  marginBottom: "10px",
                }}
              >
                <Grid item xs={12} sm={6}>
                  <div>
                    {typeof file !== "string" ? (
                      file.type?.includes("image/", 0) ? (
                        <img
                          height="auto"
                          width="100px"
                          src={
                            file !== null ? (file.src ? file.src : file) : ""
                          }
                          alt="image_preview"
                        />
                      ) : (
                        <img
                          height="auto"
                          width="100px"
                          src={toAbsoluteUrl(
                            "media/assets/default-image-file.png"
                          )}
                          alt="default_file"
                        />
                      )
                    ) : (
                      <img
                        height="auto"
                        width="100px"
                        src={file !== null ? (file ? file : file) : ""}
                        alt="image_preview"
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container>
                    <Grid item xs={12} sm={10}>
                      <div className="dz-details">
                        <div className="dz-filename">
                          <span title={file.name}>
                            {file.name?.length > 10
                              ? file.name.substring(0, 10) + " ..."
                              : file.name}
                          </span>
                          <br></br>
                          <span>{resolveSize(file.size)}</span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <div
                        id={`remove_preview_file_${i}`}
                        className="dz-remove"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteFile(i);
                        }}
                      >
                        <CancelRounded />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
};
