import { SCHOOL_URL, STAFF_TYPES_URL } from "../helpers/routes"
import { handleResponse, getParams, getMultipartParams } from "../helpers/validate"
import notice from "../../utils/notice"

export const defaultStaffType = {
  name: "",
  description: "",
  color: "#690d2b",
  icon: "",
  active: true,
}

export const searchStaffType = (setterFunction, slug, query) => {
  if (slug) {
    return fetch(`${SCHOOL_URL}/${slug}/staff_types/suggestions?q=${query}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
      notice("Error on loading sports")
      console.error(e.errors)
    })
  }
}

export const getStaffTypes = (setterFunction, slug, page = 1, query = "") => {
  return fetch(`${SCHOOL_URL}/${slug}/staff_types?page=${page}&search_query=${query}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
      notice("Error on loading staff types")
      console.error(e.errors)
      throw e
    })
}

export const getStaffType = (setterFunction, id) => {
  return fetch(`${STAFF_TYPES_URL}/show?id=${id}`, getMultipartParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
      notice("Error on loading staff types")
      console.error(e.errors)
      throw e
    })
}

export const saveStaffType = (setterFunction, slug, data) => {
  const jsonData = JSON.stringify(data)

  return fetch(`${SCHOOL_URL}/${slug}/staff_types`, getParams('POST', jsonData))
    .then(res => handleResponse(res))
    .then(json => {
      setterFunction({staff_type: json})
      notice("Staff type saved", "success")
    })
    .catch(e => {
      notice("Error saving staff types")
      console.error(e.errors)
      throw e
    })
}

export const updateStaffType = (setterFunction, data, id) => {
  const jsonData = JSON.stringify(data)

  return fetch(`${STAFF_TYPES_URL}/update?id=${id}`, getParams('PUT', jsonData))
    .then(res => handleResponse(res))
    .then(json => {
      setterFunction({staff_type: json})
      notice("Staff type updated", "success")
    })
    .catch(e => {
      notice("Error saving staff types")
      console.error(e.errors)
      throw e
    })
}