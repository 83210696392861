import { SCHOOL_URL } from "../../crud/helpers/routes";
import { getParams, handleResponse } from "../../crud/helpers/validate";
import { hour24tohour12, hour24tohour12GetPMAM } from "../../utils/convertHours";
import notice from "../../utils/notice";

export const columns = [
  {
    name: "id",
    label: "id",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "title",
    label: "Title",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "body",
    label: "Message",
    options: {
      filter: false,
      sort: false,
    },
  },

  {
    name: "send_at",
    label: "Scheduled date and time",
    options: {
      filter: false,
      sort: false,
    },
  },
  

 
];

export const defaulScheduledNotification = {
  title: "",
  body: "",
  send_at: new Date().toISOString(),
  time_at: new Date().toISOString(),
  all_schools: false,
  schools_id: [],
  hour: hour24tohour12(new Date().getHours()),
  minute:"0",
  AMPM: hour24tohour12GetPMAM(new Date().getHours())

};

export const searchSchools = (query = "", setterFunction) => {
  return fetch(`${SCHOOL_URL}/suggestions?q=${query}`, getParams("GET"))
    .then(handleResponse)
    .catch((e) => {
      notice("Error on loading the schools");
      console.error(e.errors);
      throw e;
    });
};
