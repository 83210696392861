import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import FormModal from "./FormModal";
import CustomTable from "../../../utils/CustomTable";
import { columns, defaultTelehelp } from "./utils";
import useTelehelpHook from "./useTelehelpHook";

const TelehelpTopics = () => {
  const [openModal, setOpenModal] = useState(false);
  const [telehelp, setTelehelp] = useState(defaultTelehelp);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const {
    handleGetSingleTelehelp,
    handleDeleteTelehelp,
    isResourceProAthlete,
  } = useTelehelpHook({
    setList,
    page,
    setCount,
    setOpenModal,
    telehelp,
    setTelehelp,
    list,
  });

  return (
    <div className="table-container">
      <div className="d-flex justify-content-end mb-4">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Add />}
          onClick={() => {
            setTelehelp(defaultTelehelp);
            setOpenModal(true);
          }}
        >
          New
        </Button>
      </div>
      <CustomTable
        title="Staff Support topics"
        data={list}
        columns={columns(
          handleGetSingleTelehelp,
          handleDeleteTelehelp,
          isResourceProAthlete
        )}
        page={page}
        count={count}
        setPage={setPage}
      />
      <FormModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        telehelp={telehelp}
        setTelehelp={setTelehelp}
        setList={setList}
        page={page}
        setCount={setCount}
      />
    </div>
  );
};

export default TelehelpTopics;
