import React from 'react'
import { TextField } from '@material-ui/core'
import InputMask from 'react-input-mask';

const InputMaskCustom = props => {
  const { inputRef, value, onChange } = props;
    return (
      <InputMask
        {...props}
        type="tel"
        inputRef={inputRef}
        mask="+19999999999"
        variant="outlined"
        name="phone_number"
        value={value}
        onChange={onChange}
      />
    )
}

const MaskedPhoneInput = ({value, handleChange, disabled, error,helperText,required}) => {
  
  return (
    <TextField
    required={required}
      disabled={disabled}
      fullWidth
      helperText={helperText}
      id="phone_number_input"
      label="Phone"
      variant="outlined"
      name="phone_number"
      value={value}
      error={error}
      onChange={handleChange}
      InputProps={{
        inputComponent: InputMaskCustom}}
    />
   
  )
}

export default MaskedPhoneInput
