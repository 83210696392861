import React, { useState } from 'react'
import { Dialog, DialogContent, Grid, TextField, DialogActions, Button, DialogTitle, CircularProgress } from '@material-ui/core'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik'

import { setLoader } from '../../../../redux/ducks/load.duck';
import * as staffTypeDUCK from "../../../../redux/ducks/staff_types.duck"
import { saveStaffType, updateStaffType } from '../../../crud/api/staff_type.crud';

const StaffDialog = ({ mode, setDialog, staff_type, school, staffTActions, settingLoader }) => {
  const open = mode === 'editing' || mode === 'create'
  const title = mode === 'editing' ? "Edit Staff Type" : "New Staff Type"
  const [loading, setLoading] = useState(false);

  // const options = [
  //   {value: 'https://itsthezone.s3.amazonaws.com/assets/calendar_apple.png', label: "Apple" },
  //   {value: 'https://itsthezone.s3.amazonaws.com/assets/calendar_ball.png', label: "Ball" },
  //   {value: 'https://itsthezone.s3.amazonaws.com/assets/calendar_brain.png', label: "Brain" },
  //   {value: 'https://itsthezone.s3.amazonaws.com/assets/calendar_dumbell.png', label: "Dubell" },
  // ]

  const create = async values => {
    try {
      await saveStaffType(staffTActions.updateStaffTypes, school.slug, values)
      setDialog("close")
      setLoading(false)
      settingLoader(true)
    } catch (error) {
      setLoading(false)
    }
  }
  
  const update = async values => {
    try {
      await updateStaffType(staffTActions.updateStaffTypes, values, staff_type.id)
      setDialog("close")
      setLoading(false)
      settingLoader(true)
    } catch (error) {
      setLoading(false)
    }
  }
  
  return (
    <Dialog open={open} onClose={() => setDialog("close")} id="staff_type_dialog">
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          ...staff_type,
          student_registry: [],
          image: null,
        }}
        validate={values => {
          const errors = {}
          if (!values.name) errors.name = 'Required field'

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true)
          setTimeout(() => {
            staff_type.id ? update(values) : create(values)
            setSubmitting(false)
          }, 1000)
        }}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
        <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="staff_type_name_input_dialog"
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="staff_type_color_input_dialog"
                  label="Color"
                  name="color"
                  type="color"
                  value={values.color}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Social icon</InputLabel>
                  <Select
                    variant="outlined"
                    id="staff_type_icon_input_dialog"
                    name="icon"
                    label="icon"
                    value={values.icon}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    renderValue={selected => {
                      let i = options.findIndex(x => x.value === selected)
                      return options[i].label
                    }}
                  >
                  {options.map((op, i) =>
                  <MenuItem value={op.value} key={op.label} id={`staff_type_icon_${i}`}>
                    <ListItemAvatar>
                      <Avatar>
                        <img width="100%" src={op.value} alt={op.label}/>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={op.label} />
                  </MenuItem>)}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rowsMax="4"
                  variant="outlined"
                  id="staff_type_description_input_dialog"
                  label="Description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Dropper setterFunction={setFieldValue} file={values.image} field="image"/>
              </Grid> */}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="cancel_staff_type_btn_dialog" onClick={() => setDialog("close")} color="secondary">
              Cancel
            </Button>
            <button
              type="submit"
              id="submit_staff_type_btn_dialog"
              disabled={isSubmitting}
              className="btn btn-primary btn-elevate kt-login__btn-primary d-flex">
              {mode === "editing" ? "Submit" : "Create"}
              {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
            </button>
          </DialogActions>
        </form>
      )}
      </Formik>
    </Dialog>
  )
}

const mapStateToProps = store => {
  return ({
  staff_type: store.staff_type.staffType,
  school: store.school.selected_school,
})}

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  staffTActions: bindActionCreators(staffTypeDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(StaffDialog)