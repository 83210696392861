import React, { useEffect, useState } from 'react'
import { Paper, MuiThemeProvider, Switch, Button, useMediaQuery } from '@material-ui/core'
import { Add } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';

import { tableMuiTheme } from '../../table/table_styles';
import ConferenceDialog from './ConferenceDialog';

import * as conferenceDUCK from "../../../../redux/ducks/conference.duck"
import { setLoader } from '../../../../redux/ducks/load.duck';
import { getConferences, defaultConference, getConference, updateConference } from '../../../crud/api/conference.crud';

const Conference = ({ conferences, conferenceActions, settingLoader }) => {
  const isDesktop = useMediaQuery("(min-width: 992px)")
  const isTablet = useMediaQuery("(min-width: 768px)")
  const [dialog, setDialog] = useState("close")

  useEffect(() => {
    getConferences(conferenceActions.setConferences)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'color',
      label: 'Color',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div style={{ background: value, height: 20, width: 40, borderRadius: 20 }}/>
        )
      }
    },
    {
      name: 'active',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, metaData, updateValue) => {
          const update = async check => {
            const conference = {
              ...metaData.tableData[metaData.rowIndex],
              active: check
            }
            settingLoader(true)
            try {
              await updateConference(conferenceActions.updateConferences, conference, conference.id)
            } catch (error) {
            }
          }
          
          return (
          <Switch
            color="secondary"
            name="status"
            checked={value}
            onChange={e => update(e.target.checked)}
          />
        )}
      }
    },
  ]

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: conferences.rowsPerPage,
    page: conferences.page,
    count: conferences.count,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "670px" : isTablet ? "640px" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "conference.csv",
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
    },
    onRowClick: (data, meta, col) => {
      const { target } = col
      if (target.name !== "status") openDialog('editing', data[0])
    },
    onChangePage: (mPage) => {
      settingLoader(true)
      getConferences(conferenceActions.setConferences, mPage +1)
    }
  };

  const openDialog = (mode, id = null) => {
    switch(mode){
        case "create":
          conferenceActions.setConference(defaultConference)
          setDialog(mode)
          break;
        case "editing":
          settingLoader(true)
          getConference(conferenceActions.setConference, id)
          .then(() => setDialog(mode))
          break;
        case "close":
          setDialog(mode)
          setTimeout(() => {
            conferenceActions.setConference(defaultConference)
          }, 500);
          break;
        default:
          console.error("Invalid dialog mode")
    }        
  }

  return (
    <div className="table-container">
      <div className="text-right pb-4 px-5">
        <Button id="create_conference_btn" variant="contained" color="secondary" onClick={() => openDialog("create")}><Add /> New conference</Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={tableMuiTheme()}>
          <MUIDataTable
            // title="Admin"
            columns={columns}
            data={conferences.data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      <ConferenceDialog mode={dialog} setDialog={openDialog} />
    </div>
  )
}

const mapStateToProps = store => ({
  conferences: store.conf.conferences,
})

const mapDispatchToProps = (dispatch) => ({
  conferenceActions: bindActionCreators(conferenceDUCK.actions, dispatch),
  settingLoader: bindActionCreators(setLoader, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Conference)
