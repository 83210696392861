import { Paper, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { connect } from "react-redux";
import DashboardImageLibrary from "./DashboardImageLibrary";
import DashboardResourcesViewer from "./DashboardResourcesViewer";
import DashboardTitle from "./DashboardTitle";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#16192B",
    width: "100%",
    padding: 24,
  },
  imageCard: {
    padding: ({ isM }) => (isM ? "0 20px" : "0 5px"),
    marginTop: 50,
  },
  subtitleContainer: {
    margin: "40px 0",
  },
  resources: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: 0,
  },
}));

const DashboardLibraryList = ({ textSize, library, isM }) => {
  const theme = useTheme();
  const classes = useStyles(theme, { isM });
  return (
    <Paper className={classes.container}>
      <DashboardTitle
        variant="dark.white"
        fontWeight={500}
        fontSize={28}
        text="Explore"
      />
      <div className="mt-5">
        <DashboardResourcesViewer
          views={library?.per_day_view_count}
          text="Resources Viewed"
          textSize={textSize}
        />
      </div>
      <div className={classes.subtitleContainer}>
        <DashboardTitle
          variant="dark.white"
          fontWeight={500}
          fontSize={20}
          text="Most Viewed Resources"
        />
      </div>
      <div className={isM ? {} : classes.resources}>
        {library?.most_viewed_resources?.length &&
          library.most_viewed_resources.map((item) => (
            <div key={item.title} className={classes.imageCard}>
              <DashboardImageLibrary
                src={item.display_image}
                views={item.view_count}
                text={item.title}
                icon={item.file_type}
              />
            </div>
          ))}
      </div>
    </Paper>
  );
};

const mapStateToProps = (store) => ({
  library: store.dashboard.data.dashboard_data.library,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardLibraryList);
