import { useState } from "react";
import app from "../../../../../../src/firebase.js";

import {
  BASE_URL,
  BASE_URL_v2,
  SCHOOL_URL,
} from "../../../../crud/helpers/routes.js";

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { handleResponse } from "../../../../crud/helpers/validate.js";
import notice from "../../../../utils/notice.js";

const SSO_LOGIN_URL = `${BASE_URL}/auth/check_if_sso`;
const LOGIN_URL = `${BASE_URL}/users/me`;
const CUSTOM_TOKEN_URL = `${BASE_URL_v2}/auth/custom-access-token`;

export function useSmsLogin(props) {
  const {
    authActions,
    setSelectedSchool,
    setWValidationCode,
    setLoading,
  } = props;
  const phoneAuth = getAuth(app);

  const [confirmationResult, setConfirmationResult] = useState(null);
  const [phone, setPhone] = useState("");

  const setSchool = (setterFunction, token) => {
    return fetch(`${SCHOOL_URL}/suggestions?q=`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => handleResponse(res))
      .then((json) => setterFunction(json[0]))
      .catch((e) => console.error(e.errors));
  };

  const setupRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log("Captcha resuelto");
        },
      },
      phoneAuth
    );
  };

  const sendSMSOtp = (phoneNumber) => {
    /* console.log(phoneNumber);
    return; */
    setupRecaptcha();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(phoneAuth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
        setPhone(phoneNumber);
        setLoading(false);
        setWValidationCode(true);
      })
      .catch((error) => {
        setLoading(false);
        notice("Error sending the OTP");
      });
  };

  const verifySmsOTP = (otp) => {
    if (confirmationResult) {
      confirmationResult
        .confirm(otp)
        .then(() => {
          verifySso(phone);
        })
        .catch((error) => {
          setLoading(false);
          notice("Incorrect OTP");
        });
    }
  };

  const verifySso = (username) => {
    fetch(SSO_LOGIN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, isEmail: 0 }),
    })
      .then((res) => handleResponse(res))
      .then((json) => {
        console.log(json);
        if (json.tmp_token) {
          getCustomAccessToken(json.tmp_token);
        }
      })
      .catch((error) => {
        setLoading(false);
        notice("Error");
      });
  };

  const getCustomAccessToken = (tmp_token) => {
    fetch(CUSTOM_TOKEN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ tmp_token, phone_number: phone }),
    })
      .then((res) => handleResponse(res))
      .then((json) => {
        console.log(json);
        if (json.access_token) {
          const token = json.access_token;
          return fetch(LOGIN_URL, {
            headers: {
              Authorization: token,
            },
          })
            .then((response) => handleResponse(response))
            .then((json) => {
              if (
                (json.user.user_type && json.user.user_type === "staff") ||
                json.user.user_type == "admin" ||
                json.user.is_admin
              ) {
                let user = {
                  id: json.id ? json.id : json.user.id,
                  user_type: json.user.user_type,
                  first_name: json.user.first_name,
                  last_name: json.user.last_name,
                  is_admin: json.user.is_admin ? true : false,
                  email: json.user.email,
                  avatar: json.user.avatar,
                  avatar_thumb: json.user.avatar_thumb,
                  phone_number: json.user.phone_number,
                  staff_member_admin:
                    json.user.user_type === "staff" ? json.is_admin : false,
                };

                if (json.school) user = { ...user, school: json.school };

                return {
                  user: user,
                  authToken: `Bearer ${token}`,
                };
              } else notice("Student's must use mobile application");
            })
            .then((data) => {
              setLoading(false);
              if (data.user.is_admin)
                setSchool(setSelectedSchool, data.authToken);
              else if (data.user.school) setSelectedSchool(data.user.school);
              authActions.login(data);
            })
            .catch(() => {
              setLoading(false);
              notice("Error");
              /*  setSubmitting(false); 
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );*/
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        notice("Error");
      });
  };

  return {
    sendSMSOtp,
    verifySmsOTP,
  };
}
