import { makeStyles, Paper } from "@material-ui/core";
import React from "react";
import DashboardTitle from "./DashboardTitle";
import InfoIcon from "@material-ui/icons/Info";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#23263F",
    borderRadius: 8,
    padding: 8,
    width: ({ width }) => width,
    height: 85,
  },
  text: {
    maxWidth: 100,
  },
  icon: {
    color: "#DFEAEE",
    backgroundColor: "#23263F",
    borderRadius: 8,
    width: 24,
    height: 24,
  },
}));

const DashboardChipInfoLaunches = ({ width, daily_launches_count }) => {
  const count = daily_launches_count?.value ?? 0;
  const classes = useStyles({ width });
  return (
    <Paper className={classes.container}>
      <div className="d-flex justify-content-between">
        <DashboardTitle
          text={count}
          variant="dark.grey3"
          fontSize={24}
          fontWeight={400}
        />
        <InfoIcon className={classes.icon} />
      </div>
      <div className={classes.text}>
        <DashboardTitle
          lineHeight={17}
          text="Daily launches"
          fontWeight={400}
          fontSize={14}
          variant="dark.grey3"
        />
      </div>
    </Paper>
  );
};

const mapStateToProps = (store) => ({
  daily_launches_count:
    store.dashboard.data.dashboard_data.daily_launches_count,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardChipInfoLaunches);
