import { ZONE_IN_URL } from "../helpers/routes";
import {
  getMultipartParams,
  getParams,
  handleError,
  handleFormData,
  handleResponse,
} from "../helpers/validate";

export const getListZoneIn = (page = 1, query = "") =>
  fetch(`${ZONE_IN_URL}?page=${page}&query=${query}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);

export const getSingleZoneIn = (id) =>
  fetch(`${ZONE_IN_URL}/${id}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);

export const createZoneIn = (data) =>
  fetch(`${ZONE_IN_URL}`, getMultipartParams("POST", handleFormData(data)))
    .then(handleResponse)
    .catch(handleError);

export const updateZoneIn = (data) =>
  fetch(
    `${ZONE_IN_URL}/${data.id}`,
    getMultipartParams("PUT", handleFormData(data))
  )
    .then(handleResponse)
    .catch(handleError);

export const deleteZoneIn = (id) =>
  fetch(`${ZONE_IN_URL}/${id}`, getParams("DELETE"))
    .then(handleResponse)
    .catch(handleError);
