import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Slide, CircularProgress } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setAlert } from '../../redux/ducks/alert.duck';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Message = ({ alert, setAlert }) => {
  const [loading, setLoading] = useState(false)

  const alertAction = () => {
    setLoading(true)
    setTimeout(() => {
      alert.action()
      .then(() => {setAlert({ title: "", message: "", btn_msg: "", action: () => { } }); setLoading(false)})
      .catch(() => setLoading(false))
    }, 500);
  }
  
  return (
    <Dialog id="mesage_dialog" style={{ bottom: "unset" }} TransitionComponent={Transition} open={alert.message !== ""} onClose={() => setAlert({ title: "", message: "", btn_msg: "", action: () => { } })}>
      <DialogTitle>{alert.title}</DialogTitle>
      <DialogContent dividers>
        {alert.message}
      </DialogContent>
      <DialogActions>
        <Button id="cancel_message_dialog" onClick={() => setAlert({ title: "", message: "", btn_msg: "", action: () => { } })}>
          Cancel
        </Button>
        {alert.btn_msg && <button id="submit_message_dialog" className="btn btn-danger btn-elevate kt-login__btn-danger d-flex" onClick={() => alertAction()}>
          {alert.btn_msg}
          {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
        </button>}
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  alert: store.alert.alert
})
const mapDispatchToProps = dispatch => {
  return {
    setAlert: bindActionCreators(setAlert, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Message)