import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Goals from './Goals'

import * as goalsDUCK from "../../../redux/ducks/goals.duck"
import { getGoalAnalytics } from '../../crud/api/goals.crud'

class GoalsWrapper extends Component {

  componentDidMount() {
    const { school, goalActions } = this.props

    if (school && school !== null) {
      getGoalAnalytics(goalActions.setGoalAnalytics, school.slug)
    }
  }

  componentDidUpdate() {
    const { school, goalActions } = this.props

    if (school && school !== null) {
      getGoalAnalytics(goalActions.setGoalAnalytics, school.slug)
    }
  }

  render() {
    return (
      <>
       <Goals /> 
      </>
    )
  }
}

const mapStateToProps = store => ({
  school: store.school.selected_school,
})

const mapDispatchToProps = (dispatch) => ({
  goalActions: bindActionCreators(goalsDUCK.actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(GoalsWrapper)