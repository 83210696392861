import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Grid, useMediaQuery } from "@material-ui/core";
import {  Alert } from '@material-ui/lab'

import CustomModal from "../../Components/CustomModal";
import SimpleInput from "../../Components/SimpleInput";
import SimpleCheckbox from "../../Components/SimpleCheckbox";
import SimpleAutocomplete from "../../Components/SimpleAutocomplete";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  defaultNotify,
  BODY_CHARACTER_LIMIT,
  TITLE_CHARACTER_LIMIT,
  searchFunction,
} from "./utils";
import { searchSchools } from "../Polls/utils";
import { defaultContent } from "../../crud/api/content.crud";
import { saveNotification } from "../../crud/api/notifications.crud";
import { actions } from "../../../redux/ducks/contents.duck";

const NotifyModal = ({ open, setOpen,typeNotification ="library_content" }) => {
  const isDesktop = useMediaQuery("(min-width: 600px)");
  const dispatch = useDispatch();
  const { selected_school, user, content } = useSelector((store) => ({
    selected_school: store.school.selected_school,
    content: store.contents_.content,
    user: store.auth.user,
  }));
  const [notify, setNotify] = useState(defaultNotify);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (content.id && !notify.contentId)
      setNotify({
        ...notify,
        contentId: content.id,
        targetId:  content.id,
        type_notification : typeNotification,
        contentTitle: content.title,
      });
  }, [content.id, content.title, notify]);

  const handleSaveNotification= async () => {
    
    let errors = []
    if (notify.title === "" ) errors.push("Title cannot be empty");
    if (notify.body === "" )  errors.push("Body cannot be empty");
    if (notify.all_teams === false && notify.schools_id.length === 0)  errors.push("School cannot be empty");;
    setErrors(errors)
    if(errors.length > 0) return ;
    try {
      await saveNotification(notify);
      setNotify(defaultNotify);
      dispatch(actions.setContent(defaultContent));
      setOpen(false);
    } catch (e) {

    }
  };

  const handleCloseModal = async () => {
    setErrors([]);
    setNotify(defaultNotify);
    dispatch(actions.setContent(defaultContent));
    setOpen(false);
  };


  const handleChange = ({ target }) => {
    
    const { name, value } = target;
    if (name === "title" && value.length > 40) return;
    else if (name === "body" && value.length > 144) return;
    else if (name === "all_teams") {
      setNotify({
        ...notify,
        [name]: value,
        schools_id: [],
        teams_id: [],
        students_id: [],
      });
    } else setNotify({ ...notify, [name]: value });
  };

  return (
    <CustomModal
      open={open}
      onClose={handleCloseModal}
      onConfirmButton={handleSaveNotification}
      confirmButtonLabel="Send"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <h3 className="mb-2">Live Preview</h3>
          <div
            className="blured-xs-box px-3 py-2"
            style={{ minWidth: 150, maxHeight: 250 }}
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-end mb-2">
                <img
                  src={toAbsoluteUrl("/media/logos/logo_contained.png")}
                  alt="logo"
                  width="18px"
                  className="mr-3 rounded"
                />
                THE ZONE
              </div>
              <p>Now</p>
            </div>
            <div>
              <h3 className="text-break">
                {notify.title.length === 0
                  ? "Title has a 40-character limit"
                  : notify.title}
              </h3>
              <h6 className="font-weight-light text-break">
                {notify.body.length === 0
                  ? 'Body has a 144-character limit. PLEASE use the "ANNOUNCEMENTS" feature for sharing Rich Long Text, Links, Videos, and Docs'
                  : notify.body}
              </h6>
            </div>
          </div>
        </Grid>
        <Divider
          orientation={isDesktop ? "vertical" : "horizontal"}
          flexItem={isDesktop}
          className={!isDesktop ? "w-100" : ""}
        />
        <Grid container item spacing={2} xs={12} sm={8}>
          <Grid item xs={12}>
            <h3>Type here</h3>
          </Grid>
          <Grid item xs={12}>
            <SimpleInput
              disabled
              name="contentTitle"
              value={notify.contentTitle}
              onChange={handleChange}
              id="content_notifications_btn"
              label="Content"
            />
          </Grid>
          <Grid item xs={12}>
            <SimpleInput
              name="title"
              value={notify.title}
              onChange={handleChange}
              id="title_notifications_btn"
              label={`Title | ${notify.title.length}/${TITLE_CHARACTER_LIMIT}`}
              helperText={`Tip: A great "Title" is three things: Catchy, Short, and Insightful`}
            />
          </Grid>
          <Grid item xs={12}>
            <SimpleInput
              rows={4}
              multiline
              name="body"
              value={notify.body}
              onChange={handleChange}
              id="body_notifications_btn"
              label={`Body | ${notify.body.length}/${BODY_CHARACTER_LIMIT}`}
              helperText={`NOTE: Use the "ANNOUNCEMENTS" feature for sharing Rich Long Text, Links, Videos, and Docs`}
            />
          </Grid>
          <Grid item xs={12}>
            <SimpleCheckbox
              labelPos="end"
              name="all_teams"
              id="all_teams_checbox"
              onChange={handleChange}
              value={notify.all_teams}
              label={
                user.is_admin
                  ? "All Organizations"
                  : "Notifiy all students in all Organizations"
              }
            />
          </Grid>
          <Grid item xs={12}>
            {user.is_admin ? (
              <SimpleAutocomplete
                multiple
                id="tags-schools"
                name="schools_id"
                label="Organizations"
                disabled={notify.all_teams}
                searchFunction={searchSchools}
                value={notify.schools_id}
                onChange={handleChange}
              />
            ) : (
              <SimpleAutocomplete
                multiple
                id="tags-teams"
                name="teams_id"
                label="Teams"
                disabled={notify.all_teams}
                searchFunction={
                  searchFunction(selected_school?.slug).searchTeams
                }
                value={notify.teams_id}
                onChange={handleChange}
              />
            )}
          </Grid>
          {!user.is_admin && (
            <Grid item xs={12}>
              <SimpleAutocomplete
                multiple
                label="Students"
                id="tags-students"
                name="students_id"
                onChange={handleChange}
                value={notify.students_id}
                disabled={notify.all_teams || !selected_school}
                error={!selected_school}
                helperText={
                  !selected_school && "Please select a school in the aside menu"
                }
                searchFunction={
                  searchFunction(selected_school?.slug).searchStudents
                }
              />
            </Grid>
          )}
          <br></br>
          {errors.length > 0 && (
            <Alert severity="error">
              {
                errors.map(error => (
                  <li style={{ listStyleType: 'disc' }} >
                    {error}
                  </li>

                ))

              }
            </Alert>
          )}
        </Grid>
        
           
           
            
      </Grid>
    </CustomModal>
  );
};

export default NotifyModal;
