import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultPoll } from "../../app/crud/api/polls.crud";

export const actionType = {
  GetPolls: "[POLL] GET_POLLS",
  GetPoll: "[POLL] GET_POLL",
  UpdatePoll: "[POLL] UPDATE_POLL",
};

const initialState = {
  polls: { data: [], isFetched: false },
  poll: defaultPoll,
  selected_poll: null,
};

export const reducer = persistReducer(
  { storage, key: "poll", withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetPolls: {
        return {
          ...state,
          polls: {
            data: action.payload.items,
            count: action.payload.total,
            page: action.payload.page,
            pages: action.payload.pages,
            rowsPerPage: action.payload.per_page,
            isFetched: true,
          },
        };
      }

      case actionType.UpdatePoll: {
        let newData = state.polls.data;
        const index = state.polls.data.findIndex(
          (x) => x.id === action.payload.id
        );

        if (index === -1) {
          newData = [action.payload, ...state.polls.data];
        } else {
          newData = state.polls.data;
          newData[index] = action.payload;
        }

        return { ...state, polls: { ...state.polls, data: newData } };
      }

      case actionType.GetPoll: {
        return {
          ...state,
          poll: {
            ...action.payload,
            ...action.payload.poll,
            close_date: new Date(
              action.payload.poll?.close_date?.replace("Z", "")
            ),
            teams: action.payload.teams,
            schools: action.payload.schools || [],
            all_schools: action.payload.all_schools,
            options: action.payload.options.map((option) => ({
              ...option,
              disabled: !!option.id,
            })),
          },
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setPolls: (data) => ({ type: actionType.GetPolls, payload: data }),
  setPoll: (data) => ({ type: actionType.GetPoll, payload: data }),
  updatePolls: (data) => ({ type: actionType.UpdatePoll, payload: data }),
};
