import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import { defaultAppointment } from '../../app/crud/api/calendar.crud';

export const actionType = {
  GetAppointments: '[APPOINTMENT] GET_APPOINTMENTS',
  GetAppointment: '[APPOINTMENT] GET_APPOINTMENT',
  UpdateAppointment: '[APPOINTMENT] UPDATE_APPOINTMENT',
  DeleteAppointment: '[APPOINTMENT] DELETE_APPOINTMENT',
  LoadAppointmentCsv: '[APPOINTMENT] LOAD_APPOINTMENT_CSV',
  SelectAppointment: '[APPOINTMENT] SELECT_APPOINTMENT'
}

const initialState = {
  appointments: { data: [], isFetched: false },
  appointment: defaultAppointment,
  selected_appointment: null,
}

export const reducer = persistReducer(
  { storage, key: 'calendar', withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetAppointments: {
        return { ...state, appointments: { data: action.payload, isFetched: true } }
      }

      case actionType.UpdateAppointment: {
        let newData = state.appointments.data
        const index = state.appointments.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.appointments.data]
        } else {
          newData = state.appointments.data
          newData[index] = action.payload
        }

        return { ...state, appointments: { ...state.appointments, data: newData } }
      }

      case actionType.DeleteAppointment: {
        let newData = state.appointments.data
        const index = state.appointments.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.appointments.data]
        } else {
          newData = state.appointments.data
          newData.splice(index, 1)
        }

        return { ...state, appointments: { ...state.appointments, data: newData } }
      }
      
      case actionType.GetAppointment: {
        
        return { ...state, appointment: action.payload }
      }

      case actionType.LoadAppointmentCsv: {
        let newData =  action.payload
        
        return { ...state, appointments: { ...state.appointments, data: [...state.appointments.data, ...newData] } }
      }

      case actionType.SelectAppointment: {

        return { ...state, selected_appointment: action.payload }
      }

      default: return state

    }
  }
)

export const actions = {
  setAppointments: data => ({ type: actionType.GetAppointments, payload: data }),
  setAppointment: data => ({ type: actionType.GetAppointment, payload: data }),
  updateAppointments: data => ({ type: actionType.UpdateAppointment, payload: data }),
  deleteAppointments: data => ({ type: actionType.DeleteAppointment, payload: data }),
  loadAppointmentCsv: data => ({ type: actionType.LoadAppointmentCsv, payload: data }),
  setSelectedAppointment: state => ({ type: actionType.SelectAppointment, payload: state })
}