/* eslint-disable no-throw-literal */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Alert } from "@material-ui/lab";

import {
  Paper,
  createMuiTheme,
  MuiThemeProvider,
  Grid,
  CardMedia,
  CardContent,
  Typography,
  Card,
  ButtonGroup,
  Button,
  useMediaQuery,
  CardActions,
  Tooltip,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import {
  Delete,
  Add,
  School,
  Phone,
  Publish,
  Visibility,
} from "@material-ui/icons";
import { bindActionCreators } from "redux";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import moment from "moment";

import PasswordReset from "../PasswordReset";
import StudentDialog from "./StudentDialog";
import FilterDataTable from "../../../utils/FilterDataTable";
import FilterDataTableSelect from "../../../utils/FilterDataTableSelect";

import {
  defaultStudent,
  getStudents,
  getStudent,
  deleteStudent,
  studentBulk,
  validateStudentBulkFetch,
  generatePresignedUrl,
  checkStateBulk,
} from "../../../crud/api/students.crud";
import * as studentDUCK from "../../../../redux/ducks/students.duck";
import { setLoader } from "../../../../redux/ducks/load.duck";
import { setAlert } from "../../../../redux/ducks/alert.duck";
import notice from "../../../utils/notice";
import { BulkModal } from "../../../utils/BulkModal";
import CustomTableFooter from "../../../utils/CustomTableFooter";
import axios from "axios";
import {
  getMultipartParams,
  handleResponse,
} from "../../../crud/helpers/validate";
import { SCHOOL_URL } from "../../../crud/helpers/routes";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: ({ isDesktop }) => (!isDesktop ? "70%" : "50%"),
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
    maxWidth: 500,
  },
  alertLeft: {
    display: "inline-flex !important",
    right: 4,
  },
  progress: {
    marginLeft: 10,
  },
}));
const Student = ({
  students,
  school,
  studentActions,
  settingLoader,
  setAlert,
  staff_member_admin,
  is_admin,
}) => {
  const classes = useStyles({ isDesktop });
  const [isGrid] = useState(false);
  const [dialog, setDialog] = useState("close");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [group, setGroup] = useState("");
  const [bulk, setBulk] = useState(null);
  const [stepsBulk, setStepsBulk] = useState(1);
  const [bulkModal, setBulkModal] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [urlFile, setUrlFile] = useState("");
  const [errorsBulk, setErrorsBulk] = useState([]);
  const [teams, setTeams] = useState([]);

  const isDesktop = useMediaQuery("(min-width: 992px)");

  const getTeams = (setterFunction, slug) => {
    return fetch(
      `${SCHOOL_URL}/${slug}/teams?per_page=100`,
      getMultipartParams("GET")
    )
      .then((res) => handleResponse(res))
      .then((json) => setterFunction(json.items))
      .catch((e) => {
        console.error(e.errors);
        return e;
      });
  };
  const getQueryGroup = () => {
    if (group) {
      if (group && group !== null) {
        return "&team=" + group;
      }
    }
    return "";
  };

  useEffect(() => {
    if (school && school !== null && school.slug && school.slug !== null) {
      if (teams.length === 0) {
        getTeams(setTeams, school.slug);
      }
      if (query && query.length > 1)
        getStudents(
          studentActions.setStudents,
          school.slug,
          1,
          query,
          getQueryGroup()
        );
      else
        getStudents(
          studentActions.setStudents,
          school.slug,
          1,
          "",
          getQueryGroup()
        );
    }
  }, [query, school, studentActions.setStudents, group]);

  const openDialog = (mode, id = null) => {
    switch (mode) {
      case "create":
        settingLoader(true);
        studentActions.setStudent(defaultStudent);
        setDialog(mode);
        break;
      case "editing":
        settingLoader(true);
        getStudent(studentActions.setStudent, id).then(() => setDialog(mode));
        break;
      case "close":
        setDialog(mode);
        setTimeout(() => {
          studentActions.setStudent(defaultStudent);
        }, 500);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  };

  const deleteStudents = (id) => {
    setAlert({
      title: "Delete Athlete",
      message: "Are you sure to permanently delete this Athlete?",
      btn_msg: "Delete Athlete",
      action: () => deleteStudent(studentActions.deleteStudents, id),
    });
  };

  // const notifyStudents = (email) => {
  //   setAlert({
  //     title: "Resend student code",
  //     message: "Are you sure to resend student code?",
  //     btn_msg: "Resend student code",
  //     action: () => notifyStudent(email)
  //   })
  // }

  const createStudentBulk = (values) => {
    setBulkLoading(true);
    if (!school?.slug) {
      setBulkLoading(false);
      notice("Please select a school");
    } else if (!bulk) {
      setBulkLoading(false);
      notice("Please add a csv file");
    } else
      studentBulk(school.slug, urlFile, values.team_id).then((response) => {
        const intervalIdentifierC = setInterval(() => {
          _checkStateBulk(response.id, 6, 6.1);
        }, 5000);
        localStorage.setItem("idInterval", intervalIdentifierC);

        setBulk(null);
        setStepsBulk(4);
        // setTimeout(
        //   () =>
        //     getStudents(studentActions.setStudents, school.slug, 1, query),
        //   2000
        // );
        setTimeout(() => {
          setBulkLoading(true);
          setStepsBulk(5);
        }, 2000);
      });
  };
  const onFinish = () => {
    setStepsBulk(1);
    setBulkModal(false);
    getStudents(
      studentActions.setStudents,
      school.slug,
      1,
      query,
      getQueryGroup()
    );
  };
  const _checkStateBulk = async (id_background, nextStep, stepError) => {
    const res = await checkStateBulk(id_background);
    if (res.state === "success") {
      clearInterval(localStorage.getItem("idInterval"));
      localStorage.removeItem("idInterval");
      setStepsBulk(nextStep);
      setBulkLoading(false);
    }

    if (res.state === "error") {
      setErrorsBulk(res.result.errors);
      clearInterval(localStorage.getItem("idInterval"));
      localStorage.removeItem("idInterval");
      setStepsBulk(stepError);
      setBulkLoading(false);
    }
  };
  const validateStudentBulk = async (values) => {
    setBulkLoading(true);
    if (!school?.slug) {
      setBulkLoading(false);
      notice("Please select a school");
    } else if (!bulk) {
      setBulkLoading(false);
      notice("Please add a csv file");
    } else {
      setStepsBulk(2);
      const result = await uPloadFiletoS3();
      if (result == false) {
        return;
      }
      setUrlFile(result);
      validateStudentBulkFetch(school.slug, result)
        .then((response) => {
          const intervalIdentifierC = setInterval(() => {
            _checkStateBulk(response.id, 3, 2.2);
          }, 5000);
          localStorage.setItem("idInterval", intervalIdentifierC);
        })
        .catch(() => {
          setStepsBulk(2.2);
          setBulkLoading(false);
        });
    }
  };
  const uPloadFiletoS3 = async () => {
    const responseUploadUrl = await generatePresignedUrl(bulk.name);

    var res = await fetch(responseUploadUrl.uploadUrl, {
      method: "PUT",
      body: bulk,
    })
      .then((res) => res)
      .catch((error) => {
        setBulkLoading(false);
        setStepsBulk(2.1);

        return false;
      })
      .then((response) => response);

    if (res == undefined) return false;

    return responseUploadUrl.key;
  };
  const columns = [
    {
      name: "user",
      label: "Picture",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value &&
          (value.avatar || value.avatar_thumb) && (
            <div
              style={{
                background: `url(${
                  value.avatar_tumb ? value.avatar_tumb : value.avatar
                }) center center / cover no-repeat`,
                height: 40,
                width: 40,
                borderRadius: "50%",
                margin: "-5px 0",
              }}
            />
          ),
      },
    },
    {
      name: "user",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value && (
            <>
              {value.first_name} {value.last_name}
            </>
          ),
      },
    },
    {
      name: "user",
      label: "Email",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => value && <>{value.email}</>,
      },
    },
    {
      name: "created_at",
      label: "Date Registered",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value && <>{moment(value).format("MMM DD YYYY")}</>,
      },
    },
    {
      name: "team",
      label: "Team",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value && <>{value && value !== null ? value.name : ""}</>,
      },
    },
    {
      name: "user",
      label: "User Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            value && (
              <>
                {value !== null ? (value.active ? "Active" : "Inactive") : ""}
              </>
            )
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) =>
          value && (
            <div className="d-flex">
              <Tooltip title="Edit Athlete" placement="top">
                <a
                  id={`edit_student_btn_${value}`}
                  className="btn btn-icon btn-sm btn-light btn-hover-primary"
                  onClick={() => openDialog("editing", value)}
                >
                  {/* {staff_member_admin || is_admin ? ( */}
                  <i className="fas fa-edit text-primary" />
                  {/* /* ) : (
                    <Visibility />
                  ) } */}
                </a>
              </Tooltip>
              {(staff_member_admin || is_admin) && (
                <Tooltip title="Delete Athlete" placement="top">
                  <a
                    id={`delete_student_btn_${value}`}
                    className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                    onClick={() => deleteStudents(value)}
                  >
                    <Delete />
                  </a>
                </Tooltip>
              )}
              {/* <Tooltip title="Edit Password" placement="top">
              <a id={`passw_change_student_btn_${value}`} className="btn btn-icon btn-sm btn-light btn-hover-warning text-warning" onClick={() => setEmail(meta.rowData[0].email)}>
                <VpnKey />
              </a>
            </Tooltip>
            <Tooltip title="Resend student code" placement="top">
              <a id={`notify_student_btn_${value}`} className="btn btn-icon btn-sm btn-light btn-hover-info text-info mx-3" onClick={() => notifyStudents(meta.rowData[0].email)}>
                <Mail />
              </a>
            </Tooltip> */}
            </div>
          ),
      },
    },
  ];

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: students.rowsPerPage,
    page: students.page,
    count: students.count,
    rowHover: false,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: "none",
    tableBodyMaxHeight: isDesktop ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data",
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "Athlete.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayRowsOnly: true,
      },
    },
    customFooter: (count, page, rowsPerPage) => {
      settingLoader(true);
      return (
        <CustomTableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={(mPage) => {
            getStudents(
              studentActions.setStudents,
              school.slug,
              mPage,
              query,
              getQueryGroup()
            );
          }}
        />
      );
    },
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            padding: "4px 10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            // eslint-disable-next-line no-useless-computed-key
            ["@media(min-width: 960px)"]: {
              maxWidth: 200,
            },
          },
        },
        MUIDataTableToolbar: {
          root: {
            background:
              "linear-gradient(125deg, rgba(17, 151, 192, 1) 25%, rgba(4, 7, 25, 1) 100%)",
            minHeight: "50px",
            height: "50px",
            borderTopLeftRadius: "3px",
            borderTopRightRadius: "3px",
            color: "white",
          },
          icon: {
            color: "white",
          },
          titleRoot: {
            display: "none",
          },
          left: {
            marginLeft: 80,
          },
        },
        MUIDataTable: {
          root: {
            display: !isGrid ? "block" : "none",
          },
        },
        MuiTable: {
          root: {
            display: !isGrid ? "table" : "none",
          },
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            zIndex: 90,
          },
        },
      },
    });

  const cardView = () => (
    <Grid container spacing={2} style={{ padding: "25px 10px" }}>
      {students.data.map((stu) => {
        return (
          <Grid item key={stu.id} xs={12} sm={6} md={3}>
            <Card>
              <CardMedia
                style={{ height: 125 }}
                image={
                  stu.user.avatar_tumb ? stu.user.avatar_tumb : stu.user.avatar
                }
                title="user"
              />
              <CardContent>
                <Typography variant="h5">
                  {stu.user.first_name}{" "}
                  <span style={{ fontSize: "1rem" }}>{stu.user.last_name}</span>
                </Typography>
                <Typography>
                  Team: {stu.team && stu.team !== null ? stu.team.name : ""}
                </Typography>
                <Typography>Email: {stu.user.email}</Typography>
                <Typography variant="body2" color="textSecondary">
                  <School className="mr-1" /> {stu.classification}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <Phone className="mr-1" /> {stu.user && stu.user.phone_number}
                </Typography>
              </CardContent>
              <CardActions disableSpacing className="justify-content-end">
                <Tooltip title="Edit Athlete" placement="top">
                  <a
                    id={`edit_student_btn_${stu.id}_card`}
                    className="btn btn-icon btn-sm btn-light btn-hover-primary"
                    onClick={() => openDialog("editing", stu.id)}
                  >
                    <i className="fas fa-edit text-primary" />
                  </a>
                </Tooltip>
                <Tooltip title="Delete Athlete" placement="top">
                  <a
                    id={`delete_student_btn_${stu.id}_card`}
                    className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                    onClick={() => deleteStudents(stu.id)}
                  >
                    <Delete />
                  </a>
                </Tooltip>
                {/* <Tooltip title="Edit Student Password" placement="top">
                  <a id={`passw_change_student_btn_${stu.id}_card`} className="btn btn-icon btn-sm btn-light btn-hover-warning text-warning" onClick={() => setEmail(stu.user.email)}>
                    <VpnKey />
                  </a>
                </Tooltip> */}
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
  return (
    <>
      <div className="table-container">
        <Grid container columns={12} className=" p-2">
          <Grid
            container
            item
            spacing={2}
            xs
            direction={"row"}
            className="mb-3"
          >
            <Grid container item xs style={{ minWidth: "300px" }}>
              <FilterDataTable query={query} setQuery={setQuery} />
            </Grid>
            <Grid container item xs style={{ minWidth: "300px" }}>
              <FilterDataTableSelect
                options={teams}
                values={group}
                handleChange={setGroup}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            xs
            style={{ maxHeight: "40px" }}
            wrap="nowrap"
            justify="flex-end"
            className="mb-3"
          >
            <>
              {stepsBulk === 5 && (
                <div
                  style={{
                    display: "inline-flex !important",
                    padding: "8px 15px",
                    fontSize: "0.875rem",
                    fontFamily: "Poppins",
                    padding: "2px 5px",
                    fontWeight: 400,
                    lineHeight: 1.43,
                    borderRadius: 4,
                  }}
                  class="MuiPaper-root MuiAlert-standardInfo  MuiButtonBase-root  MuiPaper-elevation0"
                  role="alert"
                >
                  <div class="MuiAlert-icon">
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z"></path>
                    </svg>
                  </div>
                  <div class="MuiAlert-message">
                    Creating Athlete in bulk{" "}
                    {bulkLoading && stepsBulk === 5 && (
                      <CircularProgress
                        color="inherit"
                        size={18}
                        className={classes.progress}
                      />
                    )}
                  </div>
                </div>
              )}
              {stepsBulk >= 6 && (
                <>
                  <div
                    onClick={onFinish}
                    style={{
                      display: "inline-flex !important",
                      padding: "8px 15px",
                      fontSize: "0.875rem",
                      fontFamily: "Poppins",
                      padding: "2px 5px",
                      fontWeight: 400,
                      lineHeight: 1.43,
                      borderRadius: 4,
                    }}
                    class="MuiPaper-root MuiAlert-standardSuccess  MuiButtonBase-root  MuiPaper-elevation0"
                    role="alert"
                  >
                    <div class="MuiAlert-icon">
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"></path>
                      </svg>
                    </div>
                    <div class="MuiAlert-message">
                      Job finished. Click here to refresh
                    </div>
                  </div>
                </>
              )}
              <Button
                id="create_bulk_student_btn"
                className="mr-3"
                variant="contained"
                color="primary"
                onClick={() => setBulkModal(true)}
                startIcon={<Publish />}
              >
                Bulk Upload
              </Button>

              <Alert
                classes={classes.alertLeft}
                style={{ display: "none" }}
                className=" MuiButtonBase-root "
                severity=""
              >
                {" "}
              </Alert>

              <Button
                id="create_student_btn"
                variant="contained"
                color="secondary"
                onClick={() => openDialog("create")}
                startIcon={<Add />}
              >
                New Athlete
              </Button>
            </>
          </Grid>
        </Grid>
        {/*  <div className="pb-4 d-flex justify-content-between align-items-end" style={{ border: '1px solid red' }}>
          <div style={{ border: '1px solid red' }}>

            <FilterDataTable query={query} setQuery={setQuery} />
            <FilterDataTableSelect options={teams} />
          </div> */}
        {/* <div style={{ border: '1px solid red' }}>
            {(staff_member_admin || is_admin) && (
              <>

                {stepsBulk === 5 &&
                  <div style={{
                    display: 'inline-flex !important',
                    padding: '8px 15px',
                    fontSize: '0.875rem',
                    fontFamily: 'Poppins',
                    padding: '2px 5px',
                    fontWeight: 400,
                    lineHeight: 1.43,
                    borderRadius: 4,

                  }}
                    class="MuiPaper-root MuiAlert-standardInfo  MuiButtonBase-root  MuiPaper-elevation0" role="alert">
                    <div class="MuiAlert-icon"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z"></path></svg></div><div class="MuiAlert-message">
                      Creating Athlete in bulk {bulkLoading && stepsBulk === 5 && <CircularProgress color="inherit" size={18} className={classes.progress} />}
                    </div>
                  </div>
                }
                {
                  stepsBulk >= 6 &&
                  <>
                    <div onClick={onFinish} style={{
                      display: 'inline-flex !important',
                      padding: '8px 15px',
                      fontSize: '0.875rem',
                      fontFamily: 'Poppins',
                      padding: '2px 5px',
                      fontWeight: 400,
                      lineHeight: 1.43,
                      borderRadius: 4,

                    }}
                      class="MuiPaper-root MuiAlert-standardSuccess  MuiButtonBase-root  MuiPaper-elevation0" role="alert">
                      <div class="MuiAlert-icon">
                        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"></path></svg>
                      </div>
                      <div class="MuiAlert-message">
                        Job finished. Click here to refresh
                      </div>
                    </div>

                  </>
                }
                <Button
                  id="create_bulk_student_btn"
                  className="mr-3"
                  variant="contained"
                  color="primary"
                  onClick={() => setBulkModal(true)}
                  startIcon={<Publish />}
                >
                  Bulk Upload
                </Button>

                <Alert classes={classes.alertLeft} style={{ display: 'none' }} className=" MuiButtonBase-root " severity=""> </Alert>



                <Button
                  id="create_student_btn"
                  variant="contained"
                  color="secondary"
                  onClick={() => openDialog("create")}
                  startIcon={<Add />}
                >
                  New Athlete
                </Button>
              </>
            )}
          </div>
        </div> */}
        <Paper>
          <MuiThemeProvider theme={getMuiTheme()}>
            <ButtonGroup
              variant="text"
              style={{
                position: "absolute",
                zIndex: 1,
                margin: 10,
              }}
            >
              {/* <Button id="student_list_view_btn" style={{ color: "white", border: "none" }} onClick={() => setIsGrid(false)}>
              <Reorder />
            </Button>
            <Button id="student_grid_view_btn" style={{ color: "white" }} onClick={() => setIsGrid(true)}>
              <Apps />
            </Button> */}
            </ButtonGroup>
            <MUIDataTable
              // title="Admin"
              columns={columns}
              data={students.data}
              options={options}
            />
          </MuiThemeProvider>
        </Paper>
        {isGrid && cardView()}
      </div>
      <StudentDialog mode={dialog} setDialog={openDialog} />
      <PasswordReset email={email} setEmail={setEmail} />
      {bulkModal && (
        <BulkModal
          showSelectTeam={true}
          urlDownloadTemplate={process.env.REACT_APP_URL_TEMPLATE_STUDENT}
          errorsBulk={errorsBulk}
          setSteps={setStepsBulk}
          open={bulkModal}
          validateBulk={validateStudentBulk}
          setOpen={setBulkModal}
          bulk={bulk}
          steps={stepsBulk}
          setBulk={setBulk}
          school={school}
          loading={bulkLoading}
          bulkRequest={createStudentBulk}
          setLoading={setBulkLoading}
        />
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    school: store.school.selected_school,
    students: store.student.students,
    staff_member_admin: store.auth.user?.staff_member_admin,
    is_admin: store.auth.user?.is_admin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  studentActions: bindActionCreators(studentDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Student);
