import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionType = {
  SetAlert: '[Alert] SET_ALERT'
}

export const alertDefault = {
  alert: {
    title: "",
    message: "",
    btn_msg: "",
    action: () => { }
  }
}

export const reducer = persistReducer(
  { storage, key: 'alert', whitelist: [] },
  (state = alertDefault, action) => {
    switch (action.type) {
      case actionType.SetAlert: {
        
        return { alert: action.alert }
      }
      default:
        return state
    }
  }
)

export const setAlert = alert => ({ type: actionType.SetAlert, alert: alert })
